import React from 'react';
import { IconProps } from './IconProps';

const CommentIcon = (props : IconProps) => {
  return (
    <svg className="w-full h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path stroke={props.iconColor || "currentColor"} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 17h6l3 3v-3h2V9h-2M4 4h11v8H9l-3 3v-3H4V4Z"/>
    </svg>  
  )
}

export default CommentIcon