import React, { useEffect, useState } from 'react'
import { getPresentationEvaluationUser, getSelectionScenarioItems } from '../../../../../../../../data/SelectionPortalRequests';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { CrossIcon, LoadingSpinner, MainButton } from 'swap-frontend-library';
import ScenarioEvaluation from './components/ScenarioEvaluation';
import { socket } from '../../../../../../../../_socket/socket';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/store';

interface props {
    presentationId: string,
    meetingItem: any
}

const EvaluationArea = ({presentationId, meetingItem} : props) => {
    const { selectionId } = useOutletContext<any>();
    const [scenarios, setScenarios] = useState<any[]>();
    const [scenarioEvaluations, setScenarioEvaluations] = useState<any[]>();
    const [selectedScenario, setSelectedScenario] = useState<any>();
    const [liveScenarioTaskId, setLiveScenarioTaskId] = useState<any | null>(null);
    const navigate = useNavigate();
    
    const handleFetchScenarios = async () => {
        const response = await getSelectionScenarioItems(selectionId);
        if (response.ok) {
            // console.log(response.data.data);
            setScenarios(response.data.data);
            if (typeof selectedScenario === "undefined" && response.data.data.length > 0) {
                setSelectedScenario(response.data.data[0])
            } else {
                setSelectedScenario(null)
            }
        }
    }
    
    const handleFetchScenarioEvaluations = async () => {
        const response = await getPresentationEvaluationUser(presentationId);
        if (response.ok) {
            // console.log(response.data.data);
            setScenarioEvaluations(response.data.data);
        }
    }

    useEffect(() => {
        setLiveScenarioTaskId(meetingItem.liveScenarioTaskId);
    }, [])

    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    useEffect(() => {
        const handleJoinedPresentation = () => {
            console.log("Joined selection successfully");
        }

        const handleSetActiveScenarioTask = (data : any) => {
            // console.log(data);
            setLiveScenarioTaskId({liveScenarioId: data.liveScenarioId, liveScenarioTaskId: data.liveScenarioTaskId})
        }

        socket.on("joined presentation", handleJoinedPresentation);
        socket.on("set active scenarioTaskId", handleSetActiveScenarioTask);

        handleFetchScenarios();
        handleFetchScenarioEvaluations();

        socket.emit("join presentation", { presentationId });
        socket.emit("test room", { presentationId });
        socket.emit("get connected sockets", { presentationId });
        
        return () => {
            socket.off("joined presentation");
            socket.off("set active scenarioTaskId");
        }
    }, [])
    
    if (typeof scenarios === "undefined" || typeof selectedScenario === "undefined" || typeof scenarioEvaluations === "undefined") return <LoadingSpinner />

    if (selectedScenario == null) {
        return (
            <div className='flex items-center justify-center w-full h-64 bg-white m-4 rounded shadow font-semibold'>
                No scenarios definded
            </div>
        )
    }
    
    return (
        <div>
            <div className="flex h-12 pr-5 pl-5 text-base font-semibold leading-6 text-text-gray-800 bg-white border-b border-solid">
                <ul className="flex overflow-x-auto w-full h-full pt-2 text-sm w-full font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
                    {
                        scenarios.map((scenario:any, index:number) => {
                            return (
                                <li 
                                // ref={(selectedTab == scenario.id) ? (ref) : (undefined)}
                                onClick={() => {setSelectedScenario(scenario); navigate(`?scenarioId=${scenario.id}`);}}
                                className={`relative cursor-pointer flex items-end justify-between mr-2 p-2 border-b-4 ${(selectedScenario?.id == scenario.id) ? ("text-primary border-primary dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>
                                    <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                                    Scenario {scenario.cleanOrderNumber}: {scenario.title}
                                    </p>
                                    {
                                        liveScenarioTaskId?.liveScenarioId == scenario.id ? (
                                        <div className='absolute right-0 bg-primary3 text-white rounded p-1'>
                                            Live
                                        </div>
                                        ) : <></>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                {/* <div className='h-12 w-64 flex items-center justify-center'>
                    <div className='h-10 w-full'>
                        <MainButton text="Go to live scenario" height='h-10' />
                    </div>
                </div> */}
            </div>
            <div>
                <ScenarioEvaluation scenario={selectedScenario} scenarioEvaluations={scenarioEvaluations} presentationId={presentationId} handleFetchScenarioEvaluations={handleFetchScenarioEvaluations} liveScenarioTaskId={liveScenarioTaskId} setLiveScenarioTaskId={setLiveScenarioTaskId} />
            </div>
        </div>
  )
}

export default EvaluationArea