import React, { useEffect, useRef, useState } from 'react';
import BigNumberSmallChartCard from './components/charts/BigNumberSmallChartCard';
import BigBarChartFitler from './components/charts/BigBarChartFilter';
import BigRingChartFilter from './components/charts/BigRingChartFilter';
import BigLineChartFilter from './components/charts/BigLineChartFilter';
import LastActivitiesList from './components/charts/LastActivitiesList';
import SelectionManager from '../../selection-selector/SelectionSelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import SoftwareProductsWedge from './features/SoftwareProductsWedge';
import { useOutletContext } from 'react-router-dom';
import RequirementsDonut from './features/RequirementsDonut';
import ScenariosDonut from './features/ScenariosDonut';
import WebcastsDonut from './features/WebcastsDonut';
import NextStepsWidget from './features/NextStepsWidget';
import SelectionPhaseModal from '../selection-guide/SelectionPhaseModal';
import ProjectPhasesWidget from './features/ProjectPhasesWidget';
import TaskListWidget from './features/TaskListWidget';
import Cookies from 'universal-cookie';
import FeatureHighlightsWidget from './features/FeatureHighlightsWidget';

const Index = () => {
    const role = useSelector((state: RootState) => state.user.role);
    const {selectionId, setSelectedSelectionPhase} = useOutletContext<any>();
    useEffect(() => {setSelectedSelectionPhase("")}, [])

    const cookies = new Cookies();
    const [ disableModal, setDisableModal ] = useState(false);
    
    return (
      <div className='h-full p-4 overflow-y-auto'>        
        <div className='mb-4 space-y-6'>

          <SoftwareProductsWedge selectionId={selectionId} />

          {/* Next Step Row */}
          <NextStepsWidget selectionId={selectionId} />

          <div className="max-md:max-w-full">
            <div className="lg:max-h-[700px] max-lg:flex-col flex gap-5 max-md:gap-0">

              {/* Tasklist */}
              <div className="w-full lg:w-[66%]">
                <TaskListWidget selectionId={selectionId} />
              </div>
              

              {/* Calendar */}
              <div className="w-full lg:w-[33%]">
                <ProjectPhasesWidget />
              </div>

            </div>
          </div>

          {/* Statistics Row */}
          <div className="max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <RequirementsDonut selectionId={selectionId} />
              </div>
              
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <ScenariosDonut selectionId={selectionId} />
              </div>
              
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <WebcastsDonut selectionId={selectionId} />
              </div>

            </div>
          </div>

        </div>

        {/* <div className="grid gap-4 xl:grid-cols-3">
          <BigLineChartFilter title={"Sales This Week"} />
          <LastActivitiesList />
        </div>
        <div className="grid grid-cols-1 my-4 xl:grid-cols-1 xl:gap-4">
          <div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-3">
            <BigNumberSmallChartCard title="Registered Vendors" bigVal="323" changeVal='+12,4%' />
            <BigNumberSmallChartCard title="Registered Users" bigVal="643" changeVal='+34,7%' />
            <BigNumberSmallChartCard title="Registered Products" bigVal="243" changeVal='+10,7%' />  
          </div>
        </div>
        <div className="grid grid-cols-1 my-4 xl:grid-cols-1 xl:gap-4">
          <div className="grid w-full grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-2">
            <BigBarChartFitler title={"Traffic by user type"} />
            <BigRingChartFilter title={"Traffic by device"} />
          </div>
        </div> */}

        <div className="max-md:max-w-full"> 
          <FeatureHighlightsWidget />
        </div>



        {
          typeof cookies.get('modal-seen:selection-start') === "undefined" ? <SelectionPhaseModal selectionPhaseTaskCode={'selection-start'}  /> : <></>
        }
        
      </div>
    )
}

export default Index