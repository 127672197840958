import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentIntent, StripeError } from "@stripe/stripe-js";
import MainButton from "./MainButton";
import { useTranslation } from "react-i18next";

interface props {
  setCheckoutResult : React.Dispatch<React.SetStateAction<any>>;
}

const PaymentCheckoutForm = ({setCheckoutResult} : props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e : any) => {
    e.preventDefault();

    if (isProcessing || !stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`, // TODO Complete page for redirects
      },
      redirect: "if_required"
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setMessage(`Payment status: ${paymentIntent.status}`);
    } else {
      setMessage("An unexpected error occured.");
    }

    setCheckoutResult({
      error : error,
      paymentIntent: paymentIntent
    })

    setIsProcessing(false);
  };

  return (
    <form className="p-5 w-full" id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className='m-2 mx-auto flex justify-center'>
        <button className="bg-primary rounded items-center gap-3 text-white hover:shadow hover:shadow-primary duration-300 transition-all ease-in-out max-sm:justify-center">
          <div className='flex py-4 px-8'>
            {isProcessing ? `${t("processing")}` : `${t("payNow")}`}
          </div>
        </button>
      </div>
      {message && <div className='text-sm text-gray-500 text-center'>{message}</div>}
    </form>
  );
}

export default PaymentCheckoutForm