import React, { useState } from 'react'
import TableRow from '../../../../../../components/TableRow'
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon'
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon'
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon'
import QuickMenu from '../../../../../../components/QuickMenu'
import TrashIcon from '../../../../../../assets/img/icons/TrashIcon'
import { UpdateSelectionRequirementKeywords, updateSelectionRequirement } from '../../../../../../data/SelectionPortalRequests'

interface props {
    index : number,
    selectionRequirement : any,
    openMenuRequirement : string | undefined,
    setOpenMenuRequirement : React.Dispatch<React.SetStateAction<string | undefined>>,
    handleOpenRequirement : (a:string, b:string) => void,
    handleSetRequirementStatus : (a:string, b:string) => void,
    handleDeleteRequirement : (a:string) => void,
}

const RequirementTableRow = ({index, selectionRequirement, openMenuRequirement, setOpenMenuRequirement, handleOpenRequirement, handleSetRequirementStatus, handleDeleteRequirement} : props) => {
    const [importance, setImportance] = useState(selectionRequirement.selectionRequirements_importance);

    // const handleSetImportance = async (importanceLevel: number) => {
    //     const response = await setSelectionRequirementImportance(selectionRequirement.selectionRequirements_id, importanceLevel);
    //     if (response) {
    //         setImportance(response.data.data.importance);
    //     }
    // }

    const handleSetImportance = async ({title, description, category, importance} :  UpdateSelectionRequirementKeywords) => {
        const response = await updateSelectionRequirement(selectionRequirement.selectionRequirements_id, title, description, category, importance)
        if (response.ok) {
            setImportance(response.data.data.importance);
        }
    }

  return (
    <tbody
    id={selectionRequirement.selectionRequirements_id} className={`cursor-pointer ${selectionRequirement.selectionRequirements_requirementStatus == "archived" ? "opacity-70 hover:opacity-100 transitions-all duration-100" : ""}`} onClick={() => handleOpenRequirement(selectionRequirement.selectionRequirements_id, selectionRequirement.selectionRequirements_requirement)}>
        <TableRow maxRowWidths={["","max-w-[500px]","",""]}
        rowItems={[index, selectionRequirement.selectionRequirements_requirement, <div className='flex space-x-1 items-center group'>
            {
                [...Array(10).keys()].map((ithDot: any, index: number) => {
                    return (
                        <div onClick={(e) => {e.stopPropagation(); handleSetImportance({importance: ithDot+1});}}
                        className={`w-2 h-2 ${(ithDot < Math.ceil(importance/10)) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full`}></div>
                    )
                })
            }
        <div className='font-normal text-gray-900 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
            {importance} %
        </div>
        </div>, <div className='w-full flex justify-center'>
        {
            (selectionRequirement.selectionRequirements_requirementStatus == "warning") ? (
                <div className='group w-full h-6 text-amber-400 flex items-center justify-center'>
                    <WarningIcon iconColor='currentColor' />
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        A warning has been recognised or added manually. A manual review is necessary.
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "auto_completed") ? (
                <div className='group w-full h-6 text-green-400 font-bold flex items-center justify-center'>
                    (<CheckmarkIcon iconColor='currentColor' />)
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        Requirement was automatically checked by Matching Engine. Manual validation might be necessary.
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "completed") ? (
                <div className='group w-full h-6 text-green-400 flex items-center justify-center'>
                    <CheckmarkIcon iconColor='currentColor' />
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        Requirement was checked by user and is completed.
                    </div>
                </div>
            ) : (selectionRequirement.selectionRequirements_requirementStatus == "archived") ? (
                <div className='group w-full h-6 text-red-400 flex items-center justify-center'>
                    <div className='h-8 w-8'><CrossIcon iconColor='currentColor' /></div>
                    <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                        Requirement has been archived by the user and will not be considered further.
                    </div>
                </div>
            ) : (<></>)
        } </div>, <QuickMenu id={selectionRequirement.selectionRequirements_id} openMenuForId={openMenuRequirement} setOpenMenuForId={setOpenMenuRequirement} menuItems={[
            {label: "Completed", value: "completed", icon: <div className='text-green-400'><CheckmarkIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
            {label: "Warning", value: "warning", icon: <div className='text-amber-400'><WarningIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
            {label: "Archived", value: "archived", icon: <div className='text-red-400'><CrossIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
            {label: "Delete", value: "delete", icon: <div className='text-red-400'><TrashIcon /></div>, function: handleDeleteRequirement }
        ]} />]} />
    </tbody>
  )
}

export default RequirementTableRow