import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import { UpdateSelectionRequirementKeywords, createNewSelectionRequirement, createNotification, deleteSelectionRequirement, getRequirementTemplates, getSelectionRequirement, getUniqueSelectionRequirementCategories, setSelectionRequirementStatus, updateSelectionRequirement } from '../../../../../../data/SelectionPortalRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import CommentWindow from './CommentWindow';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import SpeedDial from '../../components/SpeedDial';
import TextInput from '../../../../../../components/TextInput';
import SelectorDropdown from '../../../../../../components/SelectorDropdown';
import MainButton from '../../../../../../components/MainButton';
import TrashIcon from '../../../../../../assets/img/icons/TrashIcon';
import WarningIcon from '../../../../../../assets/img/icons/WarningIcon';
import QuickMenu from '../../../../../../components/QuickMenu';
import RequirementInputWizard from './RequirementWizard/RequirementInputWizard';
import { CheckmarkIcon, CrossIcon, LockIcon } from 'swap-frontend-library';

interface props {
    requirementId : string,
    selectionId : string,
    handleOpenRequirement : (a:string, b:string) => void,
    handleCloseRequirement : (a:string) => void,
    handleUpdateRequirementTab : (a:string, b:string) => void,
    selectedRequirementCategory: any[],
}

const SelectionRequirementForm = ({requirementId, selectionId, handleOpenRequirement, handleCloseRequirement, handleUpdateRequirementTab, selectedRequirementCategory} : props) => {
    const [requirement, setRequirement] = useState<any>();
    const [editingLocked, setEditingLocked] = useState<any>(true);
    const [categories, setCategories] = useState<any[]>();
    const [importanceDots, setImportanceDots] = useState<Number>();
    const [softwareFulfillments, setSoftwareFulfillments] = useState<any[]>();
    const [isNewRequirement, setIsNewRequirement] = useState<boolean>(false);
    
    const [openMenuForId, setOpenMenuForId] = useState<string>();
    
    const [uniqueRequirementCategories, setUniqueRequirementCategories] = useState<any>();
    const [newDescription, setNewDescription] = useState<string | null>(null);
    const [newRequirementTitle, setNewRequirementTitle] = useState<string | null>(null);
    const [newRequirementStaticRequirementId, setNewRequirementStaticRequirementId] = useState<string | null>(null);
    const [newCategory1, setNewCategory1] = useState<string>("");
    const [newCategory2, setNewCategory2] = useState<string>("");
    const [newCategory3, setNewCategory3] = useState<string>("");

    const handleGetSelectionRequirement = async () => {        
        if (requirementId == "-1") { 
            const uniqueRequirementCategoriesTree = await getUniqueSelectionRequirementCategories(selectionId);
            if (uniqueRequirementCategoriesTree.ok) {
                setUniqueRequirementCategories(uniqueRequirementCategoriesTree.data.data.uniqueCategories)
                setIsNewRequirement(true); 
                setImportanceDots(0);
                setCategories([]);
                setRequirement([]);
            }
            return;
        }

        const response = await getSelectionRequirement(requirementId);
        if (response.ok) {
            console.log(response.data.data)
            setRequirement(response.data.data.selectionRequirement);
            setCategories(JSON.parse(response.data.data.selectionRequirement.category));
            setSoftwareFulfillments(response.data.data.fulfillments);

            if (response.data.data.selectionRequirement.staticRequirementId == null) { setEditingLocked(false); }
            
            const importance = response.data.data.selectionRequirement.importance;
            setImportanceDots(Math.ceil(importance/10));
        }
        setIsNewRequirement(false);
    }

    const handleSetImportance = async (importanceLevel: number) => {
        if (requirementId == "-1") {
            setImportanceDots(importanceLevel);
            return;
        }
        await handleUpdateRequirement({importance: importanceLevel});
    }

    const handleDeleteRequirement = async () => {
        if (await deleteSelectionRequirement(requirementId)) {
            handleCloseRequirement(requirementId);
        }
    }

    const handleSetRequirementStatus = async (requirementId: string, status : string) => {
        if (await setSelectionRequirementStatus(requirementId, status)) {
            handleGetSelectionRequirement();
        }
    }
    
    const handleUpdateRequirement = async ({title, description, category, importance} :  UpdateSelectionRequirementKeywords) => {
        const response = await updateSelectionRequirement(requirementId, title, description, category, importance)
        if (response.ok) {
            if (typeof title !== "undefined") {
                handleUpdateRequirementTab(requirementId, response.data.data.requirement);
                handleGetSelectionRequirement();
            } else {
                handleGetSelectionRequirement();
            }
        }
    }

    useEffect(() => {
        handleGetSelectionRequirement();
    }, [])
    
    useEffect(() => {
        handleGetSelectionRequirement();
    }, [requirementId])
    
    useEffect(() => {
        handleGetSelectionRequirement();
    }, [requirementId])

    const handleSaveNewRequirement = async () => {
        if (newRequirementTitle == "" || newRequirementTitle == null) {createNotification("Saving requirement failed: You need to add a title.", "warning"); return;}
        // if (importanceDots == 0 || typeof importanceDots === "undefined") {createNotification("Saving requirement failed: You need to add an importance.", "warning"); return;}
        // if (newDescription == "" || newDescription == null) {createNotification("Saving requirement failed: You need to add a description.", "warning"); return;}
        if (newCategory1 == "" || newCategory2 == "") {createNotification("Saving requirement failed: You need to add categories.", "warning"); return;}
        
        let cleanImportanceDots : Number | null;
        if (importanceDots == 0 || typeof importanceDots === "undefined") { cleanImportanceDots = null; } else { cleanImportanceDots = importanceDots; }
        
        let cleanDescription : string;
        if (newDescription == "" || newDescription == null) { cleanDescription = "" } else { cleanDescription = newDescription; }

        // console.log(newRequirementTitle, newDescription, importanceDots, JSON.stringify([newCategory1, newCategory2, newCategory3]));
        const response = await createNewSelectionRequirement(selectionId, newRequirementTitle, cleanDescription, JSON.stringify([newCategory1, newCategory2, newCategory3, null]), cleanImportanceDots, newRequirementStaticRequirementId);
        if (response.ok) {
            handleCloseRequirement("-1");
            handleOpenRequirement(response.data.data.id, response.data.data.requirement);
        }
    }
    
  return (
    <div className={`w-full h-full flex flex-col pb-6 ${((typeof requirement !== "undefined" && requirement.staticRequirementId != null) && editingLocked) ? "border-2 border-dashed border-gray-800" : ""} overflow-y-auto`}>
        {(typeof requirement === "undefined" || typeof categories === "undefined" || typeof importanceDots === "undefined") ? (<LoadingSpinner />) : (
            <>
                {
                    (requirement.staticRequirementId != null && editingLocked) ? (
                    <div className='absolute z-10 bg-gray-300 h-16 p-2 mr-0.5 text-sm font-semibold flex'>
                        <p>This is an automatically determined requirement that has already been validated for the systems under consideration. If you edit this requirement (title, description or categories), this requirement must be re-evaluated by the vendors of the shortlist.</p>
                        <MainButton text='Unlock' style='light' func={() => setEditingLocked(false)} />
                    </div>
                    ) : (<></>)
                }
                <div className={`relative mx-6 ${(requirement.staticRequirementId != null && editingLocked) ? "mt-20" : "mt-6" } flex flex-col px-5 pt-5 pb-2.5 bg-white rounded-lg shadow max-md:max-w-full`}>
                    <div className="text-md relative font-normal tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                        <div className="w-full mb-4 flex content-center w-full text-sm font-medium leading-5 text-gray-900 bg-white">
                            {
                                isNewRequirement ? (
                                    <div>
                                        <p className='text-sm font-semibold'>Description</p>
                                        <div className="flex items-center space-x-4">
                                            <SelectorDropdown items={Object.keys(uniqueRequirementCategories).map(item => ({label: item, value: item}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory1} setSelectedItem={setNewCategory1} placeholderLabel='Category 1'/>

                                            {
                                                (newCategory1 !== "") ? (
                                                    <>
                                                    <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                    <SelectorDropdown items={Object.keys(uniqueRequirementCategories[newCategory1]).map(item => ({label: item, value: item}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory2} setSelectedItem={setNewCategory2} placeholderLabel='Category 2' />
                                                    </>
                                                ) : (<></>)
                                            }
                                            {
                                                (newCategory1 !== "" && newCategory2 !== "" && typeof uniqueRequirementCategories[newCategory1][newCategory2] !== "undefined") ? (
                                                    <>
                                                    <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                                    <SelectorDropdown items={uniqueRequirementCategories[newCategory1][newCategory2].map((item:any) => ({label: item[0], value: item[0]}))} itemValueKey={'value'} itemLabelKey={'label'} selectedItem={newCategory3} setSelectedItem={setNewCategory3} placeholderLabel='Category 3'/>
                                                    </>
                                                ) : (<></>)
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex gap-5 rounded-md max-md:flex-wrap max-md:max-w-full">
                                        
                                        {
                                        (categories[0] != "") ? (
                                            <div
                                            className='flex items-center'>
                                            <div className="flex-auto">{categories[0]}</div>
                                            </div>
                                        ) : (<></>)
                                        }
                                        {
                                        (categories[1] != "") ? (
                                            <div
                                            className='flex items-center'>
                                            <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                            <div className="flex-auto ml-4">{categories[1]}</div>
                                            </div>
                                        ) : (<></>)
                                        }
                                        {
                                        (categories[2] != "") ? (
                                            <div
                                            className='flex items-center'>
                                            <ArrowRightIcon iconHeight='12' iconWidth='12' iconColor='black' />
                                            <div className="flex-auto ml-4">{categories[2]}</div>
                                            </div>
                                        ) : (<></>)
                                        }
                                    </div>
                                )
                            }
                        </div>

                        <p className='text-sm font-semibold'>Title</p>
                        {
                            isNewRequirement ? (
                                <RequirementInputWizard selectionId={selectionId} newRequirementTitle={newRequirementTitle} setNewRequirementTitle={setNewRequirementTitle} setNewCategory1={setNewCategory1} setNewCategory2={setNewCategory2} setNewCategory3={setNewCategory3} setNewDescription={setNewDescription} setNewRequirementStaticRequirementId={setNewRequirementStaticRequirementId} />
                            ) : (
                                <input disabled={editingLocked} onBlur={() => {if (newRequirementTitle != null && newRequirementTitle != "") {handleUpdateRequirement({title: newRequirementTitle})} else {createNotification("Failed to update requirement: Title cannot be empty.", "warning"); setNewRequirementTitle(null);}}} className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900" value={newRequirementTitle != null ? newRequirementTitle : requirement.requirement} onChange={(e) => setNewRequirementTitle(e.target.value)}/>
                            )
                        }

                        <div className="mt-5">
                            <p className='text-sm font-semibold'>Importance</p>
                            <div className='flex space-x-2'>
                                {
                                    [...Array(10).keys()].map((ithDot: any, index: Number) => {
                                        return (
                                            <div onClick={() => handleSetImportance(ithDot+1)}
                                            className={`w-4 h-4 ${(ithDot < importanceDots) ? ("bg-primary"): ("bg-gray-100 hover:bg-gray-200")} cursor-pointer rounded-full`}></div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="w-full relative justify-between mt-8 max-md:flex-wrap max-md:max-w-full">
                            <p className='text-sm font-semibold'>Description</p>
                            {
                                isNewRequirement ? (<TextInput textValue={newDescription} setTextValue={setNewDescription} rows={6} />) : (
                                    <textarea disabled={editingLocked} rows={4} onBlur={() => {if (newDescription != null) {handleUpdateRequirement({description: newDescription})} else {createNotification("Failed to update requirement: Description cannot be empty.", "warning"); setNewDescription(null);}}} className="w-full rounded text-xl font-bold tracking-tight leading-9 text-gray-900" value={newDescription != null ? newDescription : requirement.description} onChange={(e) => setNewDescription(e.target.value)}/>
                                )
                            }
                        </div>
                    </div>

                    {!isNewRequirement ? (
                        <div className='absolute bottom-5 right-5 flex space-x-4 h-6'>
                            <div 
                            className='h-6 w-6 flex items-center justify-center hover:bg-gray-100 rounded-full'>
                            {
                                (requirement.requirementStatus == "warning") ? (
                                    <div className='group w-full h-10 text-amber-400 flex items-center justify-center'>
                                        <WarningIcon iconColor='currentColor' />
                                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                            A warning has been recognised or added manually. A manual review is necessary.
                                        </div>
                                    </div>
                                ) : (requirement.requirementStatus == "auto_completed") ? (
                                    <div className='group w-full h-10 text-green-400 font-bold flex items-center justify-center'>
                                        (<div className='w-6 h-6'><CheckmarkIcon /></div>)
                                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                            Requirement was automatically checked by Matching Engine. Manual validation might be necessary.
                                        </div>
                                    </div>
                                ) : (requirement.requirementStatus == "completed") ? (
                                    <div className='group w-full h-10 text-green-400 flex items-center justify-center'>
                                        <div className='w-6 h-6'><CheckmarkIcon /></div>
                                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                            Requirement was checked by user and is completed.
                                        </div>
                                    </div>
                                ) : (requirement.requirementStatus == "archived") ? (
                                    <div className='group w-full h-10 text-red-400 flex items-center justify-center'>
                                        <div className='h-8 w-8'><CrossIcon /></div>
                                        <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-28 group-hover:block hidden'>
                                            Requirement has been archived by the user and will not be considered further.
                                        </div>
                                    </div>
                                ) : (<></>)
                            }

                            </div>
                            
                            <QuickMenu id={requirement.id} openMenuForId={openMenuForId} setOpenMenuForId={setOpenMenuForId} menuItems={[
                                {label: "Completed", value: "completed", icon: <div className='w-6 h-6 text-green-400'><CheckmarkIcon /></div>, function: handleSetRequirementStatus },
                                {label: "Warning", value: "warning", icon: <div className='text-amber-400'><WarningIcon iconColor='currentColor' /></div>, function: handleSetRequirementStatus },
                                {label: "Archived", value: "archived", icon: <div className='text-red-400'><CrossIcon /></div>, function: handleSetRequirementStatus },
                                {label: "Delete", value: "delete", icon: <div className='text-red-400'><TrashIcon /></div>, function: handleDeleteRequirement }
                            ]} />

                            {/* <div onClick={handleDeleteRequirement}
                            className='h-10 w-10 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-full'>
                                <div className='w-8 h-8 text-gray-400'><TrashIcon /></div>
                            </div> */}
                        </div>
                    ) : (<></>)}
                    
                    
                    {isNewRequirement ? (<div className='w-full flex justify-end'><div className='mt-4 w-fit'><MainButton text={'Save'} func={handleSaveNewRequirement} /></div></div>) : (<></>)}
                </div>

                {
                    isNewRequirement ? (<></>) : (
                    <div className="grow grid gap-4 mx-6 xl:grid-cols-2">
                        <div className="flex flex-col pt-5 overflow-y-auto pb-2.5 mt-5 bg-white rounded-lg shadow max-md:max-w-full">
                            <div className="text-xl px-5 font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                Fulfillments
                            </div>
                            <div className='grow max-h-96 overflow-y-auto '>
                                {
                                    requirement.staticRequirementId != null && typeof softwareFulfillments !== "undefined" ? (
                                    <table className="w-full text-sm text-left text-gray-500">
                                        <TableHeader header={["Software", "Fulfillment"]} />
                                        {
                                            softwareFulfillments.map((softwareFulfillment: any, index: number) => {

                                                return (
                                                    <tbody className="cursor-pointer">
                                                        <TableRow rowItems={["", softwareFulfillment.softwareFulfillment.softwareProduct.productName, softwareFulfillment.score == 3 ? <div className='text-green-400 w-6 h-6'><CheckmarkIcon /></div> : <div className='text-red-400 w-6 h-6'><CrossIcon /></div>]} />
                                                    </tbody>
                                                );
                                            })
                                        }
                                    </table>
                                    ) : (
                                        <div className='relative w-full h-full overflow-hidden'>
                                            <div className='absolute w-full h-full'>
                                                <div className='absolute h-full w-full z-5 bg-white/30 backdrop-blur rounded flex flex-col items-center justify-center p-6 text-center'>
                                                    <div className='w-12 h-12 bg-primary text-white p-2 rounded-full'><LockIcon /></div>
                                                    <p className='text-xl font-semibold mt-4'>System fulfillment to be determined</p>
                                                    <p className=''>This is a custom requirement that has not yet been evaluated for fulfillment of the systems. Once the documentation of the requirements has been completed, these custom requirements can be sent collectively to the vendors for evaluation. </p>
                                                </div>
                                            </div>
                                            <div className='p-2'>
                                                {
                                                    [{software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}, {software: "System A", fulfillment: 90, type: "Standard"}]
                                                    .map((softwareFulfillment: any, index: number) => {

                                                        return (
                                                            <tbody className="cursor-pointer">
                                                                <TableRow rowItems={["", softwareFulfillment.software, `${softwareFulfillment.fulfillment} %`, softwareFulfillment.type, "None"]} />
                                                            </tbody>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flex flex-col px-5 pt-5 pb-2.5 mt-5 bg-white rounded-lg shadow max-md:max-w-full">
                            <div className="text-xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                                Comments
                            </div>
                            <div className='h-full'>
                                <CommentWindow requirementId={requirementId} />
                            </div>
                        </div>
                    </div>
                    )
                }
            </>
        )}

        <SpeedDial speedDials={[]} defaultDials={true} currentContext='requirements' offsetY={isNewRequirement ? "bottom-5" : "bottom-32"}/>

    </div>
  )
}

export default SelectionRequirementForm