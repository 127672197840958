import React from 'react'

interface props {
    messageEmail: string
    ownEmail: string
    text: string
    date: string
}

const MessageBubble = ({messageEmail, ownEmail, text, date} : props) => {
    const createdDateParsed : Date = new Date(Date.parse(date));
    // ${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()} - 
    const createdDateString : string = `${createdDateParsed.getHours().toString().padEnd(2,"0")}:${createdDateParsed.getMinutes().toString().padEnd(2,"0")}`;
  return (
    <div>
        <div className={`flex flex-col items-start ${(messageEmail == ownEmail) ? ("items-end text-right") : ("")}`}>
            {/* {(messageEmail == ownEmail) ? (<></>) : (<div className='w-8 h-8 flex items-center justify-center rounded-full bg-gray-300 font-bold text-center text-white'><p>{String(messageEmail[0]).toUpperCase()}</p></div>)} */}
            <div className={`relative flex flex-col max-w-[95%] group leading-1.5 p-2 border-gray-200 bg-gray-100 ${(messageEmail == ownEmail) ? ("rounded-s-xl rounded-ee-xl bg-primary") : ("rounded-e-xl rounded-es-xl")} dark:bg-gray-700`}>
                <p style={{wordBreak: "break-word"}} className={`text-sm inline-block font-normal py-1 text-gray-900 max-w-full text-wrap overflow-hidden`}>{text}</p>
                {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span> */}
            </div>
            <div className="flex flex-col text-xs font-light text-gray-500">
                <p className='inline'>{messageEmail != ownEmail ? `${messageEmail} - ` : ""}<span className="inline">{createdDateString}</span></p>
            </div>
            
            {/* {(messageEmail == ownEmail) ? (<div className='w-8 h-8 flex items-center justify-center rounded-full bg-primary font-bold text-center text-white'><p>{String(messageEmail[0]).toUpperCase()}</p></div>) : (<></>)} */}
        
        {/* <button id="dropdownMenuIconButton" data-dropdown-toggle="dropdownDots" data-dropdown-placement="bottom-start" className="inline-flex self-center items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-900 dark:hover:bg-gray-800 dark:focus:ring-gray-600" type="button">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
            </svg>
        </button>
        <div id="dropdownDots" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600">
            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton">
                <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Reply</a>
                </li>
                <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Forward</a>
                </li>
                <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Copy</a>
                </li>
                <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Report</a>
                </li>
                <li>
                    <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
                </li>
            </ul>
        </div> */}
        </div>
    </div>
  )
}

export default MessageBubble