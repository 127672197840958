import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { deleteVendorInvite, fetchVendorInviteData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import DeleteModal from '../../../../../../components/DeleteModal';
import { deleteClosedBetaInviteCode, getAllClosedBetaInviteCodes } from '../../../../../../data/ClosedBetaRequests';

interface props {
    openAdd : React.Dispatch<React.SetStateAction<boolean>>;
}

const ClosedBetaInvitesTable = ({openAdd} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [inviteData, setInviteData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const handleFetchInviteData = () => {
        setInviteData([]);
        getAllClosedBetaInviteCodes().then(data => {setInviteData(data.data); setFilteredData(data.data); setLoaded(true);})
    }

    useEffect(() => {
        handleFetchInviteData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteInvite = async (inviteId : string) => {
        console.log(inviteId)
        await deleteClosedBetaInviteCode(inviteId)
        handleFetchInviteData();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteInvite(deleteItem.inviteCode)} content={
            <div>
                Soll der Invite {deleteItem.inviteCode} wirklich gelöscht werden?
            </div>
        } /> : <></>}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Closed-beta {t("inviteCode")}s
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("totalItems", {length : inviteData.length, itemType: `${t("inviteCode")}s`})}</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("inviteCode")}`} originalData={inviteData} setFilteredData={setFilteredData} filterKey={"note"} />
                </div>
            
            <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleFetchInviteData()}
                type="button"
                data-refresh
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>
                <button
                onClick={() => openAdd((prev) : boolean => !prev)}
                id="createProductButton"
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="button"
                >
                <PlusIcon iconColor='white' />
                {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <TableHeader header={[`${t("inviteCode")}`, `${t("note")}`, `${t("expired")}`, `${t("createdOn")}`, `${t("validUntil")}`, ""]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={6} className='h-16'>No invite codes available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, inviteCode, note, activated, createdDate} : any, index : number) => {
                            const createdDateParsed : Date = new Date(Date.parse(createdDate));
                            const validUntilDateParsed : Date = new Date(Date.parse(activated));
                            const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}-${createdDateParsed.getHours()}:${createdDateParsed.getMinutes()}`;
                            const validUntilDateString : string = `${validUntilDateParsed.getDate()}.${validUntilDateParsed.getMonth()+1}.${validUntilDateParsed.getFullYear()}-${validUntilDateParsed.getHours()}:${validUntilDateParsed.getMinutes()}`;
                            return (
                                <tbody key={index}>
                                    <TableRow 
                                        rowItems={[index, inviteCode, note, String(new Date() > validUntilDateParsed), createdDateString, (activated == null) ? "Not activated" : validUntilDateString]}
                                        deleteHandler={() => {handleOpenDeleteModal({id,inviteCode})}}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

export default ClosedBetaInvitesTable