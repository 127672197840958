import React from 'react'

interface props {
    rating : number
}

const PercentageCircle = ({rating} : props) => {
  const circumference : number = 2 * 22 / 7 * 22;
  return (
    <div className="relative flex items-center justify-center" >
        <svg className="transform w-12 h-12">
        <circle cx="24" cy="24" r="21" stroke="currentColor"
            strokeWidth="5"
            fill="transparent"
            className="text-secondary6"
        />
        <circle
            cx="24"
            cy="24"
            r="21"
            stroke="currentColor"
            strokeWidth="5"
            fill="transparent"
            strokeDasharray={circumference}
            strokeDashoffset={circumference - rating / 100 * circumference}
            className="text-primary"
        />
        </svg>
        <span className="absolute text-xs font-semibold">{rating}</span>
    </div>
  )
}

export default PercentageCircle