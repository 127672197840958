import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteError } from "react-router-dom";
import MainButton from '../../../components/MainButton';

const Index = () => {
    const { t } = useTranslation();
    const error: any = useRouteError();
    const { manualErrorMessage } = useParams();
  
    return (
        <div className="max-w-[942px] mx-auto px-6 max-lg:px-5 max-sm:px-4 mt-10 max-sm:mt-8 ">
        <div className="rounded-lg shadow-card bg-white p-10 max-sm:p-4">
            <div id="error-page">
            <h1 className="text-5xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">
                <span className="text-primary">{t('error.oops')}</span>
            </h1>
            <p className="text-secondary2 font-normal max-w-[540px] my-6 max-xl:max-w-[480px] max-lg:max-w-lg">
                {t('error.unexpectedError')}
                <br>
                </br>
                { ( typeof error === "undefined") ? (
                    <i>{t("error.errorMessage", {error: manualErrorMessage})}</i>
                ) : (
                    <i>{t("error.errorMessage", {error: error.statusText || error.message })}</i>
                )}
            </p>
            <MainButton text={t('error.home')} icon='' link='/' />
            </div>
        </div>
        </div>
    );
}

export default Index;