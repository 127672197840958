import React, { useEffect, useState } from 'react'
import CrossIcon from '../assets/img/icons/CrossIcon'
import { useDispatch } from 'react-redux';
import NotificationSlice, {actions} from '../redux/notificationSlice';

interface props {
    id: string,
    text: string,
    type: string
}

const Notification = ({id, text, type} : props) => {
    const dispatch = useDispatch();

    const removeNotification = () => {
        dispatch(
            actions.removeNotificationById({ id })
        );
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(
                actions.removeNotificationById({ id })
            );
        }, 10000);
    }, []);

    return (
        <div style={{transition: "translate"}} className={`${true ? "translate-x-0" : "translate-x-96"} w-96 transitions-all duration-1000 flex items-center justify-between w-full max-w-xs p-4 border text-gray-500 ${(type == "error") ? "border-red-400" :(type == "warning") ? "border-amber-400" : "border-green-400"} bg-white rounded-lg shadow-md`} role="alert">
            <div className="text-sm font-normal">
            {text}
            </div>
            <div className="ml-8 flex items-center ms-auto space-x-2 rtl:space-x-reverse">
                <div className="text-gray-300 text-sm font-medium h-8 p-1.5 hover:bg-gray-100 rounded-md cursor-pointer">Undo</div>
                <div onClick={removeNotification} className='text-gray-300 hover:bg-gray-100 rounded-md p-1 w-8 h-8 m-auto cursor-pointer'><CrossIcon iconColor='currentColor' /></div>
            </div>
        </div>
  )
}

export default Notification