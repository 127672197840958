import { createSlice } from "@reduxjs/toolkit";
// import { extraAction } from "../extraAction";

export interface NotificationState {
    id: string; // is unix timestamp
    message: string;
    type: string;
}

export interface NotificationsState {
    notifications: NotificationState[];
}

const initialState: NotificationsState = {
    notifications: [],
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
    createNotification: (state, action) => {
        state.notifications.push({
            id: action.payload.id,
            message: action.payload.message,
            type: action.payload.type
        });
    },

    removeNotificationById: (state, action) => {
        state.notifications = state.notifications.filter((item : NotificationState)  => item.id !== action.payload.id)
        }
    }
});

//   extraReducers: {
//     [extraAction]: (state, action) => {
//       state.alerts.push({ message: action.error.message, type: "error" });
//     }
//   }

export const actions = notificationSlice.actions;

export default notificationSlice;