import React, { useState } from 'react';

interface props {
    totalCompletion: any[]
}

const CompletionBar = ({totalCompletion}: props) => {
    const [percentage, setPercentage] = useState(Math.round(totalCompletion[0] / totalCompletion[1] * 100))

  return (
    <div className={`w-[100%] px-0 md:px-4 lg:px-5 mx-auto mt-10 max-sm:mt-8 timeline`}>
        <div className="h-[20px] flex justify-between items-stretch">
            <div className="h-full mx-auto w-[70%] bg-gray-200 rounded-full dark:bg-gray-700">
                <div className={`h-full bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`} style={{width: `${percentage.toString()}%`}}>
                        { 
                            (percentage >= 5) ? (`${percentage} %`) : ("")
                        }
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompletionBar