import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

interface props {
    options: string[],
    label: string,
    filteredData: any[],
    filterKey: string,
    setFilteredData: React.Dispatch<React.SetStateAction<any[]>>;
    originalData: any[]
}

const DropdownMenu = ({options, label, filteredData, filterKey, setFilteredData, originalData} : props) => {
    const {t} = useTranslation();
    const [dropdownValue, setDropdownValue] = useState("");
    const [valueChanged, setValueChanged] = useState(false);

    const handleFilterDataDropdown = (e : any) => {
        const value = e.target.value;
        setDropdownValue(value);
        if (value == "") {
            setFilteredData(originalData);
        } else {
            const tempData = originalData.filter((obj : any) => obj[filterKey].toLowerCase().includes(value.toLowerCase()));
            setFilteredData([...tempData]);
        }
        setValueChanged(true);
    }
    
    useEffect(() => {
        if (!valueChanged) {
            setDropdownValue("");
        }
        setValueChanged(false);
    }, [filteredData])

  return (
    <div>
        {
            (label != "") ? (
                <label className="block text-sm font-medium text-gray-900 dark:text-white">{label}</label>
            ) : (<></>)
        }
        <select onChange={handleFilterDataDropdown} value={dropdownValue}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{WebkitAppearance: "none"}}>
            <option value="" disabled hidden>{t("filterBy", {"filterKey": filterKey})}</option>
            {
                (dropdownValue == "") ? (
                    <></>
                    ) : (
                    <option value="">{t("all")}</option>
                )
            }
            {
                options.map((item : any, index : number) => {
                    return (<option key={index} value={item}>{item}</option>);
                })
            }
        </select>
    </div>
  )
}

export default DropdownMenu