import React, { useEffect, useState } from 'react'
import ParticipantIcon from '../../presentation-overview/components/ParticipantIcon'
import { updatePresentationParticipant, getUsersSelection } from '../../../../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import { LoadingSpinner } from 'swap-frontend-library';

const SidebarParticipants = ({participants, presentationId, handleGetSelectionPresentation} : {participants: string[], presentationId: string, handleGetSelectionPresentation: () => {}}) => {
const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
const [usersSelection, setUsersSelection] = useState<any[]>();
  
  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId);
    // console.log(data.data.data)
    setUsersSelection(data.data.data);
  }
  
  const handleAddParticipant = async (userId: string) => {
    const response = await updatePresentationParticipant(presentationId, userId, false);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }
  
  const handleDeleteParticipant = async (userId: string) => {
    const response = await updatePresentationParticipant(presentationId, userId, true);
    if (response.ok) {
      handleGetSelectionPresentation();
    } 
  }

  useEffect(() => {
    handleFetchUsersSelection();
  }, [])

  return (
    <div className='flex flex-col gap-4 p-4 w-96'>
      {
          participants.sort((a : any, b : any) => (a.user.email < b.user.email ? -1 : 1)).map((participant : any) => (
              <div onClick={() => handleDeleteParticipant(participant.user.id)}
              className='flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                  <ParticipantIcon name={participant.user.email.replace("@", " ")} />
                  <p className='font-semibold text-nowrap'>{participant.user.email}</p>
              </div>

          ))
      }
      {
          (typeof usersSelection === "undefined") ? <LoadingSpinner /> : (
            usersSelection.filter(selectionUser => !participants.map((participant : any) => participant.user.id).includes(selectionUser.user.id)).sort((a : any, b : any) => (a.user.email < b.user.email ? -1 : 1)).map(selectionUser => (
                <div onClick={() => handleAddParticipant(selectionUser.user.id)}
                className='opacity-30 hover:opacity-100 flex items-center gap-4 hover:bg-gray-50 cursor-pointer rounded-r-full rounded-l-full'>
                    <ParticipantIcon name={selectionUser.user.email.replace("@", " ")} checked={false} />
                    <p className='font-semibold text-nowrap'>{selectionUser.user.email}</p>
                </div>
            ))
          )
      }
    </div>
  )
}

export default SidebarParticipants