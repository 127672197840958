import React, { useEffect, useState } from 'react'
import { FormModal, LoadingSpinner, MainButton } from 'swap-frontend-library';
import { createSelectionPresentation, getSelectionRanking, getUsersSelection } from '../../../../../../../../data/SelectionPortalRequests';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Calendar from '../../../../../../../../components/CalendarWidget';

interface props {
    modalOpen: boolean,
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    selectionId: string,
    handleGetSelectionPresentations: () => {}
}

const AddPresentationModal = ({modalOpen, setModalOpen, selectionId, handleGetSelectionPresentations} : props) => {
    const {t} = useTranslation();
    const [ usersSelection, setUsersSelection ] = useState<any[]>();
    const [ softwareProducts, setSoftwareProducts ] = useState<any[]>();
    const [ selectedSoftwareProduct, setSelectedSoftwareProduct ] = useState<any>();
    const [ timeFrom, setTimeFrom ] = useState<string>();
    const [ timeFromDate, setTimeFromDate ] = useState<Date>();
    const [ timeTo, setTimeTo ] = useState<string>();
    const [ timeToDate, setTimeToDate ] = useState<Date>();
    const [ timeError, setTimeError ] = useState(false);
    const [ duration, setDuration ] = useState<{hours:number, minutes:number}>();
    const [ date, setDate ] = useState<Date | null>(null);
    const [ title, setTitle ] = useState<string>("Vendor presentation");
    const [ participants, setParticipants ] = useState<string[]>([]);

    const handleGetSelectionSoftwareProducts = async () => {
        const response = await getSelectionRanking(selectionId);
        setSoftwareProducts(response.data.data.results);
    }

    const handleFetchUsersSelection = async () => {
        const data = await getUsersSelection(selectionId);
        console.log(data.data.data)
        setUsersSelection(data.data.data);
    }
    
    const handleUpdateParticipants = (userId: string) => {
        if (participants.includes(userId)) {
            setParticipants(participants.filter(item => item != userId));
        } else {
            setParticipants(prev => [...prev, userId]);
        }
    }

    const parseTime = (timeString: string) => {
        var d = new Date();
        var time = timeString.match( /(\d+)(?::(\d\d))?\s*(p?)/ );
        if (time == null) return

        d.setHours( parseInt( time[1]) + (time[3] ? 12 : 0) );
        d.setMinutes( parseInt( time[2]) || 0 );
        
        if (typeof date !== "undefined" && date != null) {
            d.setDate(date.getDate());
            d.setMonth(date.getMonth());
            d.setFullYear(date.getFullYear());
        }

        return d;
    }

    const handleSavePresentation = async () => {
        console.log(title, selectedSoftwareProduct, date, timeFromDate, timeToDate, participants)

        if (typeof date === "undefined" || date == null || typeof timeFrom === "undefined" || typeof timeTo === "undefined" || typeof selectedSoftwareProduct === "undefined") return

        const from = parseTime(timeFrom)
        const to = parseTime(timeTo)
        
        if (typeof from === "undefined" || typeof to === "undefined") return

        const success = await createSelectionPresentation(selectionId, selectedSoftwareProduct, from, to, title, participants);
        if (success) {
            handleGetSelectionPresentations();
            setModalOpen(false);
        }
    }

    useEffect(() => {
        setTimeError(false);
        if (typeof timeFrom !== "undefined") setTimeFromDate(parseTime(timeFrom));
        if (typeof timeTo !== "undefined") setTimeToDate(parseTime(timeTo));
    }, [timeFrom, timeTo])
    
    useEffect(() => {
        if (typeof timeToDate !== "undefined" && typeof timeFromDate !== "undefined") {
            const d = (timeToDate.getTime() - timeFromDate.getTime()) / 1000 / 60 / 60;
            const hours = Math.trunc(d);
            const minutes = Math.round(((d - hours)*60) * 100) / 100;
            setDuration({hours, minutes})
            if (d < 0) return setTimeError(true)
        }
    }, [timeFromDate, timeToDate])

    useEffect(() => {
        handleGetSelectionSoftwareProducts();
        handleFetchUsersSelection();
    }, [])

    return (
    <FormModal isOpen={modalOpen} setOpen={setModalOpen}>
        <div>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Add vendor presentation</h3>
            {/* selectionSoftwareProductId, timeFrom, timeTo, title, participants */}


            <div>
                <label className="block text-sm font-medium text-gray-900 mt-4">Title</label>
                <input placeholder={``} value={title} onChange={(event) => {setTitle(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
            </div>

            <div className='grid grid-cols-2 gap-4 mt-4'>
                <div>
                    <Calendar selectedDate={date} setSelectedDate={setDate} />
                </div>
                <div className='flex flex-col'>
                    <div className='flex gap-4 mt-4'>
                        <div>
                            <label className="block text-sm font-medium text-gray-900 ">Presentation start</label>
                            <input type="time" placeholder={`HH:MM`} value={timeFrom} onChange={(event) => {setTimeFrom(event.target.value)}} name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5 "  required></input>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-900 ">Presentation end</label>
                            <input type="time" min={timeFrom} placeholder={`HH:MM`} value={timeTo} onChange={(event) => {setTimeTo(event.target.value)}} name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fit p-2.5"  required></input>
                        </div>
                    </div>
                    <div className='mt-4 border rounded bg-gray-50 grow flex items-center justify-center'>
                        {
                            typeof duration !== "undefined" && !timeError ? (
                                <p className='font-semibold'>{duration?.hours} hours, {duration?.minutes} minutes</p>
                            ) : <></>
                        }

                        {
                            timeError ? (
                                <p className='text-red-400 font-semibold'>Error: End date is before start date.</p>
                            ) : <></>
                        }
                    </div>
                </div>
            </div>

            {/* <div>
                <label className="block text-sm font-medium text-gray-900 mt-4">Date</label>
                <input placeholder={`DD/MM/YYYY`} value={date} onChange={(event) => {setDate(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
            </div> */}


            {
                (typeof softwareProducts === "undefined") ? <LoadingSpinner /> : (
                <div className='mt-4'>
                    <label htmlFor="countries" className="block text-sm font-medium text-gray-900 dark:text-white">{t("vendorCompany")}</label>
                    <select onChange={(event) => setSelectedSoftwareProduct(event.target.value)}
                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{WebkitAppearance: "none"}}>
                        <option value="" selected disabled hidden>{t("chooseHere")}</option>
                        {
                            softwareProducts.map((item : any, index : number) => {
                                if (item.included) return (<option key={index} value={item.id}>{item.softwareProduct.vendorDetails.company} {item.softwareProduct.productName}</option>);
                            })
                        }
                    </select>
                </div>
                )
            }

            {
                (typeof usersSelection === "undefined") ? <LoadingSpinner /> : (
                    <div className='mt-4'>
                        <label htmlFor="countries" className="block text-sm font-medium text-gray-900 dark:text-white">Participants</label>
                        <div className='flex flex-wrap gap-4'>
                        {
                            usersSelection.map(selectionUser => (
                                <div onClick={() => handleUpdateParticipants(selectionUser.user.id)}
                                className={`cursor-pointer border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 ${participants.includes(selectionUser.user.id) ? "bg-primary" : "bg-gray-50"}`}>
                                    {selectionUser.user.email}
                                </div>
                            ))
                        }
                        </div>
                    </div>
                )
            }
        <div className='mt-4'><MainButton text={'Save'} func={handleSavePresentation}/></div>
        </div>
    </FormModal>
  )
}

export default AddPresentationModal