import React, { useState } from 'react'
import MainButton from './MainButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryUrl, queryUrlTestingMode } from '../data/CustomerSearchRequests';
import SearchIcon from '../assets/img/icons/SearchIcon';
import QueryLoading from './QueryLoading';

interface props {
    testing?: boolean,
    testingMode?: any 
    setResult?: React.Dispatch<React.SetStateAction<any>>;
}

const QueryField = ({testing, testingMode, setResult} : props) => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [queryUrlInput, setQueryUrlInput] = useState("");
    const [queryLoading, setQueryLoading] = useState(false);

    const [queryOk, setQueryOk] = useState(false);
    
    const [domainEmptyError, setDomainEmptyError] = useState(false);
    const [domainFormatError, setDomainFormatError] = useState(false);
    const [domainContentError, setDomainContentError] = useState(false);
    const [domainSearchError, setDomainSearchError] = useState(false);
    const [domainSearchErrorMessage, setDomainSearchErrorMessage] = useState("");
    // TODO Error Struktur könnte insgesamt ausgebaut werden --> Ein "Gesamt-Error" wie bei Registration Form

    const handleQuerySubmit = () => {
        if (queryUrlInput == "") { setDomainEmptyError(true); return; }
        if (domainSearchError || domainContentError || domainFormatError || domainEmptyError) { return; }
        
        setQueryLoading(true);
        setQueryOk(false);

        let queryPromise : any;
        if (testing) {
            queryPromise = queryUrlTestingMode(queryUrlInput, testingMode);
        } else {
            queryPromise = queryUrl(queryUrlInput);
        }

        queryPromise.then((res : any) => {if (res.success) {
            if (typeof setResult !== "undefined") { setResult(res.data)}
            setQueryOk(true)
        } else {
            if(res.status == 404){
                console.error("Error in search occured");
                setDomainSearchError(true);

                let baseErrorMessage = t("query.DomainSearchError")
                let errorMessage = baseErrorMessage + t("query.DomainSearchErrorAlternatives.evaluationError");
                console.log(queryUrlInput + " " + res.message)
                if (res.message == "RequestFailedException" || res.message == "NoLinksFoundException" || res.message == "NoTextsExtracted") { errorMessage = baseErrorMessage + t("query.DomainSearchErrorAlternatives.websiteError"); }
                setDomainSearchErrorMessage(errorMessage);
            } else { setQueryLoading(false); }
            if(res.status == 400){
                // TODO Tim Ausgaben implementiert, bitte prüfen 
                // Bei leerer URL zusätzlich unten abgefangen, hier ist der Backend check unten Frontend-Prüfung
                console.error("Error Submitted URL is empty");
                setDomainEmptyError(true);
            }
            if(res.status == 401){
                console.error("Error Submitted URL is not a valid URL");
                setDomainFormatError(true);
            }
            if(res.status == 402){
                console.error("Error Submitted URL may contain unwanted content");
                setDomainContentError(true);
            }
        }});
    }

    const handleNavigate = () => {
        if (queryOk) {
            if (testing) {
                ;
            } else {
                navigate("search");
            }
        } else {
            console.error("Error in query");
        }
    }
  return (
    <>
        {(queryLoading) ? (<QueryLoading queryOk={queryOk} handleNavigate={handleNavigate} isOpen={queryLoading} setOpen={setQueryLoading} domainSearchError={domainSearchError} domainSearchErrorMessage={domainSearchErrorMessage} />) : (<div className="hidden"></div>)}

            <div className={`p-2 pl-0 border ${(domainEmptyError || domainFormatError || domainContentError || domainSearchError) ? ("border-red-500") : ("border-secondary6")} rounded-lg flex items-center justify-between bg-white max-sm:items-start max-sm:items-start max-sm:p-2`}>
                <input onKeyDown={(event) => {if (event.key == "Enter") {handleQuerySubmit()}}} onChange={(event) => {setDomainEmptyError(false); setDomainFormatError(false); setDomainContentError(false); setDomainSearchError(false); setDomainSearchErrorMessage(""); setQueryUrlInput(event.target.value)}}
                type="search" name="search" id="search" placeholder={`${t('query.pasteCompanyUrl')}`} className="bg-transparent outline-none border-none placeholder:text-secondary4 placeholder:text-sm font-normal px-6 w-4/5 max-sm:h-11 max-sm:w-full max-sm:p-0"></input>
                <MainButton text={`${t('query.matchButton')}`} text_location='left' icon={<SearchIcon iconColor='white' />} link="" func={handleQuerySubmit}/>
            </div>
            <div className='w-full'>
                {(domainEmptyError) ? ( <p className='text-xs italic text-rose-500 text-center'>{t('query.DomainEmptyError')}</p> ) : (<></>)}
                {(domainFormatError) ? ( <p className='text-xs italic text-rose-500 text-center'>{t('query.DomainFormatError')}</p> ) : (<></>)}
                {(domainContentError) ? ( <p className='text-xs italic text-rose-500 text-center'>{t('query.DomainContentError')}</p> ) : (<></>)}
                {(domainSearchError) ? ( <p className='text-xs italic text-rose-500 text-center'>{domainSearchErrorMessage}</p> ) : (<></>)}
            </div>
    </>
  )
}

export default QueryField