import React from 'react'
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../components/MainButton';
import PercentageCircle from '../../../../../components/PercentageCircle';

interface props {
    vendorLeads : any[],
    handleOpenAccordion : (idx : string) => void,
    openLeadsIdxs : string[],
    handleUnlockLead: (leadId: string) => void,
}

const LeadRow = ({vendorLeads, handleOpenAccordion, openLeadsIdxs, handleUnlockLead} : props) => {
    const { t } = useTranslation();

  return (
    <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
        {
            vendorLeads.map((lead : any, index1: number) => {
                let leadId = lead.leadId;
                let leadPurchased = lead.leadPurchased
                let leadCustomer = lead.customerCompanyName;
                let numPagesSearched = lead.numSearches;
                let maxScore = lead.maxScore;
                let numMatchedSoftware = lead.numSoftwareMatches;

                const createdDateParsed : Date = new Date(Date.parse(lead.leadDate));
                const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;

                return (
                    <div key={index1}>
                        <div onClick={() => {handleOpenAccordion(leadId)}}
                        className={`cursor-pointer ${(openLeadsIdxs.includes(leadId)) ? ("border-slate-200") : ("")} hover:border-slate-200 hover:bg-[#FAFAFA]/50 m-2 flex border border-white rounded-md bg-white justify-between items-center gap-9.5 gap-y-6 px-4 mb-0 py-3 max-md:flex-wrap max-sm:gap-x-5`}>
                            <div className="p-1 flex gap-4 w-2/3 items-center">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">Lead {index1+1}</p>
                                    <h6 className={`${(leadPurchased) ? ("") : ("blur-sm")} text-base leading-5 text-secondary1 font-semibold`}>{leadCustomer}</h6>
                                </div>
                            </div>

                            <div className="p-1 flex gap-1 text-secondary1 whitespace-nowrap max-md:flex-wrap max-md:order-2">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">{t("numPagesSearched")}</p>
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold">{numPagesSearched}</h6>
                                </div>
                            </div>
                            
                            <div className="p-1 flex gap-1 text-secondary1 whitespace-nowrap max-md:flex-wrap max-md:order-2">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">{t("maxSoftwareScore")}</p>
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold">{maxScore}</h6>
                                </div>
                            </div>
                            
                            <div className="p-1 flex gap-1 text-secondary1 whitespace-nowrap max-md:flex-wrap max-md:order-2">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">{t("numMatchedSoftware")}</p>
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold">{numMatchedSoftware}</h6>
                                </div>
                            </div>
                            
                            <div className="p-1 flex gap-1 text-secondary1 whitespace-nowrap max-md:flex-wrap max-md:order-2">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">{t("lastActivity")}</p>
                                    <h6 className="text-base leading-5 text-secondary1 font-semibold">{createdDateString}</h6>
                                </div>
                            </div>

                            <div className="p-1 flex gap-1 text-secondary1 whitespace-nowrap max-md:flex-wrap max-md:order-2">
                                <div className="">
                                    <p className="font-normal leading-4 text-sm text-secondary1 mt-2">{t("leadPrice")}</p>
                                    <h6 className="text-base no-wrap leading-5 text-secondary1 font-semibold">{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(lead.leadPrice/100)}</h6>
                                </div>
                            </div>
                            
                            <div className="p-1 flex gap-1 text-secondary1 max-md:flex-wrap max-md:order-2">
                                {
                                    (leadPurchased) ? (<div></div>) : (
                                        <div onClick={(e) => {e.stopPropagation(); handleUnlockLead(leadId)}}>
                                            <MainButton text={`${t("unlock")}`} icon="" link="" />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className={`${(openLeadsIdxs.includes(leadId)) ? ("") : ("hidden")} border-slate-200 m-2 mb-4 border border-white rounded-md bg-white justify-between items-center mt-0 border-t-0 rounded-t-none px-4 max-md:flex-wrap max-sm:gap-x-5`}>
                            {
                                lead.searches.map((search: any, index2: number) => {
                                    const createdDateParsed : Date = new Date(Date.parse(search.createdDate));
                                    const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
                                    return (
                                        <div key={index2} className="mb-4 border-b grid grid-cols-1 md:grid-cols-2 text-gray-500 dark:text-gray-400">
                                            <div>
                                                <div className='flex items-center'>
                                                    <p className='font-bold leading-4 text-sm mt-2 pr-2'>{t("queryUrl")}</p>
                                                    <p className={`${(leadPurchased) ? ("") : ("blur-sm")} font-normal leading-4 text-sm mt-2`}>{search.queryUrl}</p>
                                                </div>
                                                <div className='flex items-center'>
                                                    <p className='font-bold leading-4 text-sm mt-2 pr-2'>{t("createdOn")}</p>
                                                    <p className='font-normal leading-4 text-sm mt-2'>{createdDateString}</p>
                                                </div>
                                            </div>
                                            <table className='table-auto md:table-fixed'>
                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3">
                                                            {t("softwareProducts")}
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            {t("score")}
                                                        </th>
                                                        <th scope="col" className="px-6 py-3">
                                                            {t("rank")}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    search.softwareProducts.map((leadDetails : any, index3: number) => {
                                                        return (
                                                            <tr key={index3} className="bg-white dark:bg-gray-800 dark:border-gray-700">
                                                                <td className="px-6 py-4">
                                                                    {leadDetails.name}
                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    <PercentageCircle rating={leadDetails.score} />
                                                                </td>
                                                                <td className="px-6 py-4">
                                                                    {leadDetails.rank}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            })
        }
    </div>
  )
}

export default LeadRow