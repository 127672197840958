import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import LoadingSpinner from './LoadingSpinner';
import ArrowRightIcon from '../assets/img/icons/ArrowRightIcon';

interface Props {
  text: string;
  text_location?: string;
  icon?: string | React.ReactElement;
  icon_color?: string;
  link?: string;
  linkNewTab?: boolean;
  func? : () => void;
  disabled?: boolean;
  style?: string; 
  additionalStyles?: string; 
  height?: string
}

const MainButton = ({text, height, text_location, icon, icon_color, link, linkNewTab, func, disabled, style, additionalStyles}: Props) => {
  const [ buttonPushed, setButtonPushed ] = useState(false);
  const [ textLeft, setTextLeft ] = useState("");
  const [ textRight, setTextRight ] = useState("");
  const [ iconPath, setIconPath ] = useState("");

  const configVisuals = () => {
    let visibleText = text;
    if (typeof icon === "string") {setIconPath(icon);}

    if (buttonPushed) { visibleText = ""; setIconPath(""); }
    // Config text location 
    if (text_location ==  "right") {
      setTextRight(visibleText);
    } else {
      setTextLeft(visibleText);
    }
  }

  const handleExecuteFunction = async () => {
    if (typeof func !== "undefined") {
      if (buttonPushed || disabled) return;
      setButtonPushed(true);
      await func();
      setButtonPushed(false);
    }
  }

  useEffect(configVisuals, []);
  useEffect(configVisuals, [buttonPushed]);
  useEffect(configVisuals, [text]);

  return (
    <Link to={(link || "")} target={linkNewTab ? "_blank" : ""}>
        <button 
          disabled={(disabled || buttonPushed) ? (true) : (false)}
          className={`w-full flex ${additionalStyles || "bg-primary hover:shadow-primary"} ${typeof height !== "undefined" ? height : "h-[50px]"} ${(style == "light") ? ("bg-white text-primary3 border border-primary3") : (additionalStyles?.includes("text-")) ? ("") : ("text-white")} rounded items-center justify-center gap-3 hover:shadow duration-300 transition-all ease-in-out max-sm:justify-center disabled:cursor-not-allowed disabled:transition-none disabled:bg-gray-300 disabled:shadow-none`}
          onClick={handleExecuteFunction}>
          <div className='flex gap-3 py-4 px-8 items-center'>
            {textLeft}
            {(iconPath != '' && !buttonPushed) ? (<img className='px-2 max-w-[40px]' src={iconPath} alt=""></img>) : (typeof icon !== "string" && typeof icon !== "undefined" && !buttonPushed) ? (icon) : (<></>)}
            {/* <ArrowRightIcon iconColor='' /> */}
            {(buttonPushed) ? (<div className='flex max-w-[40px]'><LoadingSpinner /></div>) : (<></>)}
            {textRight}
          </div>
        </button>
    </Link>
  )
}

export default MainButton