import React from 'react'

interface props {
    text: string,
    hoverGroup: string,
    width: string,
    height: string,
    xTranslate: string 
}
const Tooltip = ({text, hoverGroup, width, height, xTranslate} : props) => {
  return (
    <div className={`${width} ${height} group-hover/${hoverGroup}:block absolute ${xTranslate} text-light text-sm shadow-lg hidden p-2 items-center bg-gray-50 rounded-lg border`}>
        <p className=''>{text}</p>
    </div>
  )
}

export default Tooltip