import React, { useEffect, useState } from 'react'
import SidebarElement from '../../components/SidebarElement'
import CakeChartIcon from '../../../../assets/img/icons/CakeChartIcon'
import { RootState } from '../../../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom'
import ArrowRightIcon from '../../../../assets/img/icons/ArrowRightIcon'
import ArrowLeftIcon from '../../../../assets/img/icons/ArrowLeftIcon'
import { useTranslation } from 'react-i18next'
import SettingsFilledIcon from '../../../../assets/img/icons/SettingsFilledIcon'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import InboxFilledIcon from '../../../../assets/img/icons/InboxFilledIcon'
import ListFilledIcon from '../../../../assets/img/icons/ListFilledIcon'
import { getSelectionPhase, getUserSelectionRole } from '../../../../data/SelectionPortalRequests'
import SearchProcessSection from '../../../../components/SearchProcessSection'
import CheckmarkIcon from '../../../../assets/img/icons/CheckmarkIcon'
import MainButton from '../../../../components/MainButton'
import { setProjectRole } from '../../../../redux/selectionSlice'

// interface props {
//     outerSidebarWidth : any,
//     outerSidebarOpen : boolean
// }

export const loader = async ({ params } : any) : Promise<String> => {
  if (params.selectionId) {
      return params.selectionId;
  } else {
      return "";
  }
}

const Index = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const role = useSelector((state: RootState) => state.user.role);
    const projectRole = useSelector((state: RootState) => state.selection.projectRole);
    const [collapsedSidebar, setCollapsedSidebar] = useState(false);
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [outerSidebarOpen, outerSidebarWidth, setOuterCollapsedSidebar] = useOutletContext<any>();
    const [initializationComplete, setInitializationComplete] = useState(false);
    const [selectionPhase, setSelectionPhase] = useState("");
    const [selectionTaskId, setSelectionTaskId] = useState("");
    const [selectedSelectionPhase, setSelectedSelectionPhase] = useState("");

    const SELECTION_PHASES : any = {
      preselection: 0,
      requirements: 1,
      scenarios: 2,
      presentations: 3,
      contracts: 4,
    }
    
    const SELECTION_PHASES_LINKS : any = {
      requirements: "requirement-manager",
      scenarios: "scenario-manager",
      presentations: "presentation-manager",
      contracts: "contract-manager",
    }

    const selectionId : string = String(useLoaderData());

    useEffect(() => {setOuterCollapsedSidebar(true); setInitializationComplete(true); handleFetchSelectionPhase(); handleFetchUserSelectionRole();}, [])

    const handleFetchSelectionPhase = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getSelectionPhase(selectionId);
      if (response.ok) {
        setSelectionPhase(response.data.data.selectionPhase);

        if (typeof response.data.data.selectionSelectionPhaseTask !== "undefined") {
          // console.log(response.data.data.selectionSelectionPhaseTask[0]);
          const selectionSelectionPhaseTask = response.data.data.selectionSelectionPhaseTask[0];
          setSelectionTaskId(selectionSelectionPhaseTask.id);
        }
      }
    }
    
    const handleFetchUserSelectionRole = async () => {
      if (typeof selectionId === "undefined") return;

      const response = await getUserSelectionRole(selectionId);
      if (response.ok) {
        dispatch(setProjectRole(response.data.selectionRole));
      }
    }

    const handleCollapseSidebar = () => {
        setCollapsedSidebar(prev => !prev)
    }
    
    const menuItems = [
        {
          label: "Dashboard",
          icon: <CakeChartIcon iconColor='currentColor' />,
          roleLink: {
            "customer": ""
          },
          topAlign: true,
        },
        {
          label: "Ranking",
          icon: <ListFilledIcon iconColor='currentColor' />,
          roleLink: {
            "customer": "vendor-ranking"
          },
          topAlign: true
        },
        {
          label: "Preselection",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 0) ? "bg-primary" : "bg-gray-200"} rounded-full w-full h-full text-white font-bold flex items-center justify-center text-white p-1`}><CheckmarkIcon iconColor='currentColor' /></div>,
          roleLink: {
            "customer": ""
          },
          topAlign: true
        },
        {
          label: "Requirements",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 1) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>1</p></div>,
          roleLink: {
            "customer": SELECTION_PHASES_LINKS["requirements"]
          },
          topAlign: true
        },
        {
          label: "Scenarios",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 2) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>2</p></div>,
          roleLink: {
            "customer": SELECTION_PHASES_LINKS["scenarios"]
          },
          topAlign: true
        },
        {
          label: "Presentations",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 3) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>3</p></div>,
          roleLink: {
            "customer": SELECTION_PHASES_LINKS["presentations"]
          },
          topAlign: true
        },
        {
          label: "Contract",
          icon: <div className={`${(SELECTION_PHASES[selectionPhase] >= 4) ? "bg-primary" : "bg-gray-200"} rounded-full w-6 h-6 text-white font-bold flex items-center justify-center`}><p>4</p></div>,
          roleLink: {
            "customer": SELECTION_PHASES_LINKS["contracts"]
          },
          topAlign: true
        },
        {
          label: t("inbox"),
          icon: <InboxFilledIcon iconColor='currentColor' />,
          roleLink: { 
            "vendor": "inbox", 
            "customer": "inbox"
          },
          topAlign: false
        },
        {
          label: "Settings",
          icon: <SettingsFilledIcon iconHeight='3rem' iconWidth='3rem' iconColor='currentColor' />,
          roleLink: {
            "customer": "settings"
          },
          topAlign: false
        },
        {
          label: t("Schließen"),
          icon: (collapsedSidebar) ? (<ArrowRightIcon iconColor='currentColor' />) : (<ArrowLeftIcon iconColor='currentColor' />),
          roleLink: {
            "platform_manager": "#", 
            "vendor": "#", 
            "customer": "#"
          },
          onClick: handleCollapseSidebar,
          topAlign: false
        }
      ]

  if (!initializationComplete) {
    return (<LoadingSpinner />)
  } else {
  return (
    <div className='h-full'>

    <div className="fixed z-[50] top-0 left-[50%] -translate-x-[50%] mx-auto h-20 flex justify-center items-center">
      <SearchProcessSection processStep={SELECTION_PHASES[selectionPhase]} steps={["Preselection", "Requirements", "Scenarios", "Presentations", "Contract"]} links={["", "requirement-manager", "scenario-manager", "presentation-manager", "contract-manager"]} style='mt-0 w-[854px] timeline max-lg:hidden' />
    </div>

    {/* "-translate-x-full" left-[${-500}px] ${mobileSidebarOpen ? "translate-x-0" : (`-translate-x-{200%}`)} style={{transform: mobileSidebarOpen ? "" : "translateX(-200%)"}} */}
    <aside id="logo-sidebar" className={`fixed top-0 ${outerSidebarOpen ? `left-${outerSidebarWidth.open}` : `left-${outerSidebarWidth.closed}`} z-40 ${(collapsedSidebar) ? ("w-16") : ("w-64")} h-screen pt-20  translate-x-[-200%] sm:translate-x-0 transition-transform bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar`}>
        <div className="h-full px-3 pb-4 pt-3 overflow-y-auto bg-white flex flex-col">
            <ul className="space-y-2">
              {
                menuItems.map((value: any, index: number) => {
                  if (value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
                })
              }
            </ul>
            <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
              {
                menuItems.map((value: any, index: number) => {
                  if (!value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
              }
              )}
            </ul>
            <div className='grow flex items-end text-gray-300 text-sm text-light gap-1'>
              <span className='font-semibold'>Role: </span><span>{projectRole == "selection_admin" ? "Project lead" : "Key user"}</span>
            </div>
        </div>
      </aside>
      
      <div className={`${(collapsedSidebar) ? ("sm:ml-16") : ("sm:ml-64")} relative h-full ${(selectedSelectionPhase != selectionPhase) && (selectedSelectionPhase != "") ? ("pt-20 border-4 border-amber-200") : ("")}`}>

        {
          (selectedSelectionPhase != selectionPhase) && (selectedSelectionPhase != "") ? (
            <div className='absolute z-[2] top-0 flex justify-between space-x-2 bg-amber-100 border-b-4 border-amber-200 h-20 w-full p-2'>
              <div>
                <p className='text-md font-semibold'>Inactive selection phase</p>
                <p className='line-clamp-2 text-sm'>You are in a completed or not yet started phase of the software selection. To complete the current activities, please return to the active selection phase.</p>
              </div>
              <div className='h-full flex items-end'>
              <MainButton text={'Resume selection'} icon={<div className='text-white'><ArrowRightIcon iconColor='currentColor'/></div>} link={SELECTION_PHASES_LINKS[selectionPhase]} />
              </div>
            </div>
          ) : (<></>)
        }
        
        <Outlet context={{selectionId, selectionPhase, setSelectionPhase, selectedSelectionPhase, setSelectedSelectionPhase, selectionTaskId, setSelectionTaskId, handleFetchSelectionPhase}} />
        {/* <Outlet context={[!collapsedSidebar, {open: "64", closed: "16"}, setCollapsedSidebar]} /> */}
      </div>
    </div>
  )
  }
}

export default Index
