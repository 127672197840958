import React, { useEffect, useRef, useState } from 'react'
import OpenIcon from '../../../../../../assets/img/icons/OpenIcon'
import { useNavigate } from 'react-router-dom';

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import type { Plugin, RenderViewer } from '@react-pdf-viewer/core';
import { deleteDocument } from '../../../../../../data/SelectionPortalRequests';

export interface PageThumbnailPluginProps {
    PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
    const { PageThumbnail } = props;

    return {
        renderViewer: (renderProps: RenderViewer) => {
            let { slot } = renderProps;

            slot.children = PageThumbnail;

            // Reset the sub slot
            if (slot.subSlot) {
                slot.subSlot.attrs = {};
                slot.subSlot.children = <></>;
            }

            return slot;
        },
    };
};

interface props {
    documentDetails: any,
    handleFetchDocuments: () => void
}

const DocumentCard = ({documentDetails, handleFetchDocuments} : props) => {
    const ref = useRef<HTMLDivElement>(null);
    const [pdfHeight, setPdfHeight] = useState<number>();
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [cover, setCover] = useState<any>();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const navigate = useNavigate();

    const dateParsed : Date = new Date(Date.parse(documentDetails.updatedDate));
    const formatedDate : string = `${dateParsed.getDate()}.${dateParsed.getMonth()+1}.${dateParsed.getFullYear()}`;
    
    const thumbnailPluginInstance = thumbnailPlugin();
    const { Cover } = thumbnailPluginInstance;

    useEffect(() => {
        setPdfHeight(ref.current?.clientHeight);

        const pageThumbnailPluginInstance = pageThumbnailPlugin({
            PageThumbnail: <Cover getPageIndex={() => 0} />,
        });
        setCover([pageThumbnailPluginInstance, thumbnailPluginInstance]);
    }, [])

    const handleDeleteDocument = async () => {
        if (confirmDelete) {
            console.log(documentDetails.id);
            await deleteDocument(documentDetails.id);
            handleFetchDocuments();
            setConfirmDelete(false);
            setSettingsOpen(false);
        } else {
            setConfirmDelete(true);
        }
    }

  return (
    <div onClick={() => navigate(`document?documentId=${documentDetails.id}`)}
    className='flex flex-col bg-gray-50 border-2 border-gray-50 hover:border-primary w-56 h-64 rounded-lg shadow'>
        <div ref={ref} className='grow flex items-center justify-center'>
            {
                typeof cover !== "undefined" ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer fileUrl={documentDetails.location} plugins={[cover[0], cover[1]]}/>
                    </Worker>
                ) : (<></>)
            }
        </div>
        <div className='flex items-center justify-between border-t p-2 h-16'>
            <div className='w-[60%]'>
                <p className='font-semibold truncate'>{documentDetails.name}</p>
                <p className='font-light'>{formatedDate}</p>
            </div>
            <div className='flex items-center space-x-2'>
                <div onClick={(e) => {e.stopPropagation(); setSettingsOpen(prev => !prev); setConfirmDelete(false);}}
                className='relative cursor-pointer p-4 hover:bg-gray-200 rounded-full w-6 h-6 text-center flex items-center justify-center'>
                    ...
                    {
                        settingsOpen ? (
                            <div className='absolute left-6 w-32 z-[12] bg-gray-50 border rounded-lg'>
                                <div onClick={(e) => {e.stopPropagation(); console.log("Not implemented")}} className='border-b py-1 hover:bg-gray-100 cursor-pointer'>Versions</div>
                                <div onClick={(e) => {e.stopPropagation(); handleDeleteDocument(); }} className={`border-b py-1 hover:bg-red-200 cursor-pointer ${confirmDelete ? "bg-red-200" : ""}`}>{confirmDelete ? "Confirm" : "Delete"}</div>
                            </div>
                        ) : (<></>)
                    }
                </div>
                <div
                className='cursor-pointer p-1 hover:bg-gray-200 rounded-full'>
                    <OpenIcon />
                </div>
            </div>
        </div>
        {
            settingsOpen ? (
                <div onClick={(e) => {e.stopPropagation(); setSettingsOpen(false); setConfirmDelete(false);}}
                className='absolute top-0 left-0 z-[10] w-screen h-screen'>
                </div>
            ) : (<></>)
        }
    </div>
  )
}

export default DocumentCard