import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarElement from './components/SidebarElement';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getCustomerSearches } from '../../data/CustomerSearchRequests';
import useSPNavItems from './hooks/useSPNavItems';

const Index = () => {
  const role = useSelector((state: RootState) => state.user.role);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  
  const handleCollapseSidebar = () => {
    if (mobileSidebarOpen) { setMobileSidebarOpen(false); return; }
    setCollapsedSidebar(prev => !prev)
  }

  const menu_items = useSPNavItems(collapsedSidebar, handleCollapseSidebar);
  return (
    <div className='min-h-screen bg-gray-50'>

      <aside id="logo-sidebar" className={`${mobileSidebarOpen ? "translate-x-0" : "-translate-x-full"} fixed top-0 left-0 z-40 ${(collapsedSidebar) ? ("w-16") : ("w-64")} h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidebar`}>
        <div className="h-full px-3 pb-4 pt-3 overflow-y-auto bg-white">
            <ul className="space-y-2">
              {
                menu_items.map((value: any, index: number) => {
                  if (value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
                })
              }
            </ul>
            <ul className="pt-4 mt-4 space-y-2 font-medium border-t border-gray-200">
              {
                menu_items.map((value: any, index: number) => {
                  if (!value.topAlign && Object.keys(value.roleLink).includes(role)) {
                    return (<SidebarElement label={value.label} link={value.roleLink[role]} icon={value.icon} labelHidden={collapsedSidebar} onClick={value.onClick} />)
                  }
              }
              )}
            </ul>
        </div>
      </aside>

      <div className={`${(collapsedSidebar) ? ("sm:ml-16") : ("sm:ml-64")} h-screen pt-20`}>
        <Outlet context={[!collapsedSidebar, {open: "64", closed: "16"}, setCollapsedSidebar]} />
      </div>

      
    </div>
  )
}

export default Index