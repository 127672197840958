import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowDownIcon from '../../../assets/img/icons/ArrowDownIcon';

interface props {
    label: string,
    link: string | any[],
    icon: JSX.Element,
    labelHidden: boolean,
    onClick: boolean
}

const SidebarElement = ({label, link, icon, labelHidden, onClick} : props) => {
  const [dropdownHidden, setDropdownHidden] = useState(true);
  const handleClick = () => {
    if (typeof link !== "string") {
      setDropdownHidden(prev => !prev)
    }
  }

  return (
    <li onClick={(typeof onClick === "function") ? (onClick) : (undefined)}>
        <Link to={(typeof link === "string") ? (link) : ("#")} onClick={handleClick} className="flex items-center p-2 text-gray-900 transition duration-75 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
          <div className='w-5 h-5 text-gray-500 transition duration-75 flex justify-center items-center dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white'>
              {icon}
          </div>
          <span className={`ms-3 flex-1 ${(labelHidden) ? ("invisible text-clip") : ("")}`}>{(labelHidden) ? ("A") : (label)}</span>
          {(typeof link !== "string") ? (<ArrowDownIcon />) : (<></>)}
        </Link>
        {
          (typeof link !== "string") ? (
          <ul id="dropdown-example" className={`${(dropdownHidden) ? ("hidden") : ("")} py-2 space-y-2`}>
            {
              link.map((value: any, index: number) => (
              <li>
                <Link to={value.link} className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                  {value.label}
                </Link>
              </li>
              ))
            }
          </ul>
          ) : (<></>)
        }
    </li>
  )
}

export default SidebarElement