import React, { useState } from 'react'
import TableRow from '../../../../../../../components/TableRow'
import WarningIcon from '../../../../../../../assets/img/icons/WarningIcon'
import FilledInfoIcon from '../../../../../../../assets/img/icons/FilledInfoIcon'
import CheckmarkIcon from '../../../../../../../assets/img/icons/CheckmarkIcon'
import CrossIcon from '../../../../../../../assets/img/icons/CrossIcon'
import QuickMenu from '../../../../../../../components/QuickMenu'
import { deleteSelectionScenarioTask, updateSelectionScenarioTaskOrder } from '../../../../../../../data/SelectionPortalRequests'
import TrashIcon from '../../../../../../../assets/img/icons/TrashIcon'

interface props {
    scenarioTask: any,
    openMenuForId: string | undefined,
    setOpenMenuForId: React.Dispatch<React.SetStateAction<string | undefined>>,
    handleSetScenarioStatus : (id: string, status: string) => void,
    isLastItem: boolean,
    handleFetchScenarios: () => void,
    setModifyScenarioTaskId: React.Dispatch<React.SetStateAction<string | undefined>>,
}

const ScenarioItemTableRow = ({scenarioTask, openMenuForId, setOpenMenuForId, handleSetScenarioStatus, isLastItem, handleFetchScenarios, setModifyScenarioTaskId} : props) => {
    const [dragOverActive, setDragOverActive] = useState(false);
    const [dragOverActiveLast, setDragOverActiveLast] = useState(false);

    // A: This scenario cards gets rearranged
    const dragStart = (e : any, id : string) => {
        console.log(id);
        e.dataTransfer.setData("id", id);
    }

    // B: Some other scenario card gets rearranged and dragged/dropped over this scenario card
    const enterDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(true); return; }
        setDragOverActive(true);
    }
    
    const leaveDrop = (e : any, last?: boolean) => {
        e.preventDefault();
        if (last) { setDragOverActiveLast(false); return; }
        setDragOverActive(false);
    }
    
    const drop = async (e : any, last?: boolean) => {
        e.preventDefault();

        const id = e.dataTransfer.getData("id");

        console.log("Dropped")

        if (id != scenarioTask.id) {

            let newCleanOrderNumber : number;
            if (last) {
                newCleanOrderNumber = scenarioTask.cleanOrderNumber+1;
            } else {
                newCleanOrderNumber = scenarioTask.cleanOrderNumber;
            }

            if ((await updateSelectionScenarioTaskOrder(id, newCleanOrderNumber)).ok) {
                handleFetchScenarios();
            }
        }

        if (last) { setDragOverActiveLast(false); return; }
        setDragOverActive(false);
        
    }

    const handleDeleteScenarioItem = async (scenarioItemId: string) => {
        console.log(scenarioItemId)
        if (await deleteSelectionScenarioTask(scenarioItemId)) {
            handleFetchScenarios();
        }
    }

    return (
    <>
    
        <tbody 
        draggable={true}

        // A: Gets dragged
        onDragStart={(e) => dragStart(e, scenarioTask.id)}
        
        // B: Is dropzone
        onDragOver={(e) => enterDrop(e)} 
        onDragLeave={(e) => leaveDrop(e)} 
        onDrop={(e) => drop(e)}
        onClick={() => setModifyScenarioTaskId(scenarioTask.id)}
        className={`cursor-pointer ${scenarioTask.scenarioStatus == "archived" ? "opacity-70 hover:opacity-100 transitions-all duration-100" : ""}`}>
            <TableRow
                additionalStyles={`${dragOverActive ? "border-t-8 z-[100]": ""} transition-all duration-500`}
                rowItems={["", scenarioTask.cleanOrderNumber, scenarioTask.title, 
                
                <div className='group w-full h-6 text-amber-400 flex items-center justify-center'>
                    {
                        scenarioTask.description != "" ? (
                            <>
                                <div className='text-gray-300 hover:text-primary flex items-center justify-center'><FilledInfoIcon iconColor='currentColor' /></div>
                                <div className='font-normal text-gray-900 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                                    {scenarioTask.description}
                                </div>
                            </>
                        ) : (<></>)
                    }
                </div>, 

                <div>
                {
                    (scenarioTask.scenarioStatus == "warning") ? (
                        <div className='group w-full h-6 text-amber-400 flex items-center justify-center'>
                            <WarningIcon iconColor='currentColor' />
                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                                A warning has been recognised or added manually. A manual review is necessary.
                            </div>
                        </div>
                    ) : (scenarioTask.scenarioStatus == null) ? (
                        <div className='group w-full h-6 text-green-400 font-bold flex items-center justify-center'>
                            (<CheckmarkIcon iconColor='currentColor' />)
                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                                Scenario was created manually but not confirmed yet. Manual validation might be necessary.
                            </div>
                        </div>
                    ) : (scenarioTask.scenarioStatus == "completed") ? (
                        <div className='group w-full h-6 text-green-400 flex items-center justify-center'>
                            <CheckmarkIcon iconColor='currentColor' />
                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                                Scenario was checked by user and is completed.
                            </div>
                        </div>
                    ) : (scenarioTask.scenarioStatus == "archived") ? (
                        <div className='group w-full h-6 text-red-400 flex items-center justify-center'>
                            <div className='h-8 w-8'><CrossIcon iconColor='currentColor' /></div>
                            <div className='font-normal text-gray-900 w-44 whitespace-normal bg-gray-50 border rounded-lg p-2 absolute -translate-x-20 group-hover:block hidden'>
                                Scenario has been archived by the user and will not be considered further.
                            </div>
                        </div>
                    ) : (<></>)
                } </div>,
                
                <QuickMenu id={scenarioTask.id} openMenuForId={openMenuForId} setOpenMenuForId={setOpenMenuForId} menuItems={[
                    {label: "Completed", value: "completed", icon: <div className='text-green-400'><CheckmarkIcon iconColor='currentColor' /></div>, function: handleSetScenarioStatus },
                    {label: "Warning", value: "warning", icon: <div className='text-amber-400'><WarningIcon iconColor='currentColor' /></div>, function: handleSetScenarioStatus },
                    {label: "Archived", value: "archived", icon: <div className='text-red-400'><CrossIcon iconColor='currentColor' /></div>, function: handleSetScenarioStatus },
                    {label: "Delete", value: "delete", icon: <div className='text-red-400'><TrashIcon /></div>, function: handleDeleteScenarioItem }
                ]} />
            ]} 
            maxRowWidths={["","","w-full","",""]}
            />
        </tbody>
        {
            isLastItem ? 
                (
                    <tbody>
                        <td
                        onDragOver={(e) => enterDrop(e, true)} 
                        onDragLeave={(e) => leaveDrop(e, true)} 
                        onDrop={(e) => drop(e, true)}
                        colSpan={5} 
                        className={`w-full h-3 ${dragOverActiveLast ? "border-t-8 z-[100]": ""} transition-all duration-500`}>
                        </td>
                    </tbody>
                ): <></>
        }
    </>
  )
}

export default ScenarioItemTableRow