import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';

const AnalyticsInjector = (props) => {
    const location = useLocation();

    // useEffect(() => {
    //     if (!window._paq) {
    //         window._paq = [];
    //         window._paq.push(['setTrackerUrl', `${process.env.REACT_APP_MATOMO_URL}/matomo.php`]);
    //         window._paq.push(['setSiteId', process.env.REACT_APP_MATOMO_SITE_ID]);
      
    //         // Füge das Matomo-Skript zur Seite hinzu
    //         const script = document.createElement('script');
    //         script.async = true;
    //         script.src = `${process.env.REACT_APP_MATOMO_URL}/matomo.js`;
    //         document.head.appendChild(script);
    //     }
    
    //     // Tracke die aktuelle Seite bei jeder Routenänderung
    //     window._paq.push(['setCustomUrl', window.location.href]);
    //     window._paq.push(['trackPageView']);
    // }, [location])
    
    return (props.children)
}

export default AnalyticsInjector