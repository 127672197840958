import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import SoftwareFulfillmentTable from '../tables/SoftwareFulfillmentTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

import { fetchProductData, addProductData, getImage, uploadImageToBackend } from '../../../../../../data/ProductRequests';
import { fetchVendorData } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import MainButton from '../../../../../../components/MainButton';
import UploadIcon from '../../../../../../assets/img/icons/UploadIcon';
import OpenIcon from '../../../../../../assets/img/icons/OpenIcon';

interface props {
    loadProductId : string,
    successElement: JSX.Element,
    onboarding : boolean
}

const AddSoftwareProductForm = ({loadProductId, successElement, onboarding} : props) => {
    const { t } = useTranslation();
    const userRole = useSelector((state: RootState) => state.user.role);

    const [productId, setProductId] = useState("")
    const [productName, setProductName] = useState("")
    const [productUrl, setProductUrl] = useState("")
    const [productDescription, setProductDescription] = useState("")
    const [productFulfillment, setProductFulfillment] = useState([])
    
    const [vendorData, setVendorData] = useState([]);
    const [vendorDetailsId, setVendorDetailsId] = useState("")
    const [vendorCompanyName, setVendorCompanyName] = useState("")
    
    const [weightManufacturing, setWeightManufacturing] = useState(0)
    const [weightTrade, setWeightTrade] = useState(0)
    const [weightService, setWeightService] = useState(0)

    const [imageSource, setImageSource] = useState("");

    const [features, setFeatures] = useState(["Integrierte Anwendungssuite", "Zentrale Datenbank", "Automatisierung von Geschäftsprozessen", "Reporting und Analysen", "Supply Chain Management", "Customer Relationship Management (CRM)", "Skalierbarkeit und Anpassungsfähigkeit"]);
    const [moreFeature, setMoreFeature] = useState("");

    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);

    useEffect(() => {
        if (loadProductId != "") { 
            handleFetchImage();
            handleFetchProductData();
            if ( vendorDetailsId == "" && userRole == "platform_manager") { fetchVendorData().then(data => setVendorData(data.data)) }
        } else { if (userRole == "platform_manager") { fetchVendorData().then(data => setVendorData(data.data)) } }
    }, [loadProductId])

    const handleFetchProductData = async () => {
        fetchProductData(loadProductId).then(data => {
            console.log(data)

            setProductId(data.productData.id);
            setProductName(data.productData.productName);
            setProductUrl(data.productData.productUrl);
            setProductDescription(data.productData.productDescription);
            setProductFulfillment(data.productData.softwareFulfillment);
            
            setWeightManufacturing(data.productData.weightManufacturing);
            setWeightTrade(data.productData.weightTrade);
            setWeightService(data.productData.weightService);
            
            if (data.productData.vendorDetails) { 
                setVendorDetailsId(data.productData.vendorDetails.id);
                setVendorCompanyName(data.productData.vendorDetails.company); 
            } else {
                setVendorDetailsId("");
                setVendorCompanyName("Nicht zugewiesen"); 
            }
        });
    }
    
    const handleFetchImage = async () => {
        console.log("AAAA")
        getImage(loadProductId, "/api/product/get-logo?id=", 'https://via.placeholder.com/400x200').then(frontendImageLocation => {
            setImageSource(frontendImageLocation);
        });
    }
    
    const handleAddProductData = async () => {
        if (await addProductData(loadProductId, productName, productUrl, productDescription, vendorDetailsId, weightManufacturing, weightService, weightTrade)) {
            setCreatedSuccessfully(true);
        } else {
            // TODO: Highlighting der Felder wenn fehlgeschlagen
        }
    }

    const handleUploadImage = async (image : File) => {
        if (await uploadImageToBackend(image, loadProductId+"."+image.name.split(".").at(-1), "/api/product/add-logo")) {
            getImage(loadProductId, "/api/product/get-logo?id=", 'https://via.placeholder.com/400x200').then(frontendImageLocation => {
                setImageSource(frontendImageLocation);
            });
        }
    }

    useEffect(() => {
        const vendorDataForId : any = vendorData.filter((obj : any) => (obj.id == vendorDetailsId))
        if (vendorDataForId && vendorDataForId.length > 0) {
            setVendorCompanyName(vendorDataForId[0].company);
        }
    }, [vendorDetailsId])

    console.log(weightManufacturing+"/"+weightTrade+"/"+weightService)

    const handleSetWeight = (value : any, setter : any) => {
        if (value < 0) {return;}
        try {
            setter(Number(value));
        } catch (e) {
            console.error("Unacceptable value in weights.")
        }
    }

    return (
    <div>
        {(onboarding) ? (<></>) : (
            <h3 className="mb-4 text-xl font-medium text-gray-900 ">{(loadProductId == "") ? (`${t("createProduct")}`) : (`${t("updateProduct")}`)}</h3>
        )}
        {(createdSuccessfully) ? (successElement) : (
            <div className={`${(onboarding) ? ("") : ("max-h-[75vh] overflow-y-auto")} p-2 space-y-6`}>
                <div className='flex flex-col sm:flex-row gap-2'>
                    <div className="items-center sm:flex xl:block 2xl:flex sm:space-x-4 xl:space-x-0 2xl:space-x-4">
                        <div className="flex items-center justify-center bg-gray-100 rounded-lg w-64 h-28 sm:mb-0 xl:mb-4 2xl:mb-0">
                            <img 
                                className="w-11/12 h-auto max-h-full p-4 object-contain"
                                src={imageSource}
                                alt=""
                            />
                        </div>
                        <div>
                            <h3 className="mb-1 text-xl font-bold text-gray-900 ">
                                Logo
                            </h3>
                            <div className="mb-4 text-sm text-gray-500 dark:text-gray-400">
                                {t("logoFileType")}
                            </div>
                            <div className="flex items-center space-x-4">
                                <label htmlFor='input-upload' className="cursor-pointer inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                    <UploadIcon iconColor='white' />
                                    {t("upload")}
                                    <input onChange={(event) => {if (event.target.files) { handleUploadImage(event.target.files[0])}}} id='input-upload' type="file" name="file" className="hidden"></input>
                                </label>

                                <button type="button" className="py-2 px-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" >
                                    {t("delete")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("Id")}</label>
                    <input placeholder={`${t("id")}`} value={productId} type="text" name="input" className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " disabled={true}></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("productName")}</label>
                    <input placeholder={`${t("productName")}`} value={productName} onChange={(event) => {setProductName(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("productUrl")}</label>
                    <div className='flex gap-2'>
                        <input placeholder={`${t("productUrl")}`} value={productUrl} onChange={(event) => {setProductUrl(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                        <a href={productUrl} target='_blank' className='flex items-center justify-center text-primary'><OpenIcon /></a>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("productDescription")}</label>
                    {/* <input placeholder="Product Description" value={productDescription} onChange={(event) => {setProductDescription(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input> */}
                    <textarea name="" id="" placeholder={`${t("productDescription")}`}  value={productDescription} onChange={(event) => {setProductDescription(event.target.value)}} rows={5} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required></textarea>
                </div>
                <div className='flex space-x-5'>
                    <div className='w-full'>
                        <label className="block text-sm font-medium text-gray-900 ">{t("weightManufacturing")}</label>
                        <input placeholder={`${t("weightManufacturing")}`} value={weightManufacturing} onChange={(event) => {handleSetWeight(event.target.value, setWeightManufacturing)}} type="number" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                    </div>
                    <div className='w-full'>
                        <label className="block text-sm font-medium text-gray-900 ">{t("weightTrade")}</label>
                        <input placeholder={`${t("weightTrade")}`} value={weightTrade} onChange={(event) => {handleSetWeight(event.target.value, setWeightTrade)}} type="number" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                    </div>
                    <div className='w-full'>
                        <label className="block text-sm font-medium text-gray-900 ">{t("weightService")}</label>
                        <input placeholder={`${t("weightService")}`} value={weightService} onChange={(event) => {handleSetWeight(event.target.value, setWeightService)}} type="number" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " ></input>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("mainFeatures")}</label>
                    {/* <ul className="grid w-full gap-2 md:grid-cols-3"> */}
                    <ul>
                        {
                            features.map((value: string, index: number) => (
                                <li key={`option-${index}`} className="float-left m-1">
                                    <input type="checkbox" id={`option-${index}`} value="" className="hidden peer" />
                                    <label htmlFor={`option-${index}`} className="inline-flex items-center justify-between w-full p-2 text-gray-500 border rounded-lg bg-gray-50 border-gray-300 text-gray-600 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-gray-700 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div className="flex flex-col sm:flex-row gap-2">
                                            <div className="w-5 h-5"><PlusIcon iconColor='gray' /></div>
                                            <div className="w-full text-sm">{value}</div>
                                        </div>
                                    </label>
                                </li>
                            ))
                        }
                        <li className="float-left m-1">
                            <input 
                            onKeyDown={(e) => {if (e.key === 'Enter' && moreFeature != "") {setFeatures(prev => [...prev, moreFeature]); setMoreFeature("")}}}
                            onChange={(e) => setMoreFeature(e.target.value)}
                            value={moreFeature}
                            type="text" id="add" placeholder="Add more" className="inline-flex items-center justify-between w-full p-1.5 text-gray-500 border rounded-lg bg-gray-50 border-gray-300 text-gray-600 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-gray-700 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700" />
                        </li>
                    </ul>
                    <div className='float-none clear-both'></div>
                </div>
                {
                    (userRole == "platform_manager") ? (
                        <div>
                            <label htmlFor="countries" className="block text-sm font-medium text-gray-900 ">{t("vendorCompany")}</label>
                            <select onChange={(event) => setVendorDetailsId(event.target.value)}
                            id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{WebkitAppearance: "none"}}>
                                {loadProductId == "" ? <option value="" selected disabled hidden>Choose here</option> : <option value={vendorDetailsId} selected>{vendorCompanyName}</option>}
                                {
                                    vendorData.map((item : any, index : number) => {
                                        return (<option key={index} value={item.id}>{item.company}</option>);
                                    })
                                }
                            </select>
                        </div>
                    ) : (<></>)
                }
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("salesContact")}</label>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <input placeholder={`${t("firstname")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                        <input placeholder={`${t("lastname")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                    </div>
                    <div className="pt-2 flex flex-col sm:flex-row gap-2">
                        <input placeholder="E-Mail" type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                        <input placeholder={`${t("phoneNumber")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 ">{t("productManagementContact")}</label>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <input placeholder={`${t("firstname")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                        <input placeholder={`${t("lastname")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                    </div>
                    <div className="pt-2 flex flex-col sm:flex-row gap-2">
                        <input placeholder="E-Mail" type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                        <input placeholder={`${t("phoneNumber")}`} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "  required></input>
                    </div>
                </div>

                {
                    (!onboarding) ? (
                        <>
                            {
                                (loadProductId != "") ? (
                                    <SoftwareFulfillmentTable data={productFulfillment} productId={loadProductId} reloadProduct={() => handleFetchProductData()} />
                                ) : (<div className="hidden"></div>)
                            }
                            {
                                (loadProductId != "") ? (
                                    <div className='w-full py-2'><MainButton text={t("goToProductPage")} link={''} style='light' func={() => window.open(`/product/?productId=${loadProductId}`,'_blank', 'rel=noopener noreferrer')} /></div>
                                ) : (<></>)
                            }
                        </>
                    ) : (
                        <></>
                    )
                }

                <div className='w-full'><MainButton text={t("save")} link="" func={handleAddProductData} /></div>
            </div>
        )}
    </div>
  )
}

export default AddSoftwareProductForm;