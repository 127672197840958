import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkIcon from '../assets/img/icons/CheckmarkIcon';

interface props {
    title: string,
    price : number,
    payments: string,
    checks : string[],
}

const ProductCard = ({title, price, payments, checks} : props) => {
    const { t } = useTranslation();
  return (
    <div className="p-6 rounded-lg h-full w-full">
        <h2 className="text-lg font-medium text-gray-900 mb-4">{title}</h2>
        <div className="text-5xl font-bold text-gray-900 mb-6">{t("dollarValue", {money: new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price/100)})}</div>
        <div className="text-gray-500 mb-6">{payments}</div>
        <ul className="mb-6">
            {
                checks.map((item: string, index: number) => {
                    return (
                        <li className="flex items-center">
                            <div className='text-primary mr-3'><CheckmarkIcon iconColor='currentColor' /></div>
                            <span className='w-[80%]'>{item}</span>
                        </li>

                    );
                })
            }
        </ul>
    </div>
  )
}

export default ProductCard