export async function fetchSoftwareProductData (userRole: string) {
    // setSoftwareProductData([]);
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/${(userRole == "platform_manager") ? ("get-all-products") : ("get-vendor-products")}`, options);
        
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchSoftwareProductData: Bad response ${res.status}`);
            return [];
        }
    } catch(error) {
        console.error(`fetchSoftwareProductData: Error occurred ${error}`);
        return [];
    }
}

export async function fetchProductData (productId: string) {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    // fetch(`${process.env.REACT_APP_BACKEND}/api/product/${(userRole == "platform_manager") ? ("get-product-manager") : ("get-product-vendor")}?id=${load}`, options).then((res) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/get-product?id=${productId}`, options);
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchProductData: Bad response ${res.status}`);
            console.log(res.status);
        }
    } catch(error) {
        console.error(`fetchProductData: Error occurred ${error}`);
    }
}

export async function fetchProductScore (customerSearchSoftwareProductId: string) {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    // fetch(`${process.env.REACT_APP_BACKEND}/api/product/${(userRole == "platform_manager") ? ("get-product-manager") : ("get-product-vendor")}?id=${load}`, options).then((res) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/get-product-score?csspId=${customerSearchSoftwareProductId}`, options);
        if (res.ok) {
            const data = await res.json();
            console.log(data);
            return data.data;
        } else {
            console.error(`fetchProductScore: Bad response ${res.status}`);
            console.log(res.status);
        }
    } catch(error) {
        console.error(`fetchProductScore: Error occurred ${error}`);
    }
}

export async function addProductData(productId: string, productName: string, productUrl: string, productDescription: string, vendorDetailsId: string, weightManufacturing : number, weightService : number, weightTrade : number) {
    // TODO: Rückgabe, welche Felder fehlgeschlagen sind und highlighting in form
    if (!productName || !productUrl) {return false;}

    const payload = {
        productId: productId,
        productName: productName,
        productDescription: productDescription, 
        productUrl: productUrl,
        vendorDetails: vendorDetailsId,
        weightManufacturing: weightManufacturing,
        weightTrade: weightTrade,
        weightService: weightService
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/add-product`, options)
        if (res.ok) {
            console.log("Created product successfully");
            return true;
        } else {
            console.error(`addProductData: Bad response ${res.status}`);
            return false;
        }
    } catch (error) {
        console.error(`addProductData: Error occurred ${error}`);
        return false;
    }
}

export async function getImage(productId : string, path : string, placeholder: string) {
    try {
        const options : RequestInit = {
            method: "GET",
            headers: {
                'Content-Type': 'image/jpeg'
            },
            credentials: "include",
        }

        const res = await fetch(`${process.env.REACT_APP_BACKEND}${path}${productId}`, options)
        if (res.status == 200) {
            const blob = await res.blob();
            return URL.createObjectURL(blob);
        } else if (res.status == 204) {
            console.error(`getImage: Bad response ${res.status}`);
            return placeholder;
        } else {
            console.error(`getImage: Bad response ${res.status}`);
            return placeholder;
        }
    } catch (error) {
        console.error(`getImage: Error occurred ${error}`);
        return placeholder;
    }
}

export async function uploadImageToBackend(image : Blob, productId : string, path : string) {
    const formData = new FormData();
    formData.append("vendorLogo", image, productId);

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}${path}`, options)
        if (res.ok) {
            console.log("Upload Successful");
            return true;
        } else {
            console.error(`uploadImageToBackend: Bad response ${res.status}`);
            return false;
        }
    } catch (error) {
        console.error(`uploadImageToBackend: Error occurred ${error}`);
        return false;
    }

}

export async function uploadSoftwareProductFunctionDump(functionDump : any[]) {
    const formData = new FormData();
    formData.append("softwareProductFunctionDump", JSON.stringify(functionDump));

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData
    }
    
    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/product/upload-software-product-function-dump`;
        const res = await fetch(route, options)
        
        if (res.ok) {
            console.log("Functions successfully uploaded");
            return true;
        } else {
            console.error(`uploadSoftwareProductFunctionDump: Bad response ${res.status}`);
            return false;
        }
    
    } catch (error) {
        console.error(`uploadSoftwareProductFunctionDump: Error occurred ${error}`);
        return false;
    }
}