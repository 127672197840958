import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { useNavigate } from 'react-router-dom';

import { addCopySoftwareFulfillment, addCrawlSoftwareFulfillment, addEmptySoftwareFulfillment, deleteSoftwareFulfillment } from '../../../../../../data/SoftwareFulfillmentRequests';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import DeleteModal from '../../../../../../components/DeleteModal';
import FormModal from '../../../../../../components/FormModal';
import MainButton from '../../../../../../components/MainButton';

interface props {
    data : any[];
    productId: string,
    reloadProduct : () => void
}

const SoftwareFulfillmentTable = ({data, productId, reloadProduct} : props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userRole = useSelector((state: RootState) => state.user.role);

    const [addSoftwareFulfillmentOptions, setAddSoftwareFulfillmentOptions] = useState(false);
    const [addSoftwareFulfillmentOptionSelected, setAddSoftwareFulfillmentOptionSelected] = useState(0);
    const [addSoftwareFulfillment_note, setAddSoftwareFulfillment_note] = useState("");
    const [addSoftwareFulfillment_idOfVersionToCopy, setAddSoftwareFulfillment_idOfVersionToCopy] = useState<string>();

    const handleAddSoftwareFulfillment = async () => {
        setAddSoftwareFulfillmentOptions(true);
    }

    // Create new software fulfillment

    const handleAddEmptySoftwareFulfillment = async () => {
        // TODO Fehlerabfang falls nicht erstellt wurde
        await addEmptySoftwareFulfillment(productId, addSoftwareFulfillment_note); 
        reloadProduct();
        setAddSoftwareFulfillmentOptions(false);
        setAddSoftwareFulfillment_note("");
    }
    
    const handleCrawlSoftwareFulfillment = async () => {
        // TODO Fehlerabfang falls nicht erstellt wurde
        await addCrawlSoftwareFulfillment(productId, addSoftwareFulfillment_note);
        reloadProduct();
        setAddSoftwareFulfillmentOptions(false);
        setAddSoftwareFulfillment_note("");
    }
    
    const handleCopySoftwareFulfillment = async () => {
        // TODO Fehlerabfang falls nicht erstellt wurde

        console.log(addSoftwareFulfillment_idOfVersionToCopy)
        console.log(addSoftwareFulfillment_note)

        if (typeof addSoftwareFulfillment_idOfVersionToCopy !== "undefined") {
            await addCopySoftwareFulfillment(productId, addSoftwareFulfillment_note, addSoftwareFulfillment_idOfVersionToCopy);
            reloadProduct();
            setAddSoftwareFulfillmentOptions(false);
            setAddSoftwareFulfillment_note("");
            setAddSoftwareFulfillment_idOfVersionToCopy(undefined);
        }
    }

    // Delete software fulfillment

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalValidation, setDeleteModalValidation] = useState("");
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteSoftwareFulfillment = async (id: string) => {
        await deleteSoftwareFulfillment(id);
        reloadProduct();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }

    return (
        <>
            {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteSoftwareFulfillment(deleteItem.id)} content={
                <div>
                    <p className='mb-3'>Soll der Funktionsumfang Version {deleteItem.version} wirklich gelöscht werden?</p>
                </div>
            } /> : <></>}
            <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
                <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    {t("softwareFulfillment")}
                    <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("totalItems", {length: data.length, itemType: `${t("softwareFulfillment")}`})}</p>
                </div>

                <div
                    className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                >
                <div className="w-full mb-1">
                    <div className="items-center justify-between block sm:flex">
                    {/* <div className="flex items-center mb-4 sm:mb-0">
                        <form className="sm:pr-3" action="#" method="GET">
                        <label htmlFor="products-search" className="sr-only">Search</label>
                        <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
                            <input
                            type="text"
                            name="email"
                            id="products-search"
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            placeholder={`${t("searchForPlaceholder", {itemType: `${t("softwareFulfillment")}s`})}`}
                            />
                        </div>
                        </form>
                    </div> */}
                    
                    <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                        <button
                        onClick={() => reloadProduct()}
                        type="button"
                        data-refresh
                        className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                            <RefreshIcon iconColor='white'/>
                            {t("refresh")}
                        </button>

                        {
                            
                            (userRole != "platform_manager") ? (<></>) : (
                            <button
                                onClick={handleAddSoftwareFulfillment}
                                id="createProductButton"
                                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                type="button"
                            >
                                <PlusIcon iconColor='white' />
                                {t("add")}
                            </button>
                            )
                        }
                    </div>
                    {(addSoftwareFulfillmentOptions) ? (<FormModal isOpen={addSoftwareFulfillmentOptions} setOpen={setAddSoftwareFulfillmentOptions} size="regular">
                        <div>
                            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Neuen Softwarekatalog hinzufügen</h3>
                            <div className="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                                <ul className="flex flex-wrap -mb-px">
                                    <li onClick={() => setAddSoftwareFulfillmentOptionSelected(0)}
                                    className="cursor-pointer mr-2">
                                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(addSoftwareFulfillmentOptionSelected == 0) ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Leer</p>
                                    </li>
                                    <li onClick={() => setAddSoftwareFulfillmentOptionSelected(1)}
                                    className="cursor-pointer mr-2">
                                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(addSoftwareFulfillmentOptionSelected == 1) ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Kopiert</p>
                                    </li>
                                    <li onClick={() => setAddSoftwareFulfillmentOptionSelected(2)}
                                    className="cursor-pointer mr-2">
                                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(addSoftwareFulfillmentOptionSelected == 2) ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Vorbefüllt</p>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className='mb-5'>
                                    <label className="block text-sm font-medium text-gray-900 dark:text-white">{t("description")} (öffentlich sichtbar)</label>
                                    <input onChange={(event) => {setAddSoftwareFulfillment_note(event.target.value)}} placeholder={`${t("description")} (öffentlich sichtbar)`} value={addSoftwareFulfillment_note} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"  required></input>
                                </div>

                                {
                                    (addSoftwareFulfillmentOptionSelected == 1) ? (
                                        // Copied
                                        <>
                                            <div className='mb-5'>
                                                <label htmlFor="countries" className="block text-sm font-medium text-gray-900 dark:text-white">Version zu kopieren</label>
                                                <select 
                                                onChange={(event) => setAddSoftwareFulfillment_idOfVersionToCopy(event.target.value)}
                                                id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{WebkitAppearance: "none"}}>
                                                    <option value={undefined} selected disabled hidden>Wählen</option>
                                                    {
                                                        data.sort((a,b) => b.version - a.version).map((item : any, index : number) => {
                                                            return (<option key={index} value={item.id}>Version {item.version} {(item.note) ? (`(${item.note})`) : ("")}</option>);
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <MainButton text='Hinzufügen' link='' func={() => handleCopySoftwareFulfillment()} />
                                        </>
                                    ) : (addSoftwareFulfillmentOptionSelected == 2) ? (
                                        // Prefilled
                                        <MainButton text='Hinzufügen' link='' func={() => handleCrawlSoftwareFulfillment()} />
                                        ) : (
                                        // Empty
                                        <MainButton text='Hinzufügen' link='' func={() => handleAddEmptySoftwareFulfillment()} />
                                    )
                                }
                            </div>
                        </div>
                    </FormModal>) : (<></>)}
                    </div>
                </div>
                </div>

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        { 
                            (userRole != "platform_manager") ? (
                                <TableHeader header={[`${t("version")}`, `${t("note")}`, `${t("createdOn")}`]} />
                                ) : (
                                <TableHeader header={[`${t("version")}`, `${t("note")}`, `${t("createdOn")}`, ""]} />
                            )
                        }
                    </thead>
                    {
                        data.sort((a,b) => b.version - a.version).map(({id, version, note, createdDate} : any, index : number) => {
                            const date : Date = new Date(Date.parse(createdDate));
                            const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                            return (
                                <tbody className="cursor-pointer" onClick={() => navigate(`/product/software-fulfillment-survey/${id}`)}>
                                    { 
                                        (userRole != "platform_manager") ? (
                                            <TableRow rowItems={[index, version, note, dateString]} />
                                        ) : (
                                            <TableRow 
                                                rowItems={[index, version, note, dateString]} 
                                                deleteHandler={() => handleOpenDeleteModal({id, version})}
                                            />
                                        )
                                    }
                                </tbody>
                            )
                        })
                    }
                </table>
            </div>
    </>
  )
}

export default SoftwareFulfillmentTable