import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { evaluateRequirementFulfillments, getSelectionPhaseTaskDetails, updateSelectionPhaseTaskDetails } from '../../../../../data/SelectionPortalRequests';
import MainButton from '../../../../../components/MainButton';
import SelectionPhaseModal from '../selection-guide/SelectionPhaseModal';
import { SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS } from './SpeedDial';

interface props {
    selectionId: string
    acceptedTaskCodes: string[]
    reloadTaskTrigger?: any
}

const SelectionGuideInjector = ({selectionId, acceptedTaskCodes, reloadTaskTrigger} : props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectionTaskId, setSelectionTaskId, setSelectionPhase } = useOutletContext<any>();
    const [ taskCompleted, setTaskCompleted ] = useState();
    const [ taskMetric, setTaskMetric ] = useState();
    const [ uniqueTaskCode, setUniqueTaskCode ] = useState();
    // const [injectionItem, setInjectionItem] = useState<any>(<></>);
    let injectionItem = <></>;
    const [responseWindowForTaskCode, setResponseWindowForTaskCode] = useState("");
    
    useEffect(() => {handleFetchSelectionTaskDetails()}, [])
    useEffect(() => {handleFetchSelectionTaskDetails()}, [selectionTaskId])
    useEffect(() => {handleFetchSelectionTaskDetails()}, [reloadTaskTrigger])
    
    const handleFetchSelectionTaskDetails = async () => {
        if (typeof selectionId === "undefined") return;
        if (selectionTaskId == "") setUniqueTaskCode(undefined);
        const selectionPhaseTaskDetails = await getSelectionPhaseTaskDetails(selectionTaskId);
        setTaskCompleted(selectionPhaseTaskDetails.data.data.completed);
        setTaskMetric(selectionPhaseTaskDetails.data.data.metric);
        setUniqueTaskCode(selectionPhaseTaskDetails.data.data.selectionPhaseTask.uniqueTaskCode);
    }

    const handleUpdateSelectionPhase = async (redirect? : string) => {
        console.log("selectionTaskId", selectionTaskId)
        console.log("uniqueTaskCode", uniqueTaskCode)
        const response = await updateSelectionPhaseTaskDetails(selectionTaskId);
        if (response.data.newSelectionSelectionPhaseTaskSaved != null)  {
            setSelectionTaskId(response.data.newSelectionSelectionPhaseTaskSaved.id);
        } else {
            setSelectionTaskId("");
        }
        if (response.data.nextSelectionPhaseTask != null) setSelectionPhase(response.data.nextSelectionPhaseTask.selectionPhase);
        if (typeof uniqueTaskCode !== "undefined") setResponseWindowForTaskCode(uniqueTaskCode);
        if (typeof redirect !== "undefined") navigate(redirect);
    }

    // REQUIREMENTS PHASE
    if (uniqueTaskCode == "requ-vali_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={'Validate'} func={() => handleUpdateSelectionPhase()} /></div>
            </div>
        )
    } else if (uniqueTaskCode == "requ-subm_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={'Submit to vendors'} func={() => handleUpdateSelectionPhase()} /></div>
            </div>
        )
    } else if (uniqueTaskCode == "requ-revi_reqs" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex grow gap-5 justify-between items-end px-10 mb-6 mt-6 text-xs text-gray-800 max-md:px-5">
                <div className='w-full'><MainButton text={'Evaluate responses'} func={async () =>  {await evaluateRequirementFulfillments(selectionId); navigate(`${location.pathname.replace("requirement-manager", "vendor-ranking")}?endPhase=requirements`)}} /></div>
            </div>
        )
    } else if (uniqueTaskCode == "requ-revi_reqs" && acceptedTaskCodes.includes("FINISH_PHASE")) {
        if (taskCompleted) {
            injectionItem = (
                <div className="border-l px-2">
                    <div className='w-full'><MainButton text={'Finish phase'} func={() => {handleUpdateSelectionPhase(location.pathname+"?endPhase=finish");}} /></div>
                </div>
            )
        } else {
            injectionItem = (
                <div className="border-l px-2 flex items-center text-center font-normal">
                    <p className='w-96 border px-2 py-1 rounded border-amber-600 bg-amber-200'>
                        {taskMetric} {SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS[uniqueTaskCode as keyof typeof SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS]}
                    </p>
                </div>
            )
        }
    } 
    // SCENARIOS PHASE
    else if (uniqueTaskCode == "scen-subm_scen" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <MainButton text={'Submit to vendors'} height="h-10" func={() => {handleUpdateSelectionPhase()}} />
            </div>
        )
    } 
    // PRESENTATIONS PHASE
    else if (uniqueTaskCode == "pres-gene_shor" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <div className='w-full'><MainButton text={'Evaluate presentations'} link={`${location.pathname.replace("presentation-manager", "vendor-ranking")}?endPhase=presentations`} /></div>
            </div>
        )
    } else if (uniqueTaskCode == "pres-gene_shor" && acceptedTaskCodes.includes("FINISH_PHASE")) {
        if (taskCompleted) {
            injectionItem = (
                <div className="border-l px-2">
                    <div className='w-full'><MainButton text={'Finish phase'} func={() => {handleUpdateSelectionPhase(location.pathname+"?endPhase=finish");}} /></div>
                </div>
            )
        } else {
            injectionItem = (
                <div className="border-l px-2 flex items-center text-center font-normal">
                    <p className='w-96 border px-2 py-1 rounded border-amber-600 bg-amber-200'>
                        {taskMetric} {SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS[uniqueTaskCode as keyof typeof SELECTION_GUIDE_PHASE_TASK_INCOMPLETE_LABELS]}
                    </p>
                </div>
            )
        }
    } 
    // CONTRACTS PHASE
    else if (uniqueTaskCode == "cont-nego_prop" && acceptedTaskCodes.includes(uniqueTaskCode)) {
        injectionItem = (
            <div className="flex h-full w-72 grow justify-end items-center font-normal">
                <MainButton text={'Finalize selection'} height="h-10" func={() => {handleUpdateSelectionPhase()}} />
            </div>
        )
    } 

    return (
        <>
        {injectionItem}
        {(responseWindowForTaskCode != "") ? (<SelectionPhaseModal selectionPhaseTaskCode={responseWindowForTaskCode}  />) : (<></>)}
        </>
    )
}

export default SelectionGuideInjector