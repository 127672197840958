import React, { useState } from 'react'
import MainButton from '../../../../../../components/MainButton'

interface props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequirementsSubmitted = ({ setOpen } : props) => {

  return (
    <div className='w-full min-h-[50vh] flex flex-col justify-between '>
            
        <div className='grow flex items-center'>
            
            <div className='w-full h-fit flex flex-col items-center'>
                <img src="/img/graphics/celebration.png" className='h-64 mb-16' />
                <p className='text-5xl font-bold text-center'>Requirements submitted</p>
                <p className='max-w-64 whitespace-normal mt-10 px-16 text-lg text-center'>It may take up to 3 weeks for vendors to respond to your request. In addition to the standard requirements, there are individual ones that require more consideration. Follow the status of incoming responses.</p>

            </div>
            
        </div>
        
        <div className='my-10 gap-2 flex justify-center items-center'>
            <MainButton text={'Learn more'} additionalStyles='bg-transparent border-2 hover:shadow-amber-400 border-amber-400 text-gray-900' />
            <MainButton text={'Continue'} func={() => setOpen(false)} additionalStyles='bg-amber-400 hover:shadow-amber-400' />
        </div>

    </div>
  )
}

export default RequirementsSubmitted