import React from 'react';
import { IconProps } from './IconProps';

const LargeUploadIcon = (props : IconProps) => {
  return (
    <svg aria-hidden="true" fill="none" stroke={props.iconColor} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
      </svg>
  )
}

export default LargeUploadIcon