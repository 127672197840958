import React, { useEffect, useState } from 'react'
import Notification from '../../../components/Notification'
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from '../../../redux/store';
import NotificationSlice, {actions} from '../../../redux/notificationSlice';

// export const createNotification = (message:string, type:string) => {
//     const dispatch = useDispatch();
//     dispatch(
//         actions.createNotification({
//         id: String(Date.now()),
//         message: "We are off to a good start! 🤗 " + String(Math.random()),
//         type: "success"
//     })
//     );
// };

const NotificationOverlay = () => {
    const { notifications } = useSelector((state: RootState) => state.notifications);

    // useEffect(() => {
    //     console.log(notifications);
    // }, [notifications]);

    const createNotification = () => {
        store.dispatch(actions.createNotification({
            id: String(Date.now()),
            message: "We are off to a good start! 🤗 " + String(Math.random()),
            type: "success"
        }))
    }

    return (
        <div className='fixed top-24 right-5 z-[1000] space-y-4'>
            {/* <div onClick={() => createNotification()} className='bg-primary p-4 rounded-lg cursor-pointer'>Add new notification</div> */}
            {
                notifications.map((item:any, index:number) => {
                    return (
                        <Notification id={item.id} text={item.message} type={item.type} />
                    )
                })
            }
        </div>
  )
}

export default NotificationOverlay