import React from 'react';
import { IconProps } from './IconProps';

const LockIcon = (props : IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 7.75C10.11 7.75 9.75 8.54 9.75 10V10.62H14.25V10C14.25 8.54 13.89 7.75 12 7.75Z" fill={props.iconColor || "white"}/>
      <path d="M12 15.1C12.6075 15.1 13.1 14.6075 13.1 14C13.1 13.3925 12.6075 12.9 12 12.9C11.3925 12.9 10.9 13.3925 10.9 14C10.9 14.6075 11.3925 15.1 12 15.1Z" fill={props.iconColor || "white"}/>
      <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17.38 14.5C17.38 16.7 16.7 17.38 14.5 17.38H9.5C7.3 17.38 6.62 16.7 6.62 14.5V13.5C6.62 11.79 7.03 11 8.25 10.73V10C8.25 9.07 8.25 6.25 12 6.25C15.75 6.25 15.75 9.07 15.75 10V10.73C16.97 11 17.38 11.79 17.38 13.5V14.5Z" fill={props.iconColor || "white"}/>
    </svg>
  )
}

export default LockIcon