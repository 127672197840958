import React, { useEffect } from 'react'
import CrossIcon from '../assets/img/icons/CrossIcon';

interface props {
    children: JSX.Element;
    isOpen : boolean;
    setOpen : React.Dispatch<React.SetStateAction<boolean>> | undefined;
    size?: string;
    additionalStyles?: string;
    overlayStyles?: string
}

const FormModal = ({children, isOpen, setOpen, size, additionalStyles, overlayStyles} : props) => {

  useEffect(() => {
    if (isOpen) {document.body.style.overflow = 'hidden';}

    return () => {document.body.style.overflow = 'auto';}
  }, [])

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    if (typeof setOpen !== "undefined") setOpen((prev) : boolean => !prev);
  }

  return (
    <div className="flex items-center justify-center fixed top-0 left-0 w-screen h-screen z-[10000]">

      <div onClick={() => handleClose()} className={`${overlayStyles || "bg-gray-100 opacity-50"} absolute w-full h-full z-[100]`}></div>
      
      <div className={`${additionalStyles} relative bg-white rounded-lg shadow-2xl ${(size == "large") ? ("lg:w-[90%]") : ("lg:w-1/2")} mx-auto my-auto z-[100]`}>
        <button onClick={() => handleClose()} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="authentication-modal">
          <div className='w-5 h-5'><CrossIcon iconColor='gray' /></div>
          <span className="sr-only">Close modal</span>
        </button>
      
        <div className="px-6 py-6 lg:px-8">
          {children}
        </div>
    </div>
    
  </div>
  );
}

export default FormModal