export async function addEmptySoftwareFulfillment(productId : string, note : string) {
    const payload = {
        softwareProductId: productId,
        note: note
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/fulfillment/add-empty-fulfillment`, options)
        
        if (res.ok) {
            console.log("Created empty software fulfillment successfully");
            const data = await res.json();
            return {sucess: true, data: data.data};
        } else {
            console.error(`addEmptySoftwareFulfillment: Bad response ${res.status}`);
            return {sucess: false, data: undefined};
        }
        
    } catch (error) {
        console.error(`addEmptySoftwareFulfillment: Error occurred ${error}`);
        return {sucess: false, data: undefined};
    }
}

export async function addCrawlSoftwareFulfillment(productId : string, note : string) {
    const payload = {
        softwareProductId: productId,
        note: note
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/fulfillment/add-crawl-fulfillment`, options)
        
        if (res.ok) {
            console.log("Created empty software fulfillment successfully");
            const data = await res.json();
            return {sucess: true, data: data.data};
        } else {
            console.error(`addEmptySoftwareFulfillment: Bad response ${res.status}`);
            return {sucess: false, data: undefined};
        }
        
    } catch (error) {
        console.error(`addEmptySoftwareFulfillment: Error occurred ${error}`);
        return {sucess: false, data: undefined};
    }
}

export async function addCopySoftwareFulfillment(productId : string, note : string, idOfVersionToCopy : string) {
    const payload = {
        softwareProductId: productId,
        note: note,
        idOfVersionToCopy: idOfVersionToCopy
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/fulfillment/add-copy-fulfillment`, options)
        
        if (res.ok) {
            console.log("Created empty software fulfillment successfully");
            const data = await res.json();
            return {sucess: true, data: data.data};
        } else {
            console.error(`addEmptySoftwareFulfillment: Bad response ${res.status}`);
            return {sucess: false, data: undefined};
        }
        
    } catch (error) {
        console.error(`addEmptySoftwareFulfillment: Error occurred ${error}`);
        return {sucess: false, data: undefined};
    }
}

export async function fetchAllSoftwareFulfillments() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/get-all-fulfillments`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchAllSoftwareFulfillments: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchAllSoftwareFulfillments: Error occurred ${error}`);
    }
}

export async function fetchSoftwareFulfillmentData(softwareFulfillmentId : string, userRole : string) {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/${(userRole == "platform_manager") ? ("get-fulfillment-manager") : ("get-fulfillment-vendor")}?id=${softwareFulfillmentId}`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchSoftwareFulfillmentData: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchSoftwareFulfillmentData: Error occurred ${error}`);
    }
}

export async function sendSoftwareFulfillmentData(softwareFulfillmentId: string, changedRequirementScores : any, userRole : string) {
    // TODO: Besserer Fehlerabfang bei schlechtem Input

    const payload = {
        fulfillmentId : softwareFulfillmentId,
        changedScores : changedRequirementScores
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/${(userRole == "platform_manager") ? ("add-fulfillment-scores-manager") : ("add-fulfillment-scores")}`;
        const res = await fetch(route, options)
        
        if (res.ok) {
            console.log("Updated scores successfully");
        } else {
            console.error(`sendSoftwareFulfillmentData: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`sendSoftwareFulfillmentData: Error occurred ${error}`);
    }
}

export async function uploadRequirementDump(requirementDump : any[]) {
    const formData = new FormData();
    formData.append("requirementDump", JSON.stringify(requirementDump));

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData
    }
    
    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/upload-requirement-dump`;
        const res = await fetch(route, options)
        
        if (res.ok) {
            console.log("Requirements successfully");
            return true;
        } else {
            console.error(`uploadRequirementDump: Bad response ${res.status}`);
            return false;
        }
    
    } catch (error) {
        console.error(`uploadRequirementDump: Error occurred ${error}`);
        return false;
    }
}

export async function syncFulfillmentWithMatcher(softwareFulfillmentIds : any[]) {
    const payload = {
        softwareFulfillmentIds: softwareFulfillmentIds
    };

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }
    
    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/sync-fulfillments-matcher`;
        const res = await fetch(route, options)
        
        if (res.ok) {
            return true;
        } else {
            console.error(`syncFulfillmentWithMatcher: Bad response ${res.status}`);
            return false;
        }
    
    } catch (error) {
        console.error(`syncFulfillmentWithMatcher: Error occurred ${error}`);
        return false;
    }
}

export async function syncFulfillmentRemoveFromMatcher(softwareFulfillmentIds : any[]) {
    const payload = {
        softwareFulfillmentIds: softwareFulfillmentIds
    };

    const options : RequestInit = {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }
    
    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/sync-fulfillments-remove-from-matcher`;
        const res = await fetch(route, options)
        
        if (res.ok) {
            return true;
        } else {
            console.error(`syncFulfillmentRemoveFromMatcher: Bad response ${res.status}`);
            return false;
        }
    
    } catch (error) {
        console.error(`syncFulfillmentRemoveFromMatcher: Error occurred ${error}`);
        return false;
    }
}

export async function fetchRequirementCatalogue() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/get-requirement-catalogue`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchRequirementCatalogue: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchRequirementCatalogue: Error occurred ${error}`);
    }
}

export async function fetchRequirement(requirementId : string) {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/fulfillment/get-requirement?requirementId=${requirementId}`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchRequirementCatalogue: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchRequirementCatalogue: Error occurred ${error}`);
    }
}

export async function deleteSoftwareFulfillment (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/fulfillment/delete-fulfillment`, options)
        if (res.ok) {
            console.log("deleteSoftwareFulfillment: Deleted fulfillment successfully");
        } else {
            console.error(`deleteSoftwareFulfillment: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`deleteSoftwareFulfillment: Error occurred ${error}`);
    }
}