import React, { SetStateAction, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import SoftwareProductTable from '../../object-settings/tables/SoftwareProductTable';
import { fetchProductData, fetchSoftwareProductData, getImage } from '../../../../../../data/ProductRequests';
import SoftwareFulfillmentSurvey from '../../software-fulfillment-survey/Index';
import MainButton from '../../../../../../components/MainButton';
import FormModal from '../../../../../../components/FormModal';
import AddSoftwareProductForm from '../../object-settings/forms/AddSoftwareProductForm';
import { useTranslation } from 'react-i18next';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';

const OnboardingCreateProduct = () => {
  const {t} = useTranslation();
  const [softwareProductData, setSoftwareProductData] = useState([]);
  const [productName, setProductName] = useState("")
  const [productUrl, setProductUrl] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [productFulfillment, setProductFulfillment] = useState<any[]>([])
  const [imageSource, setImageSource] = useState("");
  const [fulfillmentOpen, setFulfillmentOpen] = useState(false);

  const [finishedProducts, setFinishedProducts] = useState<any[]>([]);
  
  const [ setProductNamesAndIds, selectedProductId, setSelectedProductId ] = useOutletContext<any>();

  const handleReloadProducts = async () => {
    setSoftwareProductData([]);
    const data = await fetchSoftwareProductData("vendor")
    console.log(data)
    setSoftwareProductData(data);
    setProductNamesAndIds(data.map((obj : any, index : number) => ({productName: obj.productName, id: obj.id})))
  }

  useEffect(() => {handleReloadProducts()}, [])

  const handleSelectProduct = (productId : string) => {
    setFulfillmentOpen(false);
    console.log(productId)
    setSelectedProductId(productId);
    fetchProductData(productId).then(data => {
      setProductName(data.productData.productName);
      setProductUrl(data.productData.productUrl);
      setProductDescription(data.productData.productDescription);
      setProductFulfillment(data.productData.softwareFulfillment);
    });
    getImage(productId, "/api/product/get-logo?id=", 'https://via.placeholder.com/400x200').then(frontendImageLocation => {
      setImageSource(frontendImageLocation);
    });
  }
   const handleDeselectProduct = () => {
    setFulfillmentOpen(false);
    setSelectedProductId("");
   }

  return (
    <div>
      <div>
        <h1 className="text-3xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">{(softwareProductData.length > 0) ? (t("updateProductDetails")) : (t("onboardingAddProductDetails"))}</h1>
        <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">{t("onboardingAddProductDescription")}</p>
        
        { 
          (selectedProductId != "") ? (
            (!fulfillmentOpen) ? (
              <>
                <AddSoftwareProductForm loadProductId={selectedProductId} successElement={
                  <div>
                    <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">{t("onboardingFulfillmentDescription")}</p>
                    <div onClick={() => setFulfillmentOpen(true)}
                    className="flex justify-between mt-10">
                      <MainButton text={t("next")} text_location='left' icon={<ArrowRightIcon iconColor='white' />} icon_color='' link="" />
                    </div>
                  </div>
                } onboarding={true} />
              
              </>
            ) : (productFulfillment.length > 0) ? (
              <FormModal isOpen={fulfillmentOpen} setOpen={setFulfillmentOpen} size="large">
                <div className='max-sm:max-w-[90vw] max-h-[85vh] overflow-y-auto'>
                  <SoftwareFulfillmentSurvey softwareFulfillmentIdProp={productFulfillment[0].id} exitAction={() => {finishedProducts.push(selectedProductId);handleDeselectProduct();}} />
                </div>
              </FormModal>
            ) : (<></>)
          ) : (
            <div className='pl-16'>
              <div className='font-medium'>
                {t("yourProducts")}
              </div>
              {
                softwareProductData.map((obj : any, index : number) => (
                  <div onClick={() => handleSelectProduct(obj.id)}
                  className={`sm:w-1/2 cursor-pointer hover:border-slate-200 hover:bg-[#FAFAFA]/50 m-2 flex border border-white rounded-md bg-white items-center gap-9.5 gap-y-6 px-4 mb-0 py-3 max-md:flex-wrap max-sm:gap-x-5`}>
                    <div className='flex items-center justify-center p-3 w-5 h-5 rounded-full border-2 border-gray-500'>
                      {
                        (finishedProducts.includes(obj.id)) ? (
                          <div className=''><CheckmarkIcon /></div>
                        ) : (<></>)
                      }
                    </div>
                    <div className='pl-2'>
                      <p>{obj.productName}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      {
        (finishedProducts.length == softwareProductData.length) ? (
          <div className="mt-10">
            <MainButton text={t("next")} text_location='left' icon={<ArrowRightIcon iconColor='white' />} icon_color='' link="/onboarding/next-steps" />
          </div>

        ) : (<></>)
      }
    </div>
  )
}

export default OnboardingCreateProduct