import React from 'react'
import { Navigate, useLocation } from "react-router-dom";

import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

interface props {
    protectedIfLoggedIn : boolean;
    children: JSX.Element;
    allowedRoles: string[]
}

const ProtectedRoute = ({protectedIfLoggedIn, allowedRoles, children} : props) => {
    const prevLocation = useLocation();
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
    const userRole = useSelector((state: RootState) => state.user.role);

    // Route is only available if user is NOT logged in & user IS currently logged in > GO TO HOME
    // console.log(userIsLoggedIn, prevLocation.pathname, protectedIfLoggedIn, userIsLoggedIn, protectedIfLoggedIn && userIsLoggedIn ? "1" : "", !protectedIfLoggedIn && !userIsLoggedIn ? "2" : "")
    if (protectedIfLoggedIn && userIsLoggedIn) { return <Navigate to="/" replace={true} />; }

    // Route is only available if user IS logged in & user is currently NOT logged in > GO TO SIGNIN
    if (!protectedIfLoggedIn && !userIsLoggedIn) { return <Navigate to={`/signin?redirectTo=${prevLocation.pathname}`} replace={true} />; }
    
    // Route is only available for specific roles & user has other role > GO TO HOME
    if (userRole != "") {
        if (!allowedRoles.includes(userRole)) { console.log(`Route blocked for user type ${userRole}`); return <Navigate to="/" replace={true} />; }
    }
    
    return children;
}

export default ProtectedRoute;