import React from 'react'
import MessageBubble from '../../../requirement-manager/components/MessageBubble'

const SidebarChat = () => {
  return (
    <div className='flex flex-col grow max-h-full'>
        <div className='h-full max-h-full overflow-y-auto w-full flex items-end pb-4'>
            <div className='w-full px-8 space-y-2'>
                <MessageBubble messageEmail={"email2"} ownEmail={"email"} text={"message.chatMessage_message"} date={"2024-03-04 18:30"} />
                <MessageBubble messageEmail={"email"} ownEmail={"email"} text={"message.chatMessage_message"} date={"2024-03-04 18:30"} />
                <MessageBubble messageEmail={"email2"} ownEmail={"email"} text={"message.chatMessage_message"} date={"2024-03-04 18:30"} />
            </div>
        </div>
        

        <div className='flex items-center h-20 border-t px-4 bg-white w-full'>
            <div className='w-full'>   
                <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                <div className="relative">
                    <input 
                    // onChange={(e) => setNewMessage(e.target.value)}
                    // onKeyDown={(e) => (e.key === 'Enter') ? (handleSendMessage()) : (null)}
                    type="search" id="search" 
                    // value={newMessage}
                    className="block w-full p-4 text-sm text-gray-900 border border-gray-300 focus:border-gray-500 rounded-lg bg-gray-50" placeholder="Message..." required />
                    <button 
                    // onClick={() => handleSendMessage()}
                    type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Send</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SidebarChat