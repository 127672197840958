import React from 'react';
import { IconProps } from './IconProps';

const MessageIcon = (props : IconProps) => {
  return (
    <svg className="w-full h-full" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path stroke={props.iconColor || "currentColor"} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"/>
    </svg>  
  )
}

export default MessageIcon