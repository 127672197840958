import React from 'react'
import DocumentComment from './components/DocumentComment'
import { HighlightArea } from '@react-pdf-viewer/highlight'

interface props {
  comments: any[],
  jumpToHighlight: (comment: any) => void,
  clickedHighlightComment: any
}

const SidebarComments = ({comments, jumpToHighlight, clickedHighlightComment} : props) => {
  return (
    <div className='p-4 overflow-y-auto'>
        {
          comments.filter((item:any, index:number) => (item.checklistItem == null)).map((comment:any, index:any) => {
            return (
              <DocumentComment comment={comment} jumpToHighlight={jumpToHighlight} clickedHighlightComment={clickedHighlightComment} />
            )
          })
        }
    </div>
  )
}

export default SidebarComments