import React, { useEffect, useRef, useState } from 'react'
import PlaceholderSkeleton from '../../../../../components/PlaceholderSkeleton'
import UnderConstructionPlaceholder from '../../../../../components/UnderConstructionPlaceholder'
import ScenarioOverview from './features/ScenarioOverview'
import ScenarioViewer from './features/ScenarioViewer'
import CrossIcon from '../../../../../assets/img/icons/CrossIcon'
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import { getSelectionScenarioItems } from '../../../../../data/SelectionPortalRequests'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import SelectionGuideInjector from '../components/SelectionGuideInjector'

const Index = () => {
    const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
    const [selectedTab, setSelectedTab] = useState("");
    const [openScenarios, setOpenScenarios] = useState<any[]>([]);
    const [queryScenarioId, setQueryScenarioId] = useState<string>("");
    const [scenarios, setScenarios] = useState<any[]>();
    const ref = useRef<any>();
    const navigate = useNavigate();

    useEffect(() => {setSelectedSelectionPhase("scenarios");}, [])

    const handleOpenScenario = (scenarioId: string, scenarioTitle: string) => {
        if (!openScenarios.map(item => item.id).includes(scenarioId)) {
            setOpenScenarios(prev => [...prev, {title: scenarioTitle, id: scenarioId}])
          setSelectedTab(scenarioId);
          navigate(`?scenarioId=${scenarioId}`);
        } else {
          setSelectedTab(scenarioId);
          navigate(`?scenarioId=${scenarioId}`);
        }
      }
    
    const handleCloseScenario = (scenarioId: string) => {
        setOpenScenarios(prev => prev.filter(item => item.id != scenarioId));
    }

    useEffect(() => {
        if (openScenarios.length == 0) {
          setSelectedTab("");
          navigate("");
        } else if (!openScenarios.map(item => item.id).includes(selectedTab)) {
          setSelectedTab("")
          navigate("")
        }
      }, [openScenarios]);

    useEffect(() => {
        if (typeof ref.current !== "undefined" && ref.current) {
          ref.current.scrollIntoView({ behavior: "smooth" });
        }
      }, [selectedTab]);
    
    const handleFetchScenarios = async () => {
        const response = await getSelectionScenarioItems(selectionId);
        if (response.ok) {
            // console.log(response.data.data);
            generateScenariosAggregations(response.data.data);
        }
    }

    const generateScenariosAggregations = (scenarios : any[]) => {
      const aggregationAddedToScenarios = scenarios.map(scenario => {

          scenario.selectionScenarioTasks.map((scenarioTask : any) => scenarioTask.scenarioStatus);
          const tasks = scenario.selectionScenarioTasks;
          const counts : any = {};
          for (let i = 0; i < tasks.length; i++) {
              counts[tasks[i].scenarioStatus] = (counts[tasks[i].scenarioStatus] || 0) + 1 ;
          }

          scenario.numberOfScenarioTasks = scenario.selectionScenarioTasks.length;
          scenario.scenarioTaskStatuses = counts;

          return scenario;
      })

      // console.log(aggregationAddedToScenarios);
      // setFilteredScenarios(aggregationAddedToScenarios);
      setScenarios(aggregationAddedToScenarios)
    }

    useEffect(() => {
      handleFetchScenarios();
    }, [])
    
    return (
    <div className='h-full flex flex-col'>
        {/* <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              Scenario-Manager
            </h1>
        </div> */}

        <div className="flex h-12 pr-5 pl-5 text-base font-semibold leading-6 text-text-gray-800 bg-white border-b border-solid">
            <ul className="h-full w-max pt-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
                <li 
                onClick={() => {setSelectedTab("");}}
                // navigate("")
                className="mr-2 flex items-end cursor-pointer">
                    <p className={`inline-block h-full p-2 border-b-4 bg-gray-100 truncate w-32 rounded-t-lg ${(selectedTab == "") ? ("text-primary border-primary dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>
                    {/* {(selectedRequirementCategory3) ? (selectedRequirementCategory3) : (selectedRequirementCategory2) ? (selectedRequirementCategory2) : (selectedRequirementCategory1) ? (selectedRequirementCategory1) : ("All categories")} */}
                    Overview
                    </p>
                </li>
            </ul>
            <ul className="flex overflow-x-auto w-full h-full pt-2 text-sm w-full font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">

                {
                    openScenarios.map((scenario:any, index:number) => {
                        return (
                            <li 
                            ref={(selectedTab == scenario.id) ? (ref) : (undefined)}
                            onClick={() => {setSelectedTab(scenario.id);navigate(`?scenarioId=${scenario.id}`);}}
                            className={`flex items-end justify-between mr-2 p-2 border-b-4 ${(selectedTab == scenario.id) ? ("text-primary border-primary dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")} cursor-pointer`}>
                                <p className={`inline-block align-bottom h-full truncate w-64 rounded-t-lg`}>
                                {scenario.title}
                                </p>
                                <div 
                                onClick={(e) => {handleCloseScenario(scenario.id); e.stopPropagation();}}
                                className='w-6 h-6 p-1 hover:bg-gray-100'>
                                  <CrossIcon iconColor='black' />
                                </div>
                            </li>
                        )
                    })
                }
            </ul>

            <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["scen-subm_scen"]} />
        </div>

        {
          typeof scenarios === "undefined" ? (<LoadingSpinner />) : (
            <div className='p-4 grow overflow-y-auto'>
                {
                    (selectedTab == "") ? (
                        <ScenarioOverview handleOpenScenario={handleOpenScenario} scenarios={scenarios} selectionId={selectionId} handleFetchScenarios={handleFetchScenarios} />
                    ) : (
                        <ScenarioViewer scenarioId={selectedTab} scenario={scenarios.filter(item => item.id == selectedTab)[0]} handleFetchScenarios={handleFetchScenarios} />
                    )
                }
            </div>
          )
        }

    </div>
  )
}

export default Index