import React from 'react'
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon'
import UnderConstructionPlaceholder from '../../../../../../components/UnderConstructionPlaceholder'
import BriefcaseIcon from '../../../../../../assets/img/icons/BriefcaseIcon'
import MainButton from '../../../../../../components/MainButton'
import OpenIcon from '../../../../../../assets/img/icons/OpenIcon'

interface props {
    setConsultantMarketplaceOpen : React.Dispatch<React.SetStateAction<boolean>>,
}

const ConsultantMarketplaceWindow = ({setConsultantMarketplaceOpen} : props) => {
  return (
    <>
        <div onClick={() => setConsultantMarketplaceOpen(false)} className='absolute w-fit h-8 my-1 text-gray-900 space-x-2 cursor-pointer p-2 rounded-md flex items-center bg-white hover:bg-gray-100'>
            <ArrowLeftIcon iconColor='currentColor' />
            <div>Back</div>
        </div>

        <div className='w-full flex flex-col items-center text-center'>
            <div className='mb-4 w-16 h-16 border-2 border-primary text-primary rounded-full p-2'><BriefcaseIcon iconColor='currentColor' /></div>
            <p className='text-2xl font-bold text-primary'>Get expert help</p>
            <p className='text-center italic mt-2 px-8'>Find an expert that matches your business to assist you in the selection process.</p>
        </div>

        <div className='mt-8 overflow-y-auto border-t border-b px-4 pt-2 pb-8'>
            <UnderConstructionPlaceholder pageTitle={''} />
        </div>

        <div className='w-full'>
            <div className='ml-auto mt-4 w-fit'>
                <MainButton text={'Go to marketplace'} link={''} style='light' icon={<div className='text-primary3'><OpenIcon iconColor='currentColor' /></div>} />
            </div>
        </div>

    </>
  )
}

export default ConsultantMarketplaceWindow