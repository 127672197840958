import React from 'react'
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, ArrowRightIcon, CakeChartIcon, CheckoutFilledIcon, FilledInfoIcon, HeadsetIcon, ProfileFilledIcon, SettingsFilledIcon, SignoutIcon } from 'swap-frontend-library'
import FourSquaresIcon from '../../../assets/img/icons/FourSquaresFilledIcon';

const useSPNavItems = (collapsedSidebar? : boolean, handleCollapseSidebar? : () => void) => {
    const {t} = useTranslation();
    
    const menu_items = [
        {
          label: "Dashboard",
          icon: <div className='w-6 h-6 text-gray-700'><CakeChartIcon /></div>,
          roleLink: {
            "platform_manager": "", 
            "vendor": "", 
          },
          topAlign: true,
        },
        {
          label: "Searches",
          icon: <div className='w-6 h-6 text-gray-700'><FourSquaresIcon /></div>,
          roleLink: {
            "customer": ""
          },
          topAlign: true
        },
        {
          label: t("sales"),
          icon: <div className='w-6 h-6 text-gray-700'><CakeChartIcon /></div>,
          roleLink: {
            "vendor": "#", 
            "platform_manager": "sales", 
          },
          topAlign: true
        },
        {
          label: t("leads"),
          icon: <div className='w-6 h-6 text-gray-700'><CakeChartIcon /></div>,
          roleLink: {
            "vendor": "leads", 
          },
          topAlign: true
        },
        {
          label: t("Settings"),
          icon: <div className='w-6 h-6 text-gray-700'><SettingsFilledIcon /></div>,
          roleLink: {
            "platform_manager": [
              {
                label: t("softwareProducts"),
                link: "settings/software"
              },
              {
                label: t("customers"),
                link: "settings/customers"
              },
              {
                label: t("vendor"),
                link: "settings/vendors"
              },
              {
                label: t("platformManagers"),
                link: "settings/admin"
              },
              {
                label: t("matchingLogic"),
                link: "settings/matching-logic"
              },
            ], 
            "vendor": [
              {
                label: t("softwareProducts"),
                link: "settings/software"
              },
            ], 
          },
          topAlign: true
        },
        // Bottom 
        // {
        //   label: t("shop"),
        //   icon: <div className='w-6 h-6 text-gray-700'><CheckoutFilledIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "shop",
        //     "vendor": "shop", 
        //     "customer": "shop"
        //   },
        //   topAlign: false
        // },
        // {
        //   label: t("profileSettings"),
        //   icon: <div className='w-6 h-6 text-gray-700'><ProfileFilledIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "profile-settings", 
        //     "vendor": "profile-settings", 
        //     "customer": "profile-settings"
        //   },
        //   topAlign: false
        // }, 
        {
          label:t("signOut"),
          icon: <div className='w-6 h-6 text-gray-700'><SignoutIcon /></div>,
          roleLink: {
            "platform_manager": "/signout", 
            "vendor": "/signout", 
            "customer": "/signout"
          },
          topAlign: false
        }, 
        // {
        //   label: t("documentation"),
        //   icon: <div className='w-6 h-6 text-gray-700'><FilledInfoIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "profile-settings", 
        //     "vendor": "profile-settings", 
        //     "customer": "profile-settings"
        //   },
        //   topAlign: false
        // }, 
        // {
        //   label: t("help"),
        //   icon: <div className='w-6 h-6 text-gray-700'><HeadsetIcon /></div>,
        //   roleLink: {
        //     "platform_manager": "profile-settings", 
        //     "vendor": "profile-settings", 
        //     "customer": "profile-settings"
        //   },
        //   topAlign: false
        // },
        {
          label: t("Schließen"),
          icon: (collapsedSidebar) ? (<div className='w-6 h-6 text-gray-700'><ArrowRightIcon /></div>) : (<div className='w-6 h-6 text-gray-700'><ArrowLeftIcon /></div>),
          roleLink: {
            "platform_manager": "#", 
            "vendor": "#", 
            "customer": "#"
          },
          onClick: handleCollapseSidebar,
          topAlign: false
        }
      ]
  return menu_items
}

export default useSPNavItems