export async function updateVendorLeads() {
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/leads/update-vendor-leads`, options)
        if (res.ok) {
            console.log("updateVendorLeads successfully queried");
        } else {
            console.error(`updateVendorLeads: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`updateVendorLeads: Error occurred ${error}`);
    }
}

export async function getVendorLeads() {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/leads/get-vendor-leads`, options)
        if (res.ok) {
            console.log("getVendorLeads successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getVendorLeads: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getVendorLeads: Error occurred ${error}`);
    }
}

export async function getVendorLeadData(leadId: string) {
    const options : RequestInit = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/leads/get-vendor-lead-data?leadId=${leadId}`, options)
        if (res.ok) {
            console.log("getVendorLeadData successfully queried");
            const data = await res.json();
            return data.data;
        } else {
            console.error(`getVendorLeadData: Bad response ${res.status}`);
        }
    } catch (error) {
        console.error(`getVendorLeadData: Error occurred ${error}`);
    }
}