import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchFactConfig } from '../../../../../../data/MatchingConfigRequests';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import FormModal from '../../../../../../components/FormModal';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import QueryField from '../../../../../../components/QueryField';

const Index = () => {
  const [queryResult, setQueryResult] = useState<any>();
  const [factConfig, setFactConfig] = useState<any>();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<any>();

  const [extractorModels, setExtractorModels] = useState([
      {id: "1", name: "QA-Extractor", active: true, running: true},
      {id: "2", name: "Tokenize-Extractor", active: false, running: true},
      {id: "3", name: "SemSim-Extractor", active: false, running: true},
      {id: "4", name: "ZeroShot-Classifyer", active: false, running: false}
  ]);

  useEffect(() => {
    handleFetchFactConfig();
  }, [])

  const handleFetchFactConfig = () => {
    fetchFactConfig().then(data => {
      if (data) {
        setFactConfig(data.questions);
      } else {
        console.error("Couldnt fetch facts config.")
      }
    })
  }

  const handleActivateModels = (id: string) => {
    let temp = extractorModels;
    temp = temp.map((val: any, idx: number) => {
      if (val.id == id && val.running) { val.active = !val.active; }
      return val;
    })

    console.log(temp)

    setExtractorModels([...temp]);
  }
  
  useEffect(() => {console.log(queryResult)}, [queryResult])
  return (
    <div>
      <div className="relative bg-white overflow-x-auto shadow-md sm:rounded-lg">
        <div className="text-sm font-medium text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Extraktion Testing
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Testen Sie hier die Informations-Extraktion der Algorithmen.</p>
          </div>
          <ul className='p-5'>
            {
              extractorModels.map((model:any, idx:number) => {

                return (
                  <li key={`option-${idx}`} className="float-left m-1">
                    <input type="checkbox" id={`option-${idx}`} checked={model.active && model.running} onChange={() => handleActivateModels(model.id)} className="hidden peer" />
                    <label htmlFor={`option-${idx}`} className="inline-flex items-center justify-between w-full p-2 text-gray-500 border rounded-lg bg-gray-50 border-gray-300 text-gray-600 cursor-pointer peer-checked:border-gray-700 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div className="flex flex-col sm:flex-row gap-2">
                        <div className="w-[15px] h-[15px] flex"><div className={`mx-auto w-full h-full ${(model.running) ? ("bg-[#04bf07]") : ("bg-[#eb4034]")} blur-[3px] rounded-full`}></div></div>
                        <div className="w-full text-sm">{model.name}</div>
                      </div>
                    </label>
                  </li>
                )
              })
            }   
          </ul>
          <div className='float-none clear-both'></div>
    
          <div className="px-4 bg-white border-b space-y-6 border-gray-200">
          {/* testing_mode={{"facts":true, ""}}  */}
            <QueryField testing={true} setResult={setQueryResult} testingMode={extractorModels} />
          </div>
    
        </div>
      </div>

      {
        (typeof queryResult === "undefined" || typeof factConfig === "undefined") ? (<></>) : (typeof queryResult.facts !== "undefined" && queryResult.facts) ? (
          <>
            <div className="relative overflow-x-auto bg-white shadow-md sm:rounded-lg mt-5">
              <div className="text-sm font-medium text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  Unternehmens-Fakten
                  <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Testen Sie hier die Informations-Extraktion der Algorithmen.</p>
                </div>

                <div className="p-6 bg-white border-b border-gray-200 grid grid-cols-2 gap-4">
                  {
                    Object.keys(queryResult.facts).map((key:any, idx:number) => {

                      if (factConfig[key].information == "logo") {
                        return  (
                          <div className="w-full mt-0">
                            <div className="block text-sm font-medium text-gray-900 dark:text-white">{
                              factConfig[key].information.charAt(0).toUpperCase() + factConfig[key].information.slice(1)
                            }</div>
                            <div className='flex bg-gray-100 rounded'>
                              <img src={`https://${queryResult.facts[key]}`} className='max-h-[100px] max-w-full' />
                            </div>
                          </div>
                        );
                      } else {

                        return (
                          <div className='w-full mt-0'>
                            <div className="block text-sm font-medium text-gray-900 dark:text-white">{
                              factConfig[key].information.charAt(0).toUpperCase() + factConfig[key].information.slice(1)
                            }</div>
                            {
                              queryResult.facts[key].map((val:any, idx:number) => {
                                if (val.constructor === Array) {
                                  return (String(val).split(",").map((val:any, idx:number) => (
                                    <div>- {val}</div>
                                  )))
                                } else if (typeof val === "object") {
                                  console.log()
                                  return (Object.keys(val).map((key2:any, idx2:number) => (
                                    <div>- {key2}: {val[key2]}</div>
                                  )))
                                } else {
                                  return (<div>{String(val).replaceAll(",", ", ")}</div>)
                                }
                              })
                            }
                          </div>
                        );
                      }

                    })
                  }
                </div>
              </div>
            </div>
          </>
      ) : (<></>)}

      {
        (typeof queryResult === "undefined") ? (<></>) : (typeof queryResult.details !== "undefined" && queryResult.details) ? (
        <>
          {(detailsModalOpen) ? (<FormModal children={
          <div className='h-full bg-white'>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Detailauswertung</h3>
            <div className='flex space-x-2'>
            {selectedDetail.category.map((item: string, index: number) => {
              if (item != null) {
                return (
                  <div className='flex items-center space-x-1 text-primary'>
                    {(index > 0) ? (<ArrowRightIcon iconColor='currentColor' />) : (<></>) }
                    <div className='text-base font-regular leading-5'>{item}</div>
                  </div>
                )
              }  
            })}
            </div>
            
            <div className="block text-sm font-medium text-gray-900 mt-5">Information</div>
            <div>{selectedDetail.requirement}</div>
            
            <div className="block text-sm font-medium text-gray-900 mt-5">Sicherheit</div>
            <div className='text-primary font-bold'>{Math.round(selectedDetail.confidence*100)}%</div>
            
            <div className="block text-sm font-medium text-gray-900 mt-5">Informationsquellen</div>
            <div className='max-h-[500px] overflow-y-auto mt-4 bg-gray-100 rounded-md p-5'>
              {
                selectedDetail.reasoning.map((model: any, index: number) => {
                  return (
                    <div>
                      {
                        model[Object.keys(model)[0]].map((reasoning: any, index: number) => {
                          return (
                            <div className='mt-3 border-b pb-3 text-sm font-medium text-gray-500'>
                              <div className="block text-sm font-medium text-gray-900 dark:text-white">Informationsquelle {index+1}</div>
                              <div className='italic'>
                                {
                                  (Object.keys(model)[0] == "keyword") ? (<div>"{reasoning.originText[Object.keys(reasoning.originText)[0]]}"</div>) : (<div>"{reasoning.originText.split("_")[1]}"</div>)
                                }
                              </div>
                              <a href={reasoning.originUrl} target='_blank' className='underline text-primary'>
                                {reasoning.originUrl}
                              </a>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>

          </div>
          } isOpen={detailsModalOpen} setOpen={setDetailsModalOpen} size={''} />) : (<></>)}

          <div className="relative bg-white overflow-x-auto shadow-md sm:rounded-lg mt-5">
              <div className="text-sm font-medium text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                  Unternehmens-Details
                  <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Testen Sie hier die Informations-Extraktion der Algorithmen.</p>
                </div>

                <div className="px-4 p-5 bg-white border-b space-y-6 border-gray-200">

                  {
                    queryResult.details.statistics.sort((a:any,b:any) => b.count - a.count).map((category_statistic:any, idx:number) => {

                      return (
                        <div className='w-full'>
                          <div className='block text-lg font-medium text-gray-900'>{category_statistic.category} <span className='text-primary'># {category_statistic.count}, ⌀ {Math.round(category_statistic.avg_conf * 100)}% </span></div>
                          <div className="p-6 bg-white border-b border-gray-200 grid grid-cols-2 gap-4">
                          { 
                            category_statistic.avg_sub_conf.sort((a:any,b:any) => b.count - a.count).map((subcategory_statistic:any, idx:number) => {
                              const parsedReqSubCategory = String(subcategory_statistic.category).split("-;-")[1];
                              return (
                                <div className='w-full mt-0'>
                                  <div className="block text-sm font-medium text-gray-900 dark:text-white">{parsedReqSubCategory} <span className='text-primary'># {subcategory_statistic.count}, ⌀ {Math.round(subcategory_statistic.avg_conf * 100)}% </span></div>

                                  {
                                    queryResult.details.requirements[category_statistic.category][subcategory_statistic.category].map((req:any, idx:number) => {

                                      return (
                                        <div className='line-clamp-2 float-left cursor-pointer hover:underline' onClick={() => {setSelectedDetail(req); setDetailsModalOpen(true)}}>
                                          - ({Math.round(req.confidence*100)}%): {req.requirement}
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              )
                            })
                          }
                        </div>
                        </div>
                      )
                    })
                  }

                </div>
              </div>
          </div>
        </>  
      ) : (<></>)}
    </div>
  )
}

export default Index