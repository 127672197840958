import React, { useEffect, useState } from 'react'
import FormModal from '../../../../../../../components/FormModal'
import TableHeader from '../../../../../../../components/TableHeader';
import TableRow from '../../../../../../../components/TableRow';
import MainButton from '../../../../../../../components/MainButton';
import ArrowDownIcon from '../../../../../../../assets/img/icons/ArrowDownIcon';

interface props {
    requirementTemplates: any[],
    requirementTemplatesWizardModelOpen: boolean,
    setRequirementTemplatesWizardModelOpen: React.Dispatch<React.SetStateAction<boolean>>,
    uniqueRequirementCategories: any,
    setSelectedRequirementTemplate: React.Dispatch<React.SetStateAction<any | undefined>>,
}

const RequirementInputWizardModal = ({requirementTemplates, uniqueRequirementCategories, requirementTemplatesWizardModelOpen, setRequirementTemplatesWizardModelOpen, setSelectedRequirementTemplate} : props) => {
    const [selectedRequirementCategory1, setSelectedRequirementCategory1] = useState("");
    const [selectedRequirementCategory2, setSelectedRequirementCategory2] = useState("");
    const [selectedRequirementCategory3, setSelectedRequirementCategory3] = useState("");
    const [selectionRequirementFiltered, setSelectionRequirementFiltered] = useState<any[]>([]);
    
    const handleSetCategory1 = (category: string) => {
        if (selectedRequirementCategory1 == "" || selectedRequirementCategory1 != category) {
          setSelectedRequirementCategory1(category);
        } else {
          setSelectedRequirementCategory1("");
          setSelectedRequirementCategory2("");
          setSelectedRequirementCategory3("");
        }
      }
      
      const handleSetCategory2 = (category: string) => {
        if (selectedRequirementCategory2 == "" || selectedRequirementCategory2 != category) {
          setSelectedRequirementCategory2(category);
        } else {
          setSelectedRequirementCategory2("");
          setSelectedRequirementCategory3("");
        }
      }
      
      const handleSetCategory3 = (category: string) => {
        if (selectedRequirementCategory3 == "" || selectedRequirementCategory3 != category) {
          setSelectedRequirementCategory3(category);
        } else {
          setSelectedRequirementCategory3("");
        }
      }

      useEffect(() => { handleFilterRequirements(); }, [selectedRequirementCategory1, selectedRequirementCategory2, selectedRequirementCategory3])

      const handleFilterRequirements = () => {
        // console.log(selectedRequirementCategory)
        if (selectedRequirementCategory1) {
            const filteredRequirements = requirementTemplates.filter((requirement : any) =>
                ((selectedRequirementCategory1 == requirement.category[0]) && 
                !selectedRequirementCategory2 || (selectedRequirementCategory2 == requirement.category[1])) &&
                !selectedRequirementCategory3 || (selectedRequirementCategory3 == requirement.category[2])
            )
            setSelectionRequirementFiltered(filteredRequirements);
        } else {
            setSelectionRequirementFiltered(requirementTemplates);
        }
    }

    const handleCopyRequirementTemplate = (requirementTemplate: any) => {
        setSelectedRequirementTemplate({ requirement: requirementTemplate.requirement, description: requirementTemplate.description, category: requirementTemplate.category });
        setRequirementTemplatesWizardModelOpen(false);
    }
    
    return (
    <FormModal isOpen={requirementTemplatesWizardModelOpen} setOpen={setRequirementTemplatesWizardModelOpen} size='large'>
        <div className=''>
            <h3 className="border-b text-xl font-medium text-gray-900 pb-2">Requirement Template Store <p className='text-sm'>by softwarematch.de©</p></h3>

            <div className="flex max-h-[70vh] border-b gap-0 max-md:flex-wrap w-full h-full max-md:max-w-full">
      
                <div className="max-h-full w-64 flex flex-col font-medium whitespace-nowrap bg-white border-r border-solid basis-0 leading-[150%]">
                    <div className="flex flex-col overflow-y-auto px-3 pt-4 pb-1.5 text-base text-gray-900">

                    {
                        Object.keys(uniqueRequirementCategories).map((category1: string, index: number) => {
                        return (
                            <div>
                            <div onClick={() => handleSetCategory1(category1)} className={`flex flex-col cursor-pointer justify-center px-2 py-1.5 mt-4 w-full ${(selectedRequirementCategory1 == category1) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                            <div className="flex gap-3 justify-between">
                                <div className="w-full truncate">{category1}</div>
                                
                                <div className={`flex items-center ${(selectedRequirementCategory1 == category1) ? ("rotate-180") : ("")}`}><ArrowDownIcon iconColor={(selectedRequirementCategory1 == category1) ? ("white") : ("black")}/></div>
                            </div>
                            </div>
                            {
                            (selectedRequirementCategory1 == category1) ? (
                                <div className={`${(selectedRequirementCategory1 == category1) ? ("bg-gray-50") : ("")} rounded-lg py-1.5`}>
                                <div className={`flex flex-col justify-center pb-1.5 pl-5`}>
                                    
                                    {
                                    Object.keys(uniqueRequirementCategories[selectedRequirementCategory1]).map((category2: string, index: number) => {

                                        return (
                                        <div>
                                            <div onClick={() => handleSetCategory2(category2)}
                                            className={`flex flex-col mt-1.5 justify-center px-2 py-1.5 w-full cursor-pointer ${(selectedRequirementCategory2 == category2) ? ("bg-primary text-white") : ("hover:bg-primary2")} rounded-lg`}>
                                            <div className="flex gap-1 justify-between">
                                                <div className="w-full truncate">{category2}</div>
                                                <div className={`flex items-center ${(selectedRequirementCategory2 == category2) ? ("rotate-180") : ("")}`}><ArrowDownIcon iconColor={(selectedRequirementCategory2 == category2) ? ("white") : ("black")}/></div>
                                            </div>
                                            </div>
                                            {
                                            (selectedRequirementCategory2 == category2) ? (
                                                <div className="flex flex-col gap-y-3 justify-center py-1.5 pl-5 mt-2.5">
                                                {
                                                    uniqueRequirementCategories[selectedRequirementCategory1][selectedRequirementCategory2].map((category3: string[], index: number) => {
                                                    if (category3.length > 0 && !category3[1]) {
                                                        return (
                                                        <div onClick={() => handleSetCategory3(category3[0])}
                                                        className={`cursor-pointer rounded-lg px-2 py-1.5 truncate ${(selectedRequirementCategory3 == category3[0]) ? ("bg-primary text-white ") : ("hover:bg-primary2")}`}>
                                                            {category3[0]}
                                                        </div>
                                                        )
                                                    } else {
                                                        return (
                                                        <div onClick={() => handleSetCategory3(category3.join(", "))}
                                                        className={`cursor-pointer rounded-lg px-2 py-1.5 hover:bg-primary2 truncate ${(selectedRequirementCategory3 == category3.join(", ")) ? ("bg-primary text-white hover:bg-primary") : ("")}`}>
                                                            {category3.join(", ")}
                                                        </div>
                                                        )
                                                    }
                                                    })
                                                }
                                                </div>
                                            ) : (<></>)
                                            }
                                        </div>
                                        )
                                    })
                                    }
                                </div>
                                </div>
                            ) : (<></>)
                            }
                            </div>
                        )
                        })
                    }

                    </div>


                </div>

                <div className="flex flex-col flex-1 w-64">

                    <div className='h-full overflow-y-auto'>

                        <div className="flex gap-0 overflow-auto max-md:flex-wrap w-full h-full max-md:max-w-full">

                            <table className="w-full h-fit relative text-sm text-left text-gray-500">
                            {/* `${selectionRequirement.selectionRequirements_importance} %` */}
                                <TableHeader header={["Title" , "Description", "Copy to selection"]} sticky={true} />
                                {
                                    selectionRequirementFiltered.map((requirementTemplate: any, index: number) => {

                                        return (
                                            <tbody
                                            onClick={() => handleCopyRequirementTemplate(requirementTemplate)}
                                            id={requirementTemplate.id} className={`cursor-pointer opacity-70 hover:opacity-100 transitions-all duration-100`}>
                                                <TableRow maxRowWidths={["","","",""]}
                                                rowItems={[index, requirementTemplate.requirement, "", <div className='flex items-center justify-center'><MainButton text={'Copy'} /></div>]} />
                                            </tbody>
                                        );
                                    })
                                }
                            </table>

                        </div>
                    
                    </div>
                </div>
                <div className='h-12 bg-gray-900'></div>

            </div>

        </div>
    </FormModal>
  )
}

export default RequirementInputWizardModal