import React, { useEffect, useState } from 'react'
import CheckmarkIcon from '../../../../../../../../assets/img/icons/CheckmarkIcon'
import ArrowDownIcon from '../../../../../../../../assets/img/icons/ArrowDownIcon';
import CrossIcon from '../../../../../../../../assets/img/icons/CrossIcon';
import DocumentComment from './DocumentComment';
import { HighlightArea } from '@react-pdf-viewer/highlight';
import PlusIcon from '../../../../../../../../assets/img/icons/PlusIcon';

interface props {
    comments: any[],
    checklistItemData : any,
    jumpToHighlight: (comment: any) => void,
    clickedHighlightComment: any,
    checklistItemTagging: any,
    setChecklistItemTagging: React.Dispatch<React.SetStateAction<any>>
}

const DocumentChecklistItem = ({comments, checklistItemData, jumpToHighlight, clickedHighlightComment, checklistItemTagging, setChecklistItemTagging} : props) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [filteredComments, setFilteredComments] = useState<any[]>();

    useEffect(() => {
        if (typeof clickedHighlightComment !== "undefined" && clickedHighlightComment.checklistItem == checklistItemData.id) { 
            setDetailsOpen(true); 
        } else {
            setDetailsOpen(false); 
        }
    } ,[clickedHighlightComment])

    const filterComments = () => {
        const newFilteredComments = comments.filter((comment:any, index:number) => (comment.checklistItem == checklistItemData.id))
        setFilteredComments(newFilteredComments);
        if (newFilteredComments.length > 0) setChecked(true);
    }

    useEffect(filterComments, [])
    useEffect(filterComments, [comments])

    return (
    <div key={checklistItemData.id}>
        <div onClick={() => setDetailsOpen(prev => !prev)}
        className={`flex items-center space-x-4 p-4 ${detailsOpen ? "bg-gray-100 rounded-t-lg border-b" : "rounded-lg"} hover:bg-gray-100`}>
            <div className={`w-6 h-6 p-1 border-2 ${checked ? "border-green-400" : "border-gray-300"} rounded-full flex items-center justify-center text-gray-300`}>
                { checked ? <CheckmarkIcon /> : "?"}
            </div>
            <div className='truncate'>
                {checklistItemData.title}
            </div>
            { detailsOpen ? <div className='rotate-180'><ArrowDownIcon /></div> : <div className=''><ArrowDownIcon /></div>}
        </div>
        {
            detailsOpen ? (
                <div className='relative flex flex-col rounded-b-lg bg-gray-100 p-4'>
                    <div onClick={() => setDetailsOpen(false)} className='absolute top-0 left-0 w-full h-full'></div>
                    <div>
                        {checklistItemData.details}
                    </div>

                    <div className='flex flex-wrap mt-2'>
                        {
                            typeof filteredComments !== "undefined" ? 
                            filteredComments.map((comment:any, index:any) => {
                                return (
                                    <DocumentComment comment={comment} jumpToHighlight={jumpToHighlight} clickedHighlightComment={clickedHighlightComment} />
                                )
                            }) : <></>
                        }

                        <div onClick={() => setChecklistItemTagging((prev : any) => (prev.id == checklistItemData.id ? {id:undefined, title:undefined} : {id: checklistItemData.id, title: checklistItemData.title}))}
                        className={`flex items-center justify-center space-x-2 rounded-lg z-[20] cursor-pointer shadow border-2 w-full ${checklistItemTagging.id == checklistItemData.id ? "bg-primary" : "bg-gray-300 hover:bg-gray-200"} mb-2 p-2 border-gray-400`}>
                            {
                                checklistItemTagging.id == checklistItemData.id ? (
                                    <div className='text-white text-center'>Select text in document</div>
                                ) : (
                                <>
                                    <PlusIcon iconColor='currentColor' />
                                    <div className='text-gray-700 text-center'>Add tag</div>
                                </>
                                )
                            }
                        </div>

                    </div>
                </div>
            ) : (<></>)
        }
    </div>
  )
}

export default DocumentChecklistItem