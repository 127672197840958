import React, {useRef} from 'react';
import { Link } from "react-router-dom";
import MainButton from '../../../../components/MainButton';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import UserDropdown from './UserDropdown';
import { useTranslation } from 'react-i18next';
import PageLogo from '../../../../components/PageLogo';

const Navbar = () => {
    const { t } = useTranslation();
    const userIsLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
    const userRole = useSelector((state: RootState) => state.user.role);
    const userEmail = useSelector((state: RootState) => state.user.email);

    const nav = useRef<HTMLDivElement>(null);

    const toggleNavMobile = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault(); 
        if (nav.current) {
            nav.current.classList.toggle('active')
        }
    }

    return (
    <header className="bg-white">
        <nav className="fixed top-0 h-20 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
                <div className="flex items-center justify-start rtl:justify-end">
                    {/* <button onClick={() => {setCollapsedSidebar(false); setMobileSidebarOpen(prev => !prev)}}
                    aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button> */}
                    <PageLogo />

                </div>
                
                {
                    (userIsLoggedIn) ? (
                        <UserDropdown email={userEmail} role={userRole} align_right={true} />
                    ) : (
                        <MainButton text={t("signInButton")} text_location='' icon='' icon_color='white' link="/signin" />
                    )
                }

                {/* <div className="navbtn hidden text-sm text-primary font-semibold max-[900px]:block" onClick={toggleNavMobile}>
                    {t("menu")}
                </div> */}
            </div>
            </div>
        </nav>
    </header>
  )
}

export default Navbar;