import { log } from 'console';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableHeader from '../../../../../components/TableHeader';
import TableRow from '../../../../../components/TableRow';
import MainButton from '../../../../../components/MainButton';
import { uploadRequirementDump } from '../../../../../data/SoftwareFulfillmentRequests';
import KeywordLogic from './2_algorithms/requirementConfig/Index';
import { Outlet, useNavigate } from 'react-router-dom';

const MatchingLogicManager = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState("");

    useEffect(() => {
        setSelectedTab("overview");
        navigate("overview");
    }, [])

    const handleNavigate = (tab : string) => {
        if (selectedTab != tab) {
            setSelectedTab(tab);
            navigate(tab);
        }
    }

  return (
    <div>
        <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                {t("matchingLogic")}
            </h1>
        </div>
        <div className="relative overflow-x-auto mb-5">
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px">
                    <li onClick={() => handleNavigate("overview")}
                    className="mr-2 cursor-pointer">
                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(selectedTab == "overview") ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Overview</p>
                    </li>
                    <li onClick={() => handleNavigate("facts")}
                    className="mr-2 cursor-pointer">
                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(selectedTab == "facts") ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Unternehmens-Fakten</p>
                    </li>
                    <li onClick={() => handleNavigate("requirements")}
                    className="mr-2 cursor-pointer">
                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(selectedTab == "requirements") ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Software-Anforderungen</p>
                    </li>
                    <li onClick={() => handleNavigate("settings")}
                    className="mr-2 cursor-pointer">
                        <p className={`inline-block p-4 border-b-2 rounded-t-lg ${(selectedTab == "settings") ? ("text-blue-600 border-blue-600 dark:text-blue-500 dark:border-blue-500") : ("border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300")}`}>Einstellungen</p>
                    </li>
                </ul>
            </div>
        </div>
        <Outlet />
    </div>
  )
}

export default MatchingLogicManager