import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import TableHeader from '../../../../../components/TableHeader';
import TableRow from '../../../../../components/TableRow';
import { fetchManagerData } from '../../../../../data/UserManagerRequests';
import { useTranslation } from 'react-i18next';

const ManagerAdmin = () => {
    const { t } = useTranslation();

    const [adminData, setAdminData] = useState([]);

    const handleFetchManagerData = () => {
        fetchManagerData().then(data => setAdminData(data.data));
    }
  
    useEffect(() => {
        handleFetchManagerData();
    }, [])
  
    if (adminData.length == 0) {
        return (<LoadingSpinner />)
    } else {
        return (
            <div>
                <div className="mb-4 col-span-full xl:mb-2">
                    <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                        {t("platformManagers")}
                    </h1>
                </div>
                {/* Users table */}
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <caption className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                        {t("ourPlatformManagers")}
                        <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("totalItems", {length: adminData.length, itemType: `${t("platformManagers")}`})}</p>
                        </caption>
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <TableHeader header={["E-Mail", `${t("firstname")}`, `${t("lastname")}`, `${t("createdOn")}`]} />
                        </thead>
                        {
                            adminData.map(({email, createdDate} : any, index : number) => {
                                const date : Date = new Date(Date.parse(createdDate));
                                const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                                return (
                                    <tbody>
                                        <TableRow rowItems={[index, email, "Lorem Ipsum", "Lorem Ipsum", dateString]} />
                                    </tbody>
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        )
    }
}

export default ManagerAdmin