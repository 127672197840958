import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteCustomerSearch, exportCustomerSearchResults, getAllCustomerSearches } from '../../../../../../data/CustomerSearchRequests';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import FormModal from '../../../../../../components/FormModal';
import MainButton from '../../../../../../components/MainButton';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';

const CustomerAllSearchsTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const [customerSearches, setCustomerSearches] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const [customerSearchDetailsModalOpen, setCustomerSearchDetailsModalOpen] = useState(false);
    const [selectedCustomerSearch, setSelectedCustomerSearch] = useState<any>(); 
    
    const handleFetchCustomerSearches = async () => {
        const data = await getAllCustomerSearches(); 
        console.log(data)
        setCustomerSearches(data);
        setFilteredData(data);
        setLoaded(true);
    }

    useEffect(() => {
        handleFetchCustomerSearches();
    }, [])

    const handleOpenCustomerSearchDetailsModal = (customerSearchId: string) => {
        const customerSearch : any[] = customerSearches.filter((item : any) => item.id == customerSearchId);
        
        if (customerSearch.length > 0) {
            const searches = [
                ...customerSearch[0].customerSearchCustomerDetails.map((item : any) => ({id: item.customerDetails.company, freeSearch: item.freeSearch})),
                ...customerSearch[0].userCustomerSearch.map((item : any) => ({id: item.email, freeSearch: true}))
            ];
            setSelectedCustomerSearch({id: customerSearch[0].id, queryUrl: customerSearch[0].queryUrl, searches})
            setCustomerSearchDetailsModalOpen(true);
        }

    }

    const handleDeleteCustomerSearch = async (customerSearchId : string) => {
        const success = await deleteCustomerSearch([customerSearchId]);
        if (success) {
            handleFetchCustomerSearches();
            setCustomerSearchDetailsModalOpen(false);
        }
    }

    const handleExportCustomerSearch = async (queryUrl : string) => {
        console.log(queryUrl);
        const results = await exportCustomerSearchResults(queryUrl);
        console.log(results)

        const jsonString = JSON.stringify(results, null, 2);
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `search_results_${queryUrl}.json`;
        a.click();
        URL.revokeObjectURL(url);
    }

    return (
    <>

    {customerSearchDetailsModalOpen ? (
        <FormModal isOpen={customerSearchDetailsModalOpen} setOpen={setCustomerSearchDetailsModalOpen} size="regular">
            <div>
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">{t("selections")}</h3>
                <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                    <div className='flex items-center'>
                        <div className='w-full'>
                            <label className="block text-sm font-medium text-gray-900 dark:text-white">{t("queryUrl")}</label>
                            <div className='flex justify-center space-x-2'>
                                <input type="text" name="input" value={selectedCustomerSearch.queryUrl} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" disabled></input>
                                <div onClick={() => {window.location.href = `${process.env.REACT_APP_LANDING_PAGE}/search?url=${selectedCustomerSearch.queryUrl}`}}><MainButton text={t("results")} link='#' icon={<ArrowRightIcon />}/></div>
                            </div>
                        </div>
                    </div>
                    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
                        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                            {t("customers")}
                            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Die folgenden Kunden haben nach dieser URL gesucht.</p>
                        </div>
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <TableHeader header={[`${t("companyName")}/email`, `${t("freeSearch")}`]} />
                            </thead>
                            {
                                selectedCustomerSearch.searches.map(({id, freeSearch} : any, index : number) => {
                                    return (
                                        <tbody>
                                            <TableRow
                                                rowItems={[`${index}`, id, `${freeSearch ? "1": "0"}`]}
                                            />
                                        </tbody>
                                    )
                                })
                            }
                        </table>
                    </div>
                    <div className='mt-4'>
                        <MainButton text={t('delete')} style='light' func={() => handleDeleteCustomerSearch(selectedCustomerSearch.id)} />
                    </div>
                    <div className='mt-4'>
                        <div onClick={() => handleExportCustomerSearch(selectedCustomerSearch.queryUrl)}><MainButton text={t("Export")} link="" style='light' /></div>
                    </div>
                </div>
            </div>
        </FormModal>
    ) : (<></>)}


    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {t("selections")}
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Sehen Sie sich Ihre Software-Suchen an.</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
        >
            <div className="w-full mb-1">
                <div className="items-center justify-between h-[40px] block sm:flex">
                    <div className='sm:w-64 xl:w-96'>
                        <SearchBar translatedPlaceholderItem={t(`queryUrl`)} originalData={customerSearches} setFilteredData={setFilteredData} filterKey={"queryUrl"} />
                    </div>
                    
                    <div className="flex items-center h-full ml-auto space-x-2 sm:space-x-3">
                        <button
                        onClick={() => handleFetchCustomerSearches()}
                        type="button"
                        data-refresh
                        className="inline-flex items-center justify-center h-full w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                        <RefreshIcon iconColor='white' />
                        {t("refresh")}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <TableHeader header={[`${t("queryUrl")}`, `${t("numSearches")}`, `${t("numFreeSearches")}`, `${t("FirstSearch")}`]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={4} className='h-16'>No customer searches available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, queryUrl, customerSearchCustomerDetails, createdDate} : any, index : number) => {
                            const createdDateParsed : Date = new Date(Date.parse(createdDate));
                            const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
                            const numFreeSearch = customerSearchCustomerDetails.filter((item : any) => item.freeSearch == true).length;
                            return (
                                <tbody className='cursor-pointer' onClick={() => {handleOpenCustomerSearchDetailsModal(id)}}>
                                    <TableRow
                                        rowItems={[`${index}`, queryUrl, customerSearchCustomerDetails.length, String(numFreeSearch), createdDateString]}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            <NavPagination totalItems={customerSearches.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
        )
        }
    </div>
    </>
  )
}

export default CustomerAllSearchsTable