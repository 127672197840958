import React, { useState } from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from 'swap-frontend-library';

// const phases: Phase[] = [
//     { name: 'Requirements phase', startDate: new Date((new Date(new Date().setDate(today.getDate() - 10))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 16))).setHours(0,0,0,0)) },
//     { name: 'Scenario phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 17))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 42))).setHours(0,0,0,0)) },
//     { name: 'Presentation phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 43))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 56))).setHours(0,0,0,0)) },
//     { name: 'Contract phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 57))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 72))).setHours(0,0,0,0)) },
// ];

interface props {
    phaseDates?: any[],
    phaseColors?: string[];
    getPhaseForDate?: (d:any) => any;
    isStartOfPhase?: (d:any) => boolean;
    isEndOfPhase?: (d:any) => boolean;
    selectedDate?: Date | null;
    setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
}

const Calendar = ({phaseDates, phaseColors, getPhaseForDate, isStartOfPhase, isEndOfPhase, selectedDate, setSelectedDate} : props) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const today = new Date();

    const handlePreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const handleNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const handleToday = () => {
        setCurrentDate(new Date());
    };

    const getDaysInMonth = (month: number, year: number): number => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getStartDayOfMonth = (month: number, year: number): number => {
        return new Date(year, month, 1).getDay();
    };

    const getEndDayOfMonth = (month: number, year: number): number => {
        return new Date(year, month, getDaysInMonth(month, year)).getDay();
    };

    const daysInMonth = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    const startDay = getStartDayOfMonth(currentDate.getMonth(), currentDate.getFullYear());
    const endDay = getEndDayOfMonth(currentDate.getMonth(), currentDate.getFullYear());

    const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    const prevMonthDays = startDay > 0 ? getDaysInMonth(currentDate.getMonth() - 1, currentDate.getFullYear()) : 0;
    const prevMonthStartArray = Array.from({ length: startDay }, (_, i) => prevMonthDays - startDay + i + 1);

    const nextMonthEndArray = Array.from({ length: 6 - endDay }, (_, i) => i + 1);

  return (
    <div>
        {/* <div className="flex flex-col grow self-stretch px-8 pt-8 w-full bg-white rounded-lg shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full"></div> */}
      
        <div className="flex justify-between items-center mb-2 mt-4">
            <button onClick={handlePreviousMonth} className="w-6 h-6 p-2 text-gray-900 hover:bg-gray-100 rounded"><ArrowLeftIcon /></button>
            <h2 className="text-xl font-bold cursor-pointer p-2 rounded hover:bg-gray-100" onClick={handleToday}>{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
            {/* <button onClick={handleToday} className="px-3 py-2 hover:bg-gray-100 rounded">Today</button> */}
            <button onClick={handleNextMonth} className="w-6 h-6 p-2 text-gray-900 hover:bg-gray-100 rounded"><ArrowRightIcon /></button>
        </div>

        <div className='h-80'>
            <div className="grid grid-cols-7 gap-y-2 text-center">
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                <div key={day} className="font-semibold text-gray-500">
                    {day}
                </div>
                ))}
                
                {
                    prevMonthStartArray.map((day) => {
                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth()-1, day);
                        const phase = typeof getPhaseForDate !== "undefined" ? getPhaseForDate(currentDay) : undefined;
                        const phaseIndex = typeof phaseDates !== "undefined" ? phaseDates.indexOf(phase!) : undefined;
                        const isStart = typeof isStartOfPhase !== "undefined" ? isStartOfPhase(currentDay) : undefined;
                        const isEnd = typeof isEndOfPhase !== "undefined" ?  isEndOfPhase(currentDay) : undefined;

                        return (
                            <div onClick={handlePreviousMonth} key={`prev-${day}`} className={`p-2 text-gray-400 cursor-pointer ${phase && typeof phaseColors != "undefined" && typeof phaseIndex !== "undefined" ? phaseColors[phaseIndex] : ""} ${isStart ? 'rounded-l-lg' : ''} ${isEnd ? 'rounded-r-lg' : ''}`}>
                                {day}
                            </div>
                        )
                    }
                    )
                }

                {
                    daysArray.map((day) => {
                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
                        const phase = typeof getPhaseForDate !== "undefined" ? getPhaseForDate(currentDay) : undefined;
                        const phaseIndex = typeof phaseDates !== "undefined" ? phaseDates.indexOf(phase!) : undefined;
                        const isStart = typeof isStartOfPhase !== "undefined" ? isStartOfPhase(currentDay) : undefined;
                        const isEnd = typeof isEndOfPhase !== "undefined" ?  isEndOfPhase(currentDay) : undefined;

                        const isSelected = currentDay.getDate() === selectedDate?.getDate() && currentDate.getMonth() === selectedDate?.getMonth() && currentDate.getFullYear() === selectedDate?.getFullYear();

                        return (
                            <div key={day} onClick={() => {if (typeof setSelectedDate !== "undefined") setSelectedDate(currentDay) }} className={`p-2 relative ${typeof setSelectedDate !== "undefined" ? "cursor-pointer hover:bg-gray-50 rounded-full border-2" : ""} ${isSelected ? "border-primary" : "border-white"} ${phase && typeof phaseColors != "undefined" && typeof phaseIndex !== "undefined" ? phaseColors[phaseIndex] : ""} ${isStart ? 'rounded-l-lg' : ''} ${isEnd ? 'rounded-r-lg' : ''}`}>
                                {(day === today.getDate() && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear()) ? <div className={`absolute top-0 left-0 bg-primary rounded-full w-full h-full flex items-center justify-center text-white font-bold`}>{day}</div> : <>{day}</>}
                            </div>
                        );
                    })
                }

                {
                    nextMonthEndArray.map((day) => {
                        const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, day);
                        const phase = typeof getPhaseForDate !== "undefined" ? getPhaseForDate(currentDay) : undefined;
                        const phaseIndex = typeof phaseDates !== "undefined" ? phaseDates.indexOf(phase!) : undefined;
                        const isStart = typeof isStartOfPhase !== "undefined" ? isStartOfPhase(currentDay) : undefined;
                        const isEnd = typeof isEndOfPhase !== "undefined" ?  isEndOfPhase(currentDay) : undefined;

                        return (
                            <div onClick={handleNextMonth} key={`next-${day}`} className={`p-2 text-gray-400 cursor-pointer ${phase && typeof phaseColors != "undefined" && typeof phaseIndex !== "undefined" ? phaseColors[phaseIndex] : ""} ${isStart ? 'rounded-l-lg' : ''} ${isEnd ? 'rounded-r-lg' : ''}`}>
                                {day}
                            </div>
                        )
                    })
                }
            </div>
        </div>

        {
            typeof phaseDates !== "undefined" && typeof phaseColors !== "undefined" ? (
                <div className="mt-4">
                    <div className="grid grid-cols-2 gap-4">
                    {phaseDates.map((phase, index) => (
                        <div key={phase.name} className="flex items-center gap-4 w-1/2">
                            <div><div className={`w-4 h-4 ${phaseColors[index]} rounded-full`}></div></div>
                            <span className='text-sm'>{phase.name}</span>
                        </div>
                    ))}
                    </div>
                </div>
            ) : (<></>)
        }
    </div>
  );
};

export default Calendar;
