export async function fetchManagerData() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-managers`, options);
        if (res.ok) {
            const data = res.json();
            return data;
        } else {
            console.error(`fetchCustomerData: Bad response ${res.status}`);
        }

    } catch(error) {
        console.error(`fetchManagerData: Error occurred ${error}`);
    }
}