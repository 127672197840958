import React, { useEffect, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import MainButton from '../../../../../components/MainButton'
import HorizontalNavigationBalls from '../../../../../components/HorizontalNavigationBalls'
import { useTranslation } from 'react-i18next'

const Index = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [productNamesAndIds, setProductNamesAndIds] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");

  const handleActivePage = () => {
    switch (location.pathname) {
      case "/onboarding/create-account":
        setActivePage(2);
        break;
        case "/onboarding/create-product":
          setActivePage(3);
          break;
        case "/onboarding/next-steps":
          setActivePage(4);
          break;
      default:
        setActivePage(1);
    }
  }
  useEffect(handleActivePage, [location])

  return (
    <div className='relative'>
      <div className='max-sm:overflow-x-auto max-sm:flex p-5 md:fixed md:top-[10%] md:left-[5%]'>
        {/* // TODO Links von NavigationBalls conditionally entfernen */}
        <HorizontalNavigationBalls number={1} text={`${t("welcome")}!`} link={location.pathname} activePage={activePage} />
        <HorizontalNavigationBalls number={2} text={`${t("createAccount")}`} link={location.pathname} activePage={activePage} />
        <HorizontalNavigationBalls number={3} text={`${t("updateProduct")}`} link={location.pathname} activePage={activePage} />
        {
          (productNamesAndIds.length > 0) ? (
            productNamesAndIds.map((obj: any, index:number) => (<p className={
              `pl-16 text-base max-md:text-sm max-sm:text-xs max-sm:hidden ${(selectedProductId == obj.id) ? ("text-primary font-semibold") : ("text-secondary4 font-medium")}`
            }>{obj.productName}</p>))
          ) : (
            <></>
          )
        }
        <HorizontalNavigationBalls number={4} text={`${t("nextSteps")}`} link={location.pathname} activePage={activePage} />
      </div>
      <div className='w-5/6 md:w-1/2 mx-auto p-5'>
        <Outlet context={[setProductNamesAndIds, selectedProductId, setSelectedProductId]} />
      </div>
    </div>
  )
}

export default Index