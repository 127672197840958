import React from 'react'
import MainButton from '../../../../../../components/MainButton'
import { useTranslation } from 'react-i18next'
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';

const OnboardingFinished = () => {
    const {t} = useTranslation();
  return (
    <div>
        <h1 className="text-5xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">{t("onboardingFinishedHeadline")}</h1>

        <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">
            {t('onboardingFinished')}
        </p>
        
        <div className='grid grid-cols-3 gap-4 p-3'>
            <img src='/img/client-specific/logo_gito-events.png'/>
            <img src='/img/client-specific/logo_erp-management.png'/>
            <img src='/img/client-specific/logo_center-for-enterprise-research.jpg'/>
        </div>
        
        <div className="mt-10">
            <MainButton text={`${t("goToDashboard")}`} text_location='left' icon={<ArrowRightIcon iconColor='white' />} icon_color='' link="/user-area" />
          </div>
    </div>
  )
}

export default OnboardingFinished