import React, { useState } from 'react';
import Chart from "react-apexcharts";

interface props {
    subTitle: string;
    title: string;
    labels: string[]
    data: number[]
    colors?: string[]
}

const BigRingChartFilter = ({subTitle, title, labels, colors, data} : props) => {
    const [options, setOptions] = useState({
        labels: labels,
        colors: colors || ['#16BDCA', '#FDBA8C', '#1A56DB'],
        responsive: [
          {
            breakpoint: 430,
            options: {
              chart: {
                height: 300,
              },
            },
          },
        ],
        stroke: {
          colors: ["white"],
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.9,
            },
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        });
    const [series, setSeries] = useState(data);

  return (
        <div>
          <div
            className="flex items-center justify-between pb-4 mb-4 border-b border-gray-200 dark:border-gray-700"
          >
            <div>
              <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">
                {subTitle}
              </h3>
              <span className="text-md font-bold leading-none text-gray-900 sm:text-xl dark:text-white">
                {title}
              </span>
            </div>
            {/* <a
              href="#"
              className="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100 dark:text-primary-500 dark:hover:bg-gray-700"
            >
              Full report
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                ><path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"></path></svg
              >
            </a> */}
          </div>
          <div id="traffic-by-device">
          <Chart 
            type='donut'
            options={options}
            series={series}
        />
          </div>
          {/* <!-- Card Footer --> */}
          <div className='flex justify-center items-center w-full'>
            <div className="grid grid-cols-2 gap-8 pt-4 lg:justify-evenly sm:pt-6">
              {
                data.map((item:any, index:number) => {
                  return (
                  <div>
                    <h3 className="text-gray-500 dark:text-gray-400">{labels[index]}</h3>
                    <h4 className="text-xl font-bold dark:text-white">{data[index]}</h4>
                    <p className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                      <span className="flex items-center mr-1.5 text-sm text-green-500 dark:text-green-400">
                        <svg
                          className="w-4 h-4"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path
                            clip-rule="evenodd"
                            fill-rule="evenodd"
                            d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                          ></path>
                        </svg>
                        4%
                      </span>
                      vs last month
                    </p>
                  </div>

                  )
                })
              }
            </div>
          </div>
        </div>
  )
}

export default BigRingChartFilter