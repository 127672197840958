import React, { useState } from 'react';
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon';
import ArrowLeftIcon from '../../../../../../assets/img/icons/ArrowLeftIcon';
import Calendar from '../../../../../../components/CalendarWidget';

interface Phase {
  name: string;
  startDate: Date;
  endDate: Date;
}

const ProjectPhasesWidget: React.FC = () => {
    const today = new Date();
    
    const phaseColors = ['bg-lime-200', 'bg-amber-200', 'bg-cyan-200', 'bg-violet-200'];
    const phases: Phase[] = [
        { name: 'Requirements phase', startDate: new Date((new Date(new Date().setDate(today.getDate() - 10))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 16))).setHours(0,0,0,0)) },
        { name: 'Scenario phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 17))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 42))).setHours(0,0,0,0)) },
        { name: 'Presentation phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 43))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 56))).setHours(0,0,0,0)) },
        { name: 'Contract phase', startDate: new Date((new Date(new Date().setDate(today.getDate() + 57))).setHours(0,0,0,0)), endDate: new Date((new Date(new Date().setDate(today.getDate() + 72))).setHours(0,0,0,0)) },
    ];

    const isDateInPhase = (date: Date, phase: Phase): boolean => {
        return date >= phase.startDate && date <= phase.endDate;
    };

    const getPhaseForDate = (date: Date): Phase | undefined => {
        return phases.find(phase => isDateInPhase(date, phase));
    };

    const isStartOfPhase = (date: Date): boolean => {
        return phases.some(phase => phase.startDate.toDateString() === date.toDateString());
    };

    const isEndOfPhase = (date: Date): boolean => {
        return phases.some(phase => phase.endDate.toDateString() === date.toDateString());
    };

    return (
        <div className="h-full w-full mx-auto bg-white rounded-lg shadow-sm px-8 py-8">
            <div className="flex gap-3.5 justify-between max-md:flex-wrap max-md:max-w-full">
                <div className="grow text-2xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                    Schedule
                </div>
                <div className="my-auto text-base font-medium leading-6 text-blue-700 whitespace-nowrap">
                    View all
                </div>
            </div>
            <Calendar phaseDates={phases} phaseColors={phaseColors} getPhaseForDate={getPhaseForDate} isStartOfPhase={isStartOfPhase} isEndOfPhase={isEndOfPhase} />
        </div>
    );
};

export default ProjectPhasesWidget;
