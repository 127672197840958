import React from 'react';
import { AnalysisIcon, RocketIcon, BriefcaseIcon, HeadsetIcon, CakeChartIcon, SearchIcon, SettingsFilledIcon } from 'swap-frontend-library';

const FEATURE_HIGHLIGHTS = [
    { name: "Report", description: "Create a report based on your searches", icon: <div className='h-20'><AnalysisIcon /></div> },
    { name: "ROI Calculator", description: "Return on Investment compass", icon: <div className='h-20'><RocketIcon /></div> },
    { name: "Software benchmarking", description: "Compare your current software", icon: <div className='h-20 rotate-90'><SettingsFilledIcon /></div> },
    { name: "Market insights", description: "Explore software market updates", icon: <div className='h-20'><BriefcaseIcon /></div> },
    { name: "Consulantancy", description: "Benefit from the expertise of our proficient advisors", icon: <div className='h-20'><HeadsetIcon /></div> },
]

const FeatureHighlightsWidget = () => {
  return (
    <div className="h-full flex flex-col grow self-stretch px-8 py-8 w-full bg-white rounded-lg shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">
        
        <div className="flex gap-3.5 mb-4 justify-between max-md:flex-wrap max-md:max-w-full">
            <div className="grow text-2xl font-bold tracking-tight leading-9 text-gray-900 max-md:max-w-full">
                More features
            </div>
            <div className="my-auto text-base font-medium leading-6 text-blue-700 whitespace-nowrap">
                View all
            </div>
        </div>

        <div className='flex flex-wrap gap-4'>
            {
                FEATURE_HIGHLIGHTS.map((item:any, index:number) => (
                    <div key={index} className='cursor-pointer px-6 shadow rounded-lg group w-64 h-72 flex flex-col items-center text-center'>
                        <div className='h-36 flex items-center text-gray-500 group-hover:text-primary'>{item.icon}</div>
                        <p className='font-semibold text-2xl group-hover:text-primary'>{item.name}</p>
                        <p className='font-light text-gray-400'>{item.description}</p>
                        
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default FeatureHighlightsWidget