import React from 'react';
import LoadingSpinnerIcon from '../assets/img/icons/LoadingSpinngerIcon';

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div className='w-[40px]'>
        <LoadingSpinnerIcon />
      </div>
    </div>
  )
}

export default LoadingSpinner