import React, { useEffect, useState } from 'react'
import PercentageCircle from '../../../../../../components/PercentageCircle'
import { getImage } from '../../../../../../data/ProductRequests';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { Link } from 'react-router-dom';

interface props {
    vendorData: any[],
    dragStart: (a:any, b:any) => void,
    draggable: boolean,
    setSidebarOpenWithRankingData: React.Dispatch<React.SetStateAction<any | undefined>>;
}

const TopVendorsPodium = ({vendorData, dragStart, draggable, setSidebarOpenWithRankingData} : props) => {
    // Force rerender
    // const [top3data, setTop3data] = useState<any[]>();
    const [imageSource1, setImageSource1] = useState("");
    const [imageSource2, setImageSource2] = useState("");
    const [imageSource3, setImageSource3] = useState("");
    
    const handleFetchImage = async () => {
        getImage(vendorData[0].softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {setImageSource1(frontendImageLocation);});
        getImage(vendorData[1].softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {setImageSource2(frontendImageLocation);});
        getImage(vendorData[2].softwareProduct.id, "/api/product/get-logo?id=", '').then(frontendImageLocation => {setImageSource3(frontendImageLocation);});
    }

    useEffect(() => {
        handleFetchImage();
        // setTop3data(vendorData)
    }, [vendorData])

  return (
    <div className='flex space-x-4 my-8 items-end'>
        {
            (typeof vendorData === "undefined") ? (<LoadingSpinner />) : (
                <>
                    <div draggable={draggable} onDragStart={(e) => dragStart(e, vendorData[1].id)} onClick={() => setSidebarOpenWithRankingData(vendorData[1])}
                    className={`relative p-6 flex flex-col items-center pt-10 cursor-pointer rounded-lg shadow border-4 w-1/3 border-[#BEC2CB] bg-white hover:bg-gray-50`}>
                        <div className='absolute -top-10 w-full flex items-center justify-center'>
                            <div className='flex items-center justify-center text-center text-4xl text-[#6e787e] font-black italic rounded-full h-20 w-20 bg-[#BEC2CB] border-8 border-gray-50'>2</div>
                        </div>
            
                        {/* Logo */}
                        <div className='h-full w-[150px] flex items-center justify-center max-sm:hidden'>
                            {
                                (imageSource2 == "") ? (
                                    <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                                        {vendorData[2].softwareProduct.vendorDetails.company}
                                    </div>
                                ) : (
                                    <img className="max-h-full" src={imageSource2} />
                                )
                            }
                        </div>
            
                        {/* Vendor/System name */}
                        <div className='mt-8 flex max-sm:max-w-1/2 items-center h-full'>
                            <div className='text-center'>
                                <div className='flex max-xl:flex-col 2xl:space-x-4 text-lg font-bold'>
                                    <p>{vendorData[1].softwareProduct.productName} </p>
                                    {/* <p className='font-light italic'>{vendorData[1].softwareFulfillment.note}</p> */}
                                </div>
                                <div>
                                {vendorData[1].softwareProduct.vendorDetails.company}
                                </div>
                            </div>
                        </div>
            
                        {/* Scores */}
                        <div className='mt-8 grid grid-cols-2 2xl:grid-cols-4 w-full'>
                            <div className='px-2 space-y-4 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Preselection
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[1].score_preselection} />
                                    {(vendorData[1].score_preselection == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Requirements
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[1].score_requirements} />
                                    {(vendorData[1].score_requirements == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='2xl:border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Webcasts
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[1].score_webcast} />
                                    {(vendorData[1].score_webcast == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs w-full truncate'>
                                    Presentations
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[1].score_presentation} />
                                {(vendorData[1].score_presentation == null) ? (
                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                        </div>
            
                    </div>

                    <div draggable={draggable} onDragStart={(e) => dragStart(e, vendorData[0].id)} onClick={() => setSidebarOpenWithRankingData(vendorData[0])}
                    className={`relative p-6 flex flex-col items-center pt-32 cursor-pointer rounded-lg shadow border-4 w-1/3 border-[#ffd700] bg-white hover:bg-gray-50`}>
                        <div className='absolute -top-12 w-full flex items-center justify-center'>
                            <div className='flex items-center justify-center text-center text-5xl text-[#AE8625] font-black italic rounded-full h-24 w-24 bg-[#ffd700] border-8 border-gray-50'>1</div>
                        </div>
            
                        {/* Logo */}
                        <div className='h-full w-[150px] flex items-center justify-center max-sm:hidden'>
                            {
                                (imageSource2 == "") ? (
                                    <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                                        {vendorData[0].softwareProduct.vendorDetails.company}
                                    </div>
                                ) : (
                                    <img className="max-h-full" src={imageSource1} />
                                )
                            }
                        </div>
            
                        {/* Vendor/System name */}
                        <div className='mt-8 flex max-sm:max-w-1/2 items-center h-full'>
                            <div className='text-center'>
                                <div className='flex max-xl:flex-col 2xl:space-x-4 text-lg font-bold'>
                                    <p>{vendorData[0].softwareProduct.productName} </p>
                                    {/* <p className='font-light italic'>{vendorData[0].softwareFulfillment.note}</p> */}
                                </div>
                                <div>
                                {vendorData[0].softwareProduct.vendorDetails.company}
                                </div>
                            </div>
                        </div>
            
                        {/* Scores */}
                        <div className='mt-8 grid grid-cols-2 2xl:grid-cols-4 w-full'>
                            <div className='px-2 space-y-4 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Preselection
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[0].score_preselection} />
                                    {(vendorData[0].score_preselection == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Requirements
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[0].score_requirements} />
                                    {(vendorData[0].score_requirements == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='2xl:border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Webcasts
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[0].score_webcast} />
                                    {(vendorData[0].score_webcast == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs w-full truncate'>
                                    Presentations
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[0].score_presentation} />
                                {(vendorData[0].score_presentation == null) ? (
                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div draggable={draggable} onDragStart={(e) => dragStart(e, vendorData[2].id)} onClick={() => setSidebarOpenWithRankingData(vendorData[2])}
                    className={`relative p-6 flex flex-col items-center pt-5 cursor-pointer rounded-lg shadow border-4 w-1/3 border-[#CC8E34] bg-white pt-16 hover:bg-gray-50`}>
                        <div className='absolute -top-8 w-full flex items-center justify-center'>
                            <div className='flex items-center justify-center text-center text-3xl text-[#6E4D25] font-black italic rounded-full h-16 w-16 bg-[#CC8E34] border-8 border-gray-50'>3</div>
                        </div>
            
                        {/* Logo */}
                        <div className='h-full w-[150px] flex items-center justify-center max-sm:hidden'>
                            {
                                (imageSource2 == "") ? (
                                    <div className='h-full w-full p-4 flex items-center text-center justify-center font-bold text-white bg-gray-300 rounded'>
                                        {vendorData[2].softwareProduct.vendorDetails.company}
                                    </div>
                                ) : (
                                    <img className="max-h-full" src={imageSource3} />
                                )
                            }
                        </div>
            
                        {/* Vendor/System name */}
                        <div className='mt-8 flex max-sm:max-w-1/2 items-center h-full'>
                            <div className='text-center'>
                                <div className='flex max-xl:flex-col 2xl:space-x-4 text-lg font-bold'>
                                    <p>{vendorData[2].softwareProduct.productName} </p>
                                    {/* <p className='font-light italic'>{vendorData[2].softwareFulfillment.note}</p> */}
                                </div>
                                <div>
                                {vendorData[2].softwareProduct.vendorDetails.company}
                                </div>
                            </div>
                        </div>
            
                        {/* Scores */}
                        <div className='mt-8 grid grid-cols-2 2xl:grid-cols-4 w-full'>
                            <div className='px-2 space-y-4 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Preselection
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[2].score_preselection} />
                                    {(vendorData[2].score_preselection == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Requirements
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[2].score_requirements} />
                                    {(vendorData[2].score_requirements == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='2xl:border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs text-center w-full truncate'>
                                    Webcasts
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[2].score_webcast} />
                                    {(vendorData[2].score_webcast == null) ? (
                                        <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                            
                            <div className='border-l space-y-4 px-2 flex flex-col items-center'>
                                <div className='max-sm:text-xs w-full truncate'>
                                    Presentations
                                </div>
                                <div className='flex grow justify-center items-center relative'>
                                    <PercentageCircle rating={vendorData[2].score_presentation} />
                                {(vendorData[2].score_presentation == null) ? (
                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                ) : (<></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
        )}
    </div>
  )
}

export default TopVendorsPodium