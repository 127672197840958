import React from 'react';
import { IconProps } from './IconProps';

const CheckmarkIcon = (props : IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke={props.iconColor || "green"} className="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
  )
}

export default CheckmarkIcon