import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { uploadRequirementDump } from '../../../../../../../../data/SoftwareFulfillmentRequests';
import MainButton from '../../../../../../../../components/MainButton';
import UploadIcon from '../../../../../../../../assets/img/icons/UploadIcon';
import LargeUploadIcon from '../../../../../../../../assets/img/icons/LargeUploadIcon';
import DropzoneUpload from '../../../../../../../../components/DropzoneUpload';

const UploadRequirements = () => {
    const { t } = useTranslation();
    const [ filename, setFilename ] = useState("");
    const [ uploadedFile, setUploadedFile ] = useState<string | ArrayBuffer | null>();
    const [ uploadedJson, setUploadedJson ] = useState<any[]>();
    const [ renderUploadedJson, setRenderUploadedJson ] = useState(true);
    const [ dragActive, setDragActive ] = useState(false);
    const [ uploadError, setUploadError ] = useState(false);

    useEffect(() => {
        convertJson();
    }, [uploadedFile])

    const convertJson = async () => {
        if (typeof uploadedFile === "string") {
            const convertedFile = await JSON.parse(uploadedFile);
            setUploadedJson(convertedFile);
        }
    }

    const handleUploadRequirements = async () => {
        if (uploadedJson) {
            if (await uploadRequirementDump(uploadedJson)) {
                handleRemoveFile();
            } else {
                setUploadError(true);
            }
        }
    }

    const handleRemoveFile = () => {
        setUploadedFile(undefined);
        setUploadedJson(undefined);
        setRenderUploadedJson(false);
        setFilename("");
        setUploadError(false);
    }

  return (
    <>
        {
            (!uploadedJson) ? (
                <>
                <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                    {t("uploadRequirementsTitle")}
                    <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("uploadRequirementDescriptionText")}</p>
                </div>
                <div className="p-5">
                    <DropzoneUpload setUploadedFile={setUploadedFile} fileType={'JSON'} maxFileSize={'50 mb'} />
                </div>
                </>
                ) : (
                <div>
                    <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                        <div className='flex'>
                            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("uploadFilenameRecognized", {filename: filename})}</p>
                            <a href="#" onClick={() => (handleRemoveFile())}
                            className="pl-2 mt-1 text-sm font-normal">{t("remove")}</a>
                        </div>
                        <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{(uploadedJson.length > 0) ? (t("uploadIdentifiedRequirements", {identifiedRequirements: uploadedJson.length})) : (t("uploadNotRecognized"))}</p>
                    </div>
                    <div className='flex'>
                        {/* <div className='p-5'
                        onClick={() => setRenderUploadedJson(prev => !prev)}>
                            <MainButton text={'Show detected requirements'} icon={''} link={''} />
                        </div> */}
                        {
                            (uploadedJson.length > 0) ? (
                                (uploadError) ? (<p className='p-5'>{t("uploadRequirementsError")}</p>) : (    
                                    <div className='p-5'
                                    onClick={() => handleUploadRequirements()}>
                                        <MainButton text={t("upload")} icon={''} link={''} />
                                    </div>
                                )
                            ) : (<></>)
                        }
                    </div>
                </div>
            )
        }
    </>
  )
}

export default UploadRequirements