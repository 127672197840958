import React, { useEffect, useState } from 'react'
import FormModal from '../../../../../components/FormModal'
import MainButton from '../../../../../components/MainButton';
import SelectionStart from './features/SelectionStart';
import RequirementsSubmitted from './features/RequirementsSubmitted';
import RequirementsFinished from './features/RequirementsFinished';
import ScenariosFinished from './features/ScenariosFinished';
import PresentationsFinished from './features/PresentationsFinished';
import SelectionFinish from './features/SelectionFinished';
import RequirementsValidated from './features/RequirementsValidated';

interface props {
  selectionPhaseTaskCode: string
}

const SelectionPhaseModal = ({selectionPhaseTaskCode} : props) => {
  const [ isOpen, setOpen ] = useState(true);
  const [ phaseModalContent, setPhaseModalContent ] = useState<any>();

  useEffect(() => {
    switch (selectionPhaseTaskCode) {
      case ("selection-start"): setPhaseModalContent(<SelectionStart setOpen={setOpen} />); break;
      case ("requ-vali_reqs"): setPhaseModalContent(<RequirementsValidated setOpen={setOpen} />); break;
      case ("requ-subm_reqs"): setPhaseModalContent(<RequirementsSubmitted setOpen={setOpen} />); break;
      case ("requ-revi_reqs"): setPhaseModalContent(<RequirementsFinished setOpen={setOpen} />); break;
      case ("scen-subm_scen"): setPhaseModalContent(<ScenariosFinished setOpen={setOpen} />); break;
      case ("pres-gene_shor"): setPhaseModalContent(<PresentationsFinished setOpen={setOpen} />); break;
      case ("cont-nego_prop"): setPhaseModalContent(<SelectionFinish setOpen={setOpen} />); break;
      default: setOpen(false);
    }
  }, [])


  if (isOpen) {
    return (
      <FormModal isOpen={isOpen} setOpen={undefined} additionalStyles='border-4 border-amber-400 shadow-amber-200' overlayStyles='bg-amber-100 opacity-30' >
          {phaseModalContent}
      </FormModal>
    )
  } else {
    return <></>
  }
}

export default SelectionPhaseModal