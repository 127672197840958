import React from 'react';
import { IconProps } from './IconProps';

const RefreshIcon = (props : IconProps) => {
  return (
    <svg
      className={`${props.iconWidth || "w-5"} ${props.iconHeight || "h-5"}`}
      fill="none"
      stroke={props.iconColor}
      stroke-width="1.5"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        ></path>
    </svg>
  )
}

export default RefreshIcon