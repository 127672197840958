import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectUserArea = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/user-area")
    }, [])

  return (
    <div></div>
  )
}

export default RedirectUserArea