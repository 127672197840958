import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton'
import ArrowRightIcon from '../../../../../../assets/img/icons/ArrowRightIcon'
import { getSelectionPhase } from '../../../../../../data/SelectionPortalRequests'
import LoadingSpinner from '../../../../../../components/LoadingSpinner'

interface props {
  selectionId: string
  isNotCockpit?: boolean
}

export const SELECTION_PHASE_TASK_ROUTES = {
  "requirements": "requirement-manager",
  "scenarios": "scenario-manager",
  "presentations": "presentation-manager",
  "contracts": "contract-manager"
}

const NextStepsWidget = ({selectionId, isNotCockpit} : props) => {
  const [selectionTask, setSelectionTask] = useState<any | null>();

  const handleFetchSelectionPhase = async () => {
    
    const response = await getSelectionPhase(selectionId);
    console.log(response)
    if (response.ok) {
      if (typeof response.data.data.selectionSelectionPhaseTask !== "undefined") {
        console.log(response.data.data.selectionSelectionPhaseTask);
        setSelectionTask(response.data.data.selectionSelectionPhaseTask[0].selectionPhaseTask);
      } else {
        setSelectionTask(null);
      }
    };
  }

  useEffect(() => {handleFetchSelectionPhase()},[])

  return (
    <div className="flex flex-col px-5 py-5 bg-primary2 border border-primary rounded-lg shadow w-full h-full">
      <h3 className="text-base font-normal text-gray-500 dark:text-gray-400">
        Next selection task
      </h3>
      
      { typeof selectionTask === "undefined" ? <LoadingSpinner /> : (
        <div> 
          {
            selectionTask == null ? (
              <>
                <div className="flex mt-2 space-x-4 justify-between items-center w-full text-md text-gray-900">
                  <p>No new task available currently...</p>
                </div>
              </>
            ) : (
              <>
                <div>
                  <span className="text-md font-bold leading-none text-gray-900 sm:text-xl dark:text-white">
                    {selectionTask.title}
                  </span>
                </div>
                
                <div className="flex mt-2 space-x-4 justify-between items-center w-full text-md text-gray-900">
                  <p>{selectionTask.description}</p>
                  {
                    typeof isNotCockpit !== "undefined" && isNotCockpit ? <></> : 
                    <MainButton text={'Continue'} icon={<div className='text-white'><ArrowRightIcon iconColor='currentColor'/></div>} link={`${SELECTION_PHASE_TASK_ROUTES[selectionTask.selectionPhase as keyof typeof SELECTION_PHASE_TASK_ROUTES]}`} />
                  }
                </div>
              </>
            )
          }
        </div>
      )
      }
        
    </div>
  )
}

export default NextStepsWidget