import React, { useEffect, useState } from 'react'
import { MainButton, TextInput } from 'swap-frontend-library'
import { getSPInviteCode } from '../../../data/ClosedBetaRequests';

const ClosedBetaSPCodeGen = () => {
    const [text, setText] = useState("");
    const [code, setCode] = useState("");

    const handleReceiveCode = async () => {
        if (text == "") return;
        const response = await getSPInviteCode(text);
        if (typeof response != undefined) setCode(response.code);
    }

    useEffect(() => {
        setCode("");
    }, [text])

  return (
    <div className='bg-white border rounded-lg shadow p-4'>
        <div className='flex items-end gap-4'>
            <div className='w-full h-full'><TextInput textValue={text} setTextValue={setText} label='Closed beta invite for selection portal' placeholder='URL (must be same as existing matching)' /></div>
            <div className='w-64'>
                <MainButton text={'Receive code'} func={handleReceiveCode} height='h-10'/>
            </div>
        </div>
        <div className='mt-4 h-16 bg-gray-100 rounded-lg flex items-center justify-center cursor-pointer' onClick={() => {if (code != "") navigator.clipboard.writeText(code)}}>
            {
                (code != "") ? (
                    <div className='text-center'>
                        <div className='font-semibold'>
                            {code}
                        </div>
                        <div className='text-sm font-light text-gray-500'>Click to copy to clipboard</div>
                    </div>
                ) : (<></>)
            }
        </div>
    </div>
  )
}

export default ClosedBetaSPCodeGen