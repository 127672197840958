import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";

i18n
.use(i18nBackend)
.use(initReactI18next)
.init({
    lng: "de",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    backend: {
        loadPath: `${process.env.REACT_APP_SELECTION_PORTAL}/translation/{{lng}}.json`,
    },
});

export default i18n;