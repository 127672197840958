import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../../components/MainButton';
import OpenIcon from '../../../../../../assets/img/icons/OpenIcon';
import { Link } from 'react-router-dom';
import CrossIcon from '../../../../../../assets/img/icons/CrossIcon';
import PercentageCircle from '../../../../../../components/PercentageCircle';
import { useTranslation } from 'react-i18next';

interface props {
    vendorRankingData: any | undefined,
    setSidebarOpenWithRankingData: React.Dispatch<React.SetStateAction<any | undefined>>;
}

const SidebarRankingInfo = ({vendorRankingData, setSidebarOpenWithRankingData} : props) => {
    const {t} = useTranslation();
    const [vendorRankingDataState, setVendorRankingDataState] = useState<any>();

    useEffect(() => {
        if (typeof vendorRankingData !== "undefined") {
            console.log(vendorRankingData)
            setVendorRankingDataState(vendorRankingData);
        }
    }, [vendorRankingData])

  return (
    <div className={`overflow-y-hidden w-[30rem] pl-2 h-full absolute top-0 transitions-all duration-500 ${typeof vendorRankingData !== "undefined" ? "-translate-x-[30rem]" : "-translate-x-0"} -right-[30rem]`}>
        {
            typeof vendorRankingDataState !== "undefined" ? (
                <div className='flex flex-col justify-between bg-white w-full h-full border-l-2' style={{boxShadow: "-2px 0px 5px 1px rgb(0,0,0,.05)"}}>

                    <div className='h-full pt-2'>
                        
                        <div className='flex w-full p-4 h-16 items-center justify-between bg-white'>
                            <div className='w-[85%]'>
                                <p className='truncate font-bold text-2xl text-primary'>{vendorRankingDataState.softwareProduct.productName}</p>
                                <p className='truncate font-light text-md text-secondary'>{vendorRankingDataState.softwareProduct.vendorDetails.company}</p>
                            </div>
                            <div onClick={() => setSidebarOpenWithRankingData(undefined)} className='bg-gray-100 hover:bg-gray-200 cursor-pointer rounded-full w-10 h-10 p-1'><CrossIcon /></div>
                        </div>

                        <div className='pt-10 px-4 border-t overflow-y-auto h-full pb-36'>

                            <div className='flex justify-center items-center'>
                                    <div className="mt-4 grid grid-cols-2 gap-16">

                                        <div className={`sm:w-32 px-2 flex flex-col h-full items-center`}>
                                            <div className='max-sm:text-xs'>
                                                Requirements
                                            </div>
                                            <div className='flex grow justify-center items-center relative'>
                                                <PercentageCircle rating={vendorRankingDataState.score_preselection} />
                                                {(vendorRankingDataState.score_preselection == null) ? (
                                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                            ) : (<></>)}
                                            </div>
                                        </div>

                                        <div className={`sm:w-32 px-2 flex flex-col h-full items-center`}>
                                            <div className='max-sm:text-xs'>
                                                Requirements
                                            </div>
                                            <div className='flex grow justify-center items-center relative'>
                                                <PercentageCircle rating={vendorRankingDataState.score_requirements} />
                                                {(vendorRankingDataState.score_requirements == null) ? (
                                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                            ) : (<></>)}
                                            </div>
                                        </div>

                                        <div className={`sm:w-32 px-2 flex flex-col h-full items-center`}>
                                            <div className='max-sm:text-xs'>
                                                Webcasts
                                            </div>
                                            <div className='flex grow justify-center items-center relative'>
                                                <PercentageCircle rating={vendorRankingDataState.score_webcast} />
                                                {(vendorRankingDataState.score_webcast == null) ? (
                                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                            ) : (<></>)}
                                            </div>
                                        </div>

                                        <div className={`sm:w-32 px-2 flex flex-col h-full items-center`}>
                                            <div className='max-sm:text-xs'>
                                                Presentation
                                            </div>
                                            <div className='flex grow justify-center items-center relative'>
                                                <PercentageCircle rating={vendorRankingDataState.score_presentation} />
                                                {(vendorRankingDataState.score_presentation == null) ? (
                                                    <div className='absolute text-gray-500 font-bold text-lg'>?</div>
                                            ) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-16 pt-2 flex justify-between'>
                                    <h3 className="text-xl font-semibold">{t("rank")} {vendorRankingDataState.rank}</h3>
                                    <div className='flex items-center space-x-2'><p className='text-xl font-semibold'>Included</p><div className={`rounded-full blur-[3px] w-4 h-4 ${vendorRankingDataState.included ? "bg-green-300 " : "bg-red-300 "}`}></div></div>
                                </div>
                            
                        </div>
                    </div>

                    <div className='fixed bg-white border-t pt-2 bottom-0 w-[29.3rem] px-4 h-16'>
                            <MainButton text={'Open'} link={`${process.env.REACT_APP_LANDING_PAGE}/product/${vendorRankingDataState.softwareProduct.id}?softwareRankingId=${vendorRankingDataState.id}`} linkNewTab={true} icon={<OpenIcon />}/>
                    </div>

            
                </div>
            ) : (<></>)
        }
    </div>
  )
}

export default SidebarRankingInfo