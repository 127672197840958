import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { login, logout, setEmail, setRole } from '../../../redux/userSlice';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { checkLogin } from '../../../data/AuthRequests';
import NotificationOverlay from '../notification-overlay/NotificationOverlay';
import Navbar from './features/Navbar';

const Index = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    handleCheckLoggedIn();
    setLoading(false);
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      handleCheckLoggedIn();
    }
    setLoading(false);
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname])

  const handleCheckLoggedIn = () => {
    checkLogin().then(res => {
      console.log(res)
      if (res.authorized) {
        if (!isLoggedIn) {
          dispatch(login());
          dispatch(setEmail(res.data.email));
          dispatch(setRole(res.data.role));
        }
      } else {
        dispatch(logout());
      }
    })
  }

  // TODO: Remove workarounds for selection-portal
  return (
    <div className='h-screen'>
      <Navbar />
      {(!loading && typeof isLoggedIn !== "undefined") ? (
        <div className=''>
          <NotificationOverlay />
          <Outlet />
        </div>
      ) : (
        <div className='mt-32'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default Index