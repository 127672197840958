import React from 'react';
import { IconProps } from './IconProps';

const ArrowLeftIcon = (props : IconProps) => {
  return (
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.50003 14.6L2.0667 9.16669C1.42503 8.52502 1.42503 7.47503 2.0667 6.83336L7.50003 1.40002" stroke={props.iconColor || "white"} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default ArrowLeftIcon