import React from 'react'
import CheckmarkIcon from '../assets/img/icons/CheckmarkIcon';
import WarningIcon from '../assets/img/icons/WarningIcon';
import CrossIcon from '../assets/img/icons/CrossIcon';

interface MenuItem {
    label : string,
    function: (id: string, value: any) => void,
    icon: JSX.Element,
    value?: any
}

interface props {
    id : string,
    openMenuForId: string | undefined,
    setOpenMenuForId: React.Dispatch<React.SetStateAction<string | undefined>>;
    menuItems : MenuItem[]
}

const QuickMenu = ({id, openMenuForId, setOpenMenuForId, menuItems}: props) => {
    

    return (
    <div onClick={(e) => {e.stopPropagation(); setOpenMenuForId(prev => prev == id ? "" : id);}}
    className='flex items-center justify-center w-full cursor-pointer'>
        <div className='font-black w-6 h-6 hover:bg-gray-100 rounded-full text-center'>
            ...
        </div>
        {
            (openMenuForId == id) ? (
                <div className='p-2 z-[8] absolute -translate-x-20'>
                    <div className='shadow flex flex-col bg-gray-50 border rounded-lg'>
                        {
                            menuItems.map((item:MenuItem, index:number) => {
                                return (
                                    <div onClick={(e) => {e.stopPropagation(); item.function(id, (item.value || item.label)); setOpenMenuForId(undefined); }} className='grow flex space-x-2 items-center justify-between border-b py-1 px-2 hover:bg-gray-100 cursor-pointer'>
                                        <p>{item.label}</p>
                                        <div className='w-6 h-6 text-inherit'>{item.icon}</div>
                                    </div>
                                )
                            })
                        }
                        <div onClick={(e) => {e.stopPropagation(); setOpenMenuForId(undefined) }} className={`border-b py-1 px-2 hover:bg-gray-100 cursor-pointer`} >Close</div>
                    </div>
                </div>
            ) : (
                <></>
            )
        }
    </div>
  )
}

export default QuickMenu