import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../components/TableHeader';
import TableRow from '../../../../../components/TableRow';
import RefreshIcon from '../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../assets/img/icons/PlusIcon';
import SearchBar from '../../../../../components/SearchBar';
import { useTranslation } from 'react-i18next';
import { addUserSelection, getUsersSelection } from '../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../../../../components/LoadingSpinner';

const Index = () => {
  const {t} = useTranslation();
  const { selectionId } = useOutletContext<any>();
  const [userSelection, setUsersSelection] = useState<any[]>();
  const [openAdd, setOpenAdd] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserEmailError, setNewUserEmailError] = useState(false);

  const handleFetchUsersSelection = async () => {
    const data = await getUsersSelection(selectionId);
    setUsersSelection(data.data.data);
  }

  const handleOpenAddUser = () => {
    setNewUserEmail("");
    setNewUserEmailError(false);
    setOpenAdd(prev => !prev);
  }

  const handleSendInvitation = async () => {
    if (newUserEmail == "") { return; }
    const response = await addUserSelection(selectionId, newUserEmail)
    console.log(response)
    if (response.ok) {
      handleOpenAddUser();
      handleFetchUsersSelection();
    } else {
      setNewUserEmailError(true);
    }
  }

  useEffect(() => {
    handleFetchUsersSelection();
  }, [])

  return (
    <div className='p-4'>
        <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              Selection settings
            </h1>
        </div>

        <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {/* {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
                Soll der Account mit der E-Mail Adresse {deleteItem.email} wirklich gelöscht werden?
            </div>
        } /> : <></>} */}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            User in Selection
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Ingesamt sind {[].length} User an der Selection beteiligt.</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    {/* <SearchBar translatedPlaceholderItem={`${t("vendorAccount")}`} originalData={[]} setFilteredData={} filterKey={"email"} /> */}
                </div>
            
            <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleFetchUsersSelection()}
                type="button"
                data-refresh
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>

                {
                  (openAdd) ? (
                    <div className='relative'>
                      <label htmlFor="search" className="sr-only">Add user to selection</label>
                      <div className="relative h-full w-64">
                          <input
                          onChange={e => {setNewUserEmailError(false); setNewUserEmail(e.target.value);}}
                          value={newUserEmail}
                          type="text"
                          id="search"
                          className={`bg-gray-50 border ${newUserEmailError ? "border-red-300" : "border-gray-300"} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
                          placeholder={`E-Mail`}
                          />
                      </div>
                      <button onClick={handleSendInvitation}
                type="submit" className="text-white absolute end-0.5 bottom-0.5 bg-primary border hover:bg-primary2 hover:text-primary hover:border-primary font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Send</button>
                    </div>
                  ) : (<></>)
                }

                <button
                onClick={handleOpenAddUser}
                id="createProductButton"
                className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="button"
                data-drawer-target="drawer-create-product-default"
                data-drawer-show="drawer-create-product-default"
                aria-controls="drawer-create-product-default"
                data-drawer-placement="right"
                >
                {openAdd ? "Cancel" : <><PlusIcon iconColor='white' />{t("add")}</>}
                </button>
            </div>
            </div>
        </div>
        </div>

        {
          (typeof userSelection === "undefined") ? (<LoadingSpinner />) : (
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <TableHeader header={["Id", "Email", "Role"]} />
                {
                    userSelection.map((user: any, index: number) => {

                        return (
                            <tbody className="cursor-pointer">
                                <TableRow rowItems={["", user.id, user.user.email, (user.selectionRole == "selection_admin") ? ("Admin") : ("User")]} />
                            </tbody>
                        );
                    })
                }
              </table>
          )
        }

      </div>
    </div>
  )
}

export default Index