import React, { useEffect, useRef, useState } from 'react'
import { deleteSelectionRequirement, getSelectionRequirements, setSelectionRequirementStatus } from '../../../../../../data/SelectionPortalRequests';
import TableHeader from '../../../../../../components/TableHeader';
import RequirementTableRow from './RequirementTableRow';
import SpeedDial from '../../components/SpeedDial';
import MainButton from '../../../../../../components/MainButton';
import SearchBarFuzzy from '../../../../../../components/SearchBarFuzzy';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';

interface props {
    selectedTab : string,
    selectionId : string,
    setUniqueRequirementCategories: React.Dispatch<React.SetStateAction<any>>,
    handleOpenRequirement : (a:string, b:string) => void,
    selectedRequirementCategory: any[],
}

const SelectionRequirementsTable = ({selectedTab, selectionId, setUniqueRequirementCategories, handleOpenRequirement, selectedRequirementCategory}: props) => {
    const ref = useRef<any>();
    const [selectionRequirements, setSelectionRequirements] = useState([]);
    const [selectionRequirementFiltered, setSelectionRequirementFiltered] = useState<any[]>([]);
    const [selectionRequirementFilteredAndSearched, setSelectionRequirementFilteredAndSearched] = useState<any[]>([]);
    const [openMenuRequirement, setOpenMenuRequirement] = useState<string>();
    const [archivedVisible, setArchivedVisible] = useState(false);
    
    const handleFetchRequirements = async (selectionId: string) => {
        // console.log(selectionId)
        const data = await getSelectionRequirements(selectionId);
        const requirements = data.data.data.selectionRequirements.map((value : any) => {
            value.selectionRequirements_category = JSON.parse(value.selectionRequirements_category)
            return (value)
        })
        setSelectionRequirements(requirements);
        setSelectionRequirementFiltered(requirements);

        setUniqueRequirementCategories(data.data.data.uniqueCategories);
    }

    const handleFilterRequirements = () => {
        // console.log(selectedRequirementCategory)
        if (selectedRequirementCategory[0]) {
            const filteredRequirements = selectionRequirements.filter((requirement : any) =>
                ((selectedRequirementCategory[0] == requirement.selectionRequirements_category[0]) && 
                (!selectedRequirementCategory[1] || (selectedRequirementCategory[1] == requirement.selectionRequirements_category[1]))) &&
                (!selectedRequirementCategory[2] || (selectedRequirementCategory[2] == requirement.selectionRequirements_category[2]))
            )
            // console.log(filteredRequirements)
            setSelectionRequirementFiltered(filteredRequirements);
        } else {
            setSelectionRequirementFiltered(selectionRequirements);
        }
    }

    const handleSetRequirementStatus = async (requirementId: string, status : string) => {
        if (await setSelectionRequirementStatus(requirementId, status)) {
            handleFetchRequirements(selectionId);
        }
    }
    
    const handleDeleteRequirement = async (requirementId: string) => {
        if (await deleteSelectionRequirement(requirementId)) {
            handleFetchRequirements(selectionId);
        }
    }

    useEffect(() => { handleFetchRequirements(selectionId); }, [])
    // useEffect(() => { if (selectedTab == "") handleFetchRequirements(selectionId) }, [selectedTab])
    
    useEffect(() => { handleFilterRequirements(); }, [selectedRequirementCategory])
    useEffect(() => { setSelectionRequirementFilteredAndSearched(selectionRequirementFiltered) }, [selectionRequirementFiltered])

    const toggleExcluded = () => {
        setArchivedVisible(prev => !prev);
    }

    useEffect(() => {
        if(archivedVisible) {
            setTimeout(() => ref.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 100)
            // ref.current.scrollIntoView({behaviour: "smooth"});
        }
    }, [archivedVisible])

    return (
    <div>
        {/* Search / Button Bar */}
        <div className="flex items-center justify-between h-16 px-4">
            <div className='h-10 w-96'>
                <SearchBarFuzzy translatedPlaceholderItem={'requirement'} originalData={selectionRequirementFiltered} setFilteredData={setSelectionRequirementFilteredAndSearched} filterKeys={["selectionRequirements_requirement"]} />
            </div>
            <div className="flex justify-between items-center font-medium text-white h-10">
                <MainButton text={'Add requirement'} icon={<div className='text-white'><PlusIcon iconColor='currentColor' /></div>} text_location='right' func={() => handleOpenRequirement("-1", "New requirement")} />
            </div>
        </div>
        <table className="w-full relative text-sm text-left text-gray-500 dark:text-gray-400">
        {/* `${selectionRequirement.selectionRequirements_importance} %` */}
            <TableHeader header={["Title", "Importance", "Completed", "Action"]} />
            {
                selectionRequirementFilteredAndSearched.filter((item : any) => (item.selectionRequirements_requirementStatus != "archived")).map((selectionRequirement: any, index: number) => {

                    return (
                        <RequirementTableRow index={index} selectionRequirement={selectionRequirement} openMenuRequirement={openMenuRequirement} setOpenMenuRequirement={setOpenMenuRequirement} handleOpenRequirement={handleOpenRequirement} handleSetRequirementStatus={handleSetRequirementStatus} handleDeleteRequirement={handleDeleteRequirement} />
                    );
                })
            }
            <td colSpan={5}>
                <div onClick={toggleExcluded}
                className={`absolute flex items-center relative cursor-pointer ${archivedVisible ? "my-4" : "mt-4 mb-2"}`}>
                    <div className='grow my-2 border-t border-gray-400 border-4'>
                    </div>

                    <div className='mx-4 flex flex-col items-center text-gray-400'>
                        {
                            (archivedVisible) ? ("Hide archived") : ("See archived")
                        }
                        
                        {/* <ArrowDownIcon iconColor='gray' iconHeight='100px' /> */}
                    </div>
                    
                    <div className='grow my-2 border-t border-gray-400 border-4'>
                    </div>
                </div>
            </td>

            {
                (archivedVisible) ? (
                selectionRequirementFiltered.filter((item : any) => (item.selectionRequirements_requirementStatus == "archived")).map((selectionRequirement: any, index: number) => {

                    return (
                        <RequirementTableRow index={index} selectionRequirement={selectionRequirement} openMenuRequirement={openMenuRequirement} setOpenMenuRequirement={setOpenMenuRequirement} handleOpenRequirement={handleOpenRequirement} handleSetRequirementStatus={handleSetRequirementStatus} handleDeleteRequirement={handleDeleteRequirement} />
                    );
                })
                ) : (<></>)
            }
            <div ref={ref} className='invisible'></div>
        </table>

        <SpeedDial speedDials={[
            {tooltip: "Add requirement", onClick:() => handleOpenRequirement("-1", "New requirement")},
        ]} defaultDials={true} currentContext='requirements' selectionId={selectionId} reloadTaskTrigger={selectionRequirements} />
    </div>
  )
}

export default SelectionRequirementsTable