import React, { useEffect, useState } from 'react'
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

import { fetchSoftwareProductData } from '../../../../../../data/ProductRequests';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import PlusIcon from '../../../../../../assets/img/icons/PlusIcon';

interface props {
    openProduct : (id : string) => void;
}

const SoftwareProductTable = ({openProduct} : props) => {
    const { t } = useTranslation();
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search
    const userRole = useSelector((state: RootState) => state.user.role);
    const [visibleRows, setVisibleRows] = useState([1,1]);

    const [softwareProductData, setSoftwareProductData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);

    useEffect(() => {
        handleReloadProducts();
    }, [])

    const handleReloadProducts = async () => {
        setSoftwareProductData([]);
        const data = await fetchSoftwareProductData(userRole)
        console.log(data)
        setSoftwareProductData(data);
        setFilteredData(data)
        setLoaded(true);
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {t("progressCard.productText")}
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("totalItems", {length: softwareProductData.length, itemType: t("progressCard.productText")})}</p>
        </div>

        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
        >
        <div className="w-full mb-1">
            <div className="items-center justify-between block sm:flex">
                <div className='sm:w-64 xl:w-96'>
                    <SearchBar translatedPlaceholderItem={`${t("progressCard.productText")}`} originalData={softwareProductData} setFilteredData={setFilteredData} filterKey={"productName"} />
                </div>
            
            <div className="flex items-center h-[40px] ml-auto space-x-2 sm:space-x-3">
                <button
                onClick={() => handleReloadProducts()}
                type="button"
                data-refresh
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                <RefreshIcon iconColor='white' />
                {t("refresh")}
                </button>
                <button
                onClick={() => openProduct("")}
                id="createProductButton"
                className="inline-flex items-center h-full justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                type="button"
                >
                <PlusIcon iconColor='white' />
                {t("add")}
                </button>
            </div>
            </div>
        </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <TableHeader header={[`${t("productName")}`, `${t("vendorCompany")}`, `${t("productUrl")}`,  `${t("weights")}`, `${t("softwareFulfillment")}`, `${t("createdOn")}`]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={5} className='h-16'>No products available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, productName, productUrl, vendorDetails, weightTrade, weightManufacturing, weightService, softwareFulfillment, createdDate} : any, index : number) => {
                            const date : Date = new Date(Date.parse(createdDate));
                            const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                            let vendorCompany = ""
                            if (vendorDetails) { vendorCompany = vendorDetails.company }
                            return (
                                <tbody className="cursor-pointer" onClick={() => openProduct(id)}>
                                    <TableRow rowItems={[index, productName, vendorCompany, productUrl, `${weightManufacturing}/${weightTrade}/${weightService}`, softwareFulfillment.length, dateString]} />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
        )}

    </div>
  )
}

export default SoftwareProductTable