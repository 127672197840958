export async function setMatchingEngineSettings(setting_unique_name: string, setting_value: object) {

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            setting_unique_name,
            setting_value
        }),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/settings/set-matching-engine-settings`, options);
        if (res.ok) {
            return true;
        } else {
            console.error(`set-matching-engine-settings: Bad response ${res.status}`);
            return false;
        }
        
    } catch(error) {
        console.error(`set-matching-engine-settings: Error occurred ${error}`);
        return false;
    }
}

export async function getMatchingEngineSettings(setting_unique_name: string) {

    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/settings/get-matching-engine-settings?setting_unique_name=${setting_unique_name}`, options);
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`set-matching-engine-settings: Bad response ${res.status}`);
        }
        
    } catch(error) {
        console.error(`set-matching-engine-settings: Error occurred ${error}`);
    }
}