import React, { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom';
import { ArrowLeftIcon, HeadsetIcon, ListFilledIcon, LoadingSpinner, MessageIcon } from 'swap-frontend-library';
import SidebarParticipants from './features/SidebarParticipants';
import EvaluationScreen from './features/EvaluationArea';
import { getSelectionPresentation } from '../../../../../../../data/SelectionPortalRequests';
import SidebarAgenda from './features/SidebarAgenda';
import SidebarChat from './features/SidebarChat';
import { socket } from '../../../../../../../_socket/socket';

export const loader = async ({ params } : any) : Promise<String> => {
    if (params.presentationId) {
        return params.presentationId;
    } else {
        return "";
    }
}

const ParticipationScreen = () => {
    const presentationId : string = String(useLoaderData());
    const [meetingItem, setMeetingItem] = useState<any>();
    const [sidebarContent, setSidebarContent] = useState("");
    const [unseenMessages, setUnseenMessages] = useState(false);
    
    const [messages, setMessages] = useState<any[]>([]);

    const handleGetSelectionPresentation = async () => {
        // console.log(presentationId);
        const response = await getSelectionPresentation(presentationId);
        if (response.ok) {
            console.log(response.data.data)
            setMeetingItem(response.data.data);

            const oldChatMessages = response.data.data.selectionPresentationChatMessages;
            if (typeof oldChatMessages !== "undefined") setMessages(oldChatMessages);
        }
    }

    useEffect(() => {
        if (sidebarContent == "chat") setUnseenMessages(false);
    }, [sidebarContent])
    
    useEffect(() => {
        if (sidebarContent != "chat" && messages.length > 0) setUnseenMessages(true);
    }, [messages])
    
    useEffect(() => {
        handleGetSelectionPresentation();
    }, [])

    useEffect(() => {
        const handleReceiveMessage = (data : any) => {
            setMessages(prev => [...prev, data.chatMessage])
        }
    
        socket.on("send message", handleReceiveMessage);

        return () => {
            socket.off("send message");
        }
    }, [])

    if (typeof meetingItem == "undefined") {
        return (<LoadingSpinner />)
    }

    const dateParsed : Date = new Date(meetingItem.timeFrom);
    const dateString : string = `${dateParsed.getDate().toString().padStart(2, '0')}.${(dateParsed.getMonth()+1).toString().padStart(2, '0')}.${dateParsed.getFullYear()}`;
    
    const timeFrom = `${(new Date(meetingItem.timeFrom)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeFrom)).getUTCMinutes().toString().padStart(2, '0')}`;
    const timeTo = `${(new Date(meetingItem.timeTo)).getHours().toString().padStart(2, '0')}:${(new Date(meetingItem.timeTo)).getUTCMinutes().toString().padStart(2, '0')}`;

    const now = new Date(); // Get the current timestamp
    const isLive = now >= new Date(meetingItem.timeFrom) && now <= new Date(meetingItem.timeTo);
    
    return (
        <div className='h-full flex flex-col'>
            <div className="w-full h-16 flex gap-6 w-full py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
                <Link to={`../`} className='flex items-center justify-start cursor-pointer gap-2'>
                    <div className='w-4 h-4'><ArrowLeftIcon/></div>
                    <p>Back</p>
                </Link>

                <div className='flex items-center w-full h-full gap-2'>
                    <p className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.vendorDetails.company}</p>
                    <p className='font-semibold text-xl text-primary'>{meetingItem.selectionSoftwareProduct.softwareProduct.productName}</p>
                    <p className='text-xl'>|</p>
                    <p className='text-xl'>Vendor presentation</p>
                    <p className='text-xl'>|</p>
                    <p className='text-xl'>{dateString},</p>
                    <p className='text-xl'>{timeFrom} - {timeTo}</p>

                    { isLive ? (
                        <div className='ml-4 flex items-center gap-2'>
                            <div className='relative w-4 h-4'>
                                <div className='absolute left-0 top-0 bg-primary w-full h-full rounded-full'></div>
                                <div className='absolute left-0 top-0 animate-ping bg-primary w-full h-full rounded-full'></div>
                            </div>
                            <p className='font-semibold text-xl text-primary'>Live</p> 
                        </div>
                    )
                    : <></> }
                </div>

                <div className='flex items-center space-x-2'>
                    <div onClick={() => {setSidebarContent(prev => (prev == "agenda") ? ("") : ("agenda"));}}
                    className={`cursor-pointer flex items-center text-gray-400 justify-center h-10 w-10 p-1.5 rounded-full ${sidebarContent == "agenda" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                        <ListFilledIcon />
                        {/* {sidebarContent == "analysis" ? "white" : "currentColor"} */}
                    </div>
                    <div onClick={() => {setSidebarContent(prev => (prev == "participants") ? ("") : ("participants"));}}
                    className={`cursor-pointer flex items-center text-gray-400 border border-transparent justify-center h-10 w-10 p-1 rounded-full ${sidebarContent == "participants" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                        <HeadsetIcon />
                    </div>
                    <div onClick={() => {setSidebarContent(prev => (prev == "chat") ? ("") : ("chat"));}}
                    className={`relative cursor-pointer text-gray-400 p-1 flex items-center border border-transparent justify-center h-10 w-10 rounded-full ${sidebarContent == "chat" ? "bg-primary text-white" : "hover:bg-gray-100"}`}>
                        <MessageIcon />
                        {
                            unseenMessages ? <div className='absolute rounded-full w-3 h-3 bg-primary right-0 bottom-0'></div> : <></>
                        }
                        <div></div>
                    </div>
                </div>
            </div>

            <div className='relative h-full grow flex overflow-y-hidden'>
                <div className='w-full overflow-y-auto'>
                    <EvaluationScreen presentationId={meetingItem.id} meetingItem={meetingItem} />
                </div>
                {
                    sidebarContent == "" ? (<></>) : (
                        <div className='absolute shadow-lg right-0 h-full flex flex-col bg-white w-fit border-l'>
                            <div className='absolute h-16 text-2xl p-4 font-semibold w-96'>{sidebarContent.slice(0,1).toUpperCase()+sidebarContent.slice(1)}</div>
                            <div className='p-4 pt-16 flex h-full'>
                                {
                                    (sidebarContent == "agenda") ? (<SidebarAgenda presentationId={presentationId} meetingItem={meetingItem} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                                    (sidebarContent == "participants") ? (<SidebarParticipants participants={meetingItem.selectionPresentationParticipant} presentationId={presentationId} handleGetSelectionPresentation={handleGetSelectionPresentation} />) :
                                    (sidebarContent == "chat") ? (<SidebarChat messages={messages} presentationId={presentationId} />) : <></>
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ParticipationScreen