import React, { useEffect, useState } from 'react'
import MainButton from '../../../../../components/MainButton';
import BellFilledIcon from '../../../../../assets/img/icons/BellFilledIcon';
import ActivityWindow from './components/ActivityWindow';
import ChatWindow from './components/ChatWindow';
import { getAllChats } from '../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router-dom';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const Index = () => {
  const [selectedChat, setSelectedChat] = useState<string>();
  const [selectedChatEmail, setSelectedChatEmail] = useState<string>();
  const [chats, setChats] = useState<any[]>();
  const { selectionId } = useOutletContext<any>();
  const email = useSelector((state: RootState) => state.user.email);

  const handleFetchChats = async () => {
    const response = await getAllChats(selectionId);
    console.log(response.data.data);
    setChats(response.data.data);
    return (response.data.data);
  }

  const handleSwitchChatsToRecipient = async (recipientId: string) => {
    const newChats = await handleFetchChats();
    if (typeof chats !== "undefined") {

      // TODO Group chat feature
      const matchingChats = newChats.filter((chat: any) => chat.userChat.map((userChat:any) => userChat.user.id).includes(recipientId));
      if (matchingChats.length > 0) {
        setSelectedChat(matchingChats[0].id);
      }
    }
  }

  useEffect(() => {handleFetchChats()}, [])
  
  return (
    <div className='h-full'>
        {/* <div className="mb-4 col-span-full xl:mb-2">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
              Inbox
            </h1>
        </div> */}
        
        <div className='flex h-full w-full'>
          <div className="relative h-full w-64 flex flex-col font-medium whitespace-nowrap bg-white border-r border-solid basis-0 leading-[150%]">
            <div className='absolute border-b w-full p-4 h-16 top-0 bg-white flex items-center'>
              <div className="flex flex-col justify-center px-4 py-2 w-full text-sm font-light text-gray-400 bg-gray-50 rounded-lg border border-solid">
                  <div className="flex gap-2 justify-between pr-9 max-md:pr-5">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f37b3e8acc92080f05f65214031190a0a04fbd6c70d4bf2d8a3f7d27d5d221ba?"
                      className="my-auto aspect-square w-[18px]"
                    />
                  <div className="grow justify-center">Search for chat</div>
                </div>
                </div>
            </div>
            <div className='absolute border-b w-full px-3 py-2 h-16 top-16 bg-white'>
              <div onClick={() => setSelectedChat(undefined)}
              className={`relative flex items-center ${(typeof selectedChat === "undefined") ? ("bg-primary text-white") : ("hover:bg-gray-200")} rounded-md h-full px-2 cursor-pointer`}>
                <div className='absolute h-10 w-10 rounded-full flex justify-center items-center'>
                  <BellFilledIcon iconColor='currentColor' />
                </div>
                <div className='w-full pl-12'>
                  <p className='truncate font-semibold'>Activities</p>
                </div>
              </div>
            </div>

            <div className="h-full w-64 mt-32 mb-20 flex flex-col px-3 py-1.5 overflow-y-auto text-base text-gray-900">
              <div id='chats' className='h-full flex flex-col py-2 overflow-y-auto space-y-2'>
                  {
                    (typeof chats === "undefined") ? (<LoadingSpinner />) : (chats.length == 0) ? (<></>) : (
                      <>
                        {
                          chats.map((chat: any, index: number) => {
                            let recipients = chat.userChat.filter((item: any) => item.user.email != email);

                            return (
                              <div onClick={() => {setSelectedChat(chat.id); setSelectedChatEmail(recipients[0].user.email);}}
                              className={`relative py-2 flex items-center ${(selectedChat === chat.id) ? ("bg-primary text-white") : ("hover:bg-gray-200")} rounded-md px-2 cursor-pointer`}>
                                <div className='absolute h-10 w-10 bg-white rounded-full flex justify-center items-center'>
                                  <div className='text-primary font-semibold'>{String(recipients[0].user.email[0]).toUpperCase()}</div>
                                </div>
                                <div className='w-full pl-12'>
                                  <p className='truncate font-semibold'>{recipients[0].user.email}</p>
                                  <p className='text-secondary italic truncate'>Message...</p>
                                </div>
                              </div>
                            )
                          })
                        }
                      </>
                    ) 
                  }
              </div>
            </div>
            
            <div className='absolute border-t w-full p-4 h-20 bottom-0 bg-white'>
              <MainButton text={'New chat'} link={''} style='light' func={() => setSelectedChat("")}/>
            </div>
          </div>

          {
            (typeof selectedChat === "undefined") ? (<ActivityWindow />) : (<ChatWindow chatId={selectedChat} setChatId={setSelectedChat} selectedChatEmail={selectedChatEmail} handleSwitchChatsToRecipient={handleSwitchChatsToRecipient} />)
          }  
      </div>
    </div>
  )
}

export default Index