import React from 'react';
import { IconProps } from './IconProps';

const SearchIcon = (props : IconProps) => {
  return (
    <svg className={`${props.iconWidth || "w-[20px]"} ${props.iconHeight || "h-[20px]"}`} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.58333 17.5C13.9556 17.5 17.5 13.9556 17.5 9.58333C17.5 5.21108 13.9556 1.66667 9.58333 1.66667C5.21108 1.66667 1.66666 5.21108 1.66666 9.58333C1.66666 13.9556 5.21108 17.5 9.58333 17.5Z" stroke={props.iconColor || "white"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.3333 18.3333L16.6667 16.6667" stroke={props.iconColor || "white"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default SearchIcon