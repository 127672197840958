import React, { useEffect, useState } from 'react'
import { getCustomerSearches } from '../../../../data/CustomerSearchRequests'
import { useTranslation } from 'react-i18next';
import SearchTable from './components/SearchTable';
import FormModal from '../../../../components/FormModal';
import StartSelectionForm from './components/StartSelectionForm';
import { getSelections } from '../../../../data/SelectionPortalRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { InfoIcon, LoadingSpinner } from 'swap-frontend-library';

const SelectionSelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [startSelectionFormOpen, setStartSelectionFormOpen] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("");

  const [selections, setSelections] = useState<any[]>();

  const email = useSelector((state: RootState) => state.user.email);

  const handleFetchSelections = async () => {
    const response = await getSelections(email);
    setSelections(response.data.data);
    // console.log(response.data.data)
  }

  useEffect(() => {handleFetchSelections()}, [])

  return (
    <div className='p-4 pt-8 w-full'>
      {(startSelectionFormOpen) ? (<FormModal isOpen={startSelectionFormOpen} setOpen={setStartSelectionFormOpen} size="regular"><StartSelectionForm customerSearchcustomerDetailsId={selectedSearch}/></FormModal>) : (<div className="hidden"></div>)}
        <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Software-Selection Projects
          </h1>
        </div>
        <div className='py-4 w-full grow'>
          {
            (typeof selections === "undefined") ? (<LoadingSpinner />) : (selections.length == 0) ? (
              <div className='w-full bg-white border rounded-lg h-64 flex flex-col items-center justify-center'>
                <div className='w-10 h-10 mb-4 text-gray-400'><InfoIcon /></div>
                <p className='text-gray-600'>No software selection project started yet. Carry out a software matching and then start a software selection project.</p>
              </div>
            ) : (
              <div className='grid grid-cols-3 gap-4'>
                {
                  selections.map((value:any, index:number) => {
                    const createdDateParsed : Date = new Date(Date.parse(value.createdDate));
                    const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;

                    return (
                      <>
                      <div onClick={() => {navigate(`/user-area/selection-portal/${value.id}`)}}
                      className="relative flex items-end h-64 w-full bg-white shadow-md cursor-pointer transition-all hover:scale-[1.02] rounded-lg">
                        <div className="p-5 text-lg font-semibold text-left text-gray-900 w-full h-64 truncate flex flex-col items-start justify-between">
                          {
                            value.customerSearchCustomerDetails.customerSearch.companyLogos.length > 0 ?
                            <img className='h-32' src={
                              value.customerSearchCustomerDetails.customerSearch.companyLogos[0].startsWith("http") ?
                              value.customerSearchCustomerDetails.customerSearch.companyLogos[0] : "https://"+value.customerSearchCustomerDetails.customerSearch.companyLogos[0]
                            } /> : <></>
                          }
                          <div className='w-32'>
                            <p className='mt-4'>{value.customerSearchCustomerDetails.customerSearch.queryUrl}</p>
                            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                              {createdDateString}
                            </p>
                          </div>
                        </div>
                      </div>
                      </>
                    )
                  })
                }
              </div>
            ) 
          }
          {/* <div className="relative h-64 w-64 bg-white overflow-x-auto mt-5 shadow-md cursor-pointer transition-all hover:scale-[1.02] sm:rounded-lg">
            AAA
          </div> */}
        </div>
        <div>
          <SearchTable setSelectedSearch={setSelectedSearch} setStartSelectionFormOpen={setStartSelectionFormOpen} />
        </div>

    </div>
  )
}

export default SelectionSelector