import React from 'react'
import HorizontalNavigationBalls from '../../../../../../components/HorizontalNavigationBalls';
import CheckmarkIcon from '../../../../../../assets/img/icons/CheckmarkIcon';

interface props {
    topCategories: any[],
    topCategoryCompletion: any[],
    handlePageChangeCategory: (idx: number) => void,
    visibleCategoryIndex: number,
    currentSubCategories: any[],
    subCategoryCompletion: any[],
    handlePageChangeSubCategory: (idx: number) => void,
    visibleSubCategoryIndex: number
}

const FulfillmentSidebar = ({topCategories, topCategoryCompletion, handlePageChangeCategory, visibleCategoryIndex, currentSubCategories, subCategoryCompletion, handlePageChangeSubCategory, visibleSubCategoryIndex} : props) => {
  return (
    <div className='w-full overflow-x-auto sm:w-1/3 max-sm:flex p-5 md:top-[10%] md:left-[5%]'>
        {
            topCategories.map((topCat : any , idx: number) => {
                let currentTopCompletion = 0;
                if (typeof topCategoryCompletion[topCat] !== "undefined") {
                    currentTopCompletion = Math.round(topCategoryCompletion[topCat][0] / topCategoryCompletion[topCat][1] * 100);
                }
                return (
                    <div>
                            <div key={idx} onClick={() => handlePageChangeCategory(idx)}
                            className="flex w-full cursor-pointer items-center">
                                <div className='md:w-[70%]'>
                                    <HorizontalNavigationBalls number={idx+1} text={topCat} link={""} activePage={visibleCategoryIndex+1} />
                                </div>
                                <div className="ml-3 text-secondary4 whitespace-nowrap font-medium w-[20%] text-base font-medium max-md:hidden">{currentTopCompletion} %</div>
                                <div className='w-5 h-5 flex items-center justify-center rounded-full border-2 border-gray-500 max-md:hidden'>
                                {
                                    (currentTopCompletion == 100) ? (
                                            <CheckmarkIcon iconColor=''/>
                                    ) : (<></>)
                                }
                                </div>
                            </div>
                            {
                                (topCat == topCategories[visibleCategoryIndex]) ? (
                                    <>
                                    {
                                        currentSubCategories.map((subCat:any, subIdx:number) => {
                                        let currentSubCompletion = 0;
                                        if (typeof subCategoryCompletion[subCat] !== "undefined") {
                                            currentSubCompletion = Math.round(subCategoryCompletion[subCat][0] / subCategoryCompletion[subCat][1] * 100);
                                        }
                                        return (
                                            <div onClick={() => handlePageChangeSubCategory(subIdx)}
                                            className={`cursor-pointer flex items-center pl-16 text-base max-md:text-sm max-sm:text-xs max-sm:hidden ${(visibleSubCategoryIndex == subIdx) ? ("text-primary font-semibold") : ("text-secondary4 font-medium")}`
                                            }>
                                                <div className='w-[60%] whitespace-nowrap truncate'>{subCat}</div>
                                                <div className='pl-2 w-[30%] whitespace-nowrap'>{currentSubCompletion} %</div>
                                                <div className='w-5 h-5 flex items-center justify-center rounded-full border-2 border-gray-500'>
                                                    {
                                                        (currentSubCompletion == 100) ? (
                                                                <CheckmarkIcon iconColor=''/>
                                                        ) : (<></>)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </>
                                ) : (<></>)
                            }
                        </div>
                )
            })
        }
    </div>
  )
}

export default FulfillmentSidebar