import React from 'react'
import { Link } from 'react-router-dom'

const PageLogo = () => {
  return (
    <Link to={`${process.env.REACT_APP_LANDING_PAGE}`} className="text-3xl font-semibold text-primary flex items-center gap-3 max-[900px]:text-2xl max-[900px]:gap-[7.5px] max-sm:text-xl ">
        <div>
          <img src="/img/client-specific/find-your-esg.svg" alt="" className="h-10 max-lg:w-9 max-sm:w-7.5"></img>
          <p className='pl-1 text-sm font-light italic'>by Softwarematch.de</p>
        </div>
    </Link>
  )
}

export default PageLogo