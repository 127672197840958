import React, { useEffect, useState } from 'react';
import PercentageCircle from '../../../../../components/PercentageCircle';
import VendorRankingRow from './components/VendorRankingRow';
// @ts-ignore
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ArrowDownIcon from '../../../../../assets/img/icons/ArrowDownIcon';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { changeRankingInclusion, getSelectionRanking } from '../../../../../data/SelectionPortalRequests';
import { useOutletContext } from 'react-router';
import TopVendorsPodium from './components/TopVendorsPodium';
import SidebarRankingInfo from './components/SidebarRankingInfo';
import { useSearchParams } from 'react-router-dom';
import { AnalysisIcon, MainButton, SettingsFilledIcon } from 'swap-frontend-library';
import SpeedDial from '../components/SpeedDial';
import SelectionGuideInjector from '../components/SelectionGuideInjector';

const Index = () => {
    const { selectionId }  = useOutletContext<any>();
    const [vendorRanking, setVendorRanking] = useState<any[]>();
    const [currentAvailableScore, setCurrentAvailableScore] = useState<string>("preselection");
    const [excludedVisible, setExcludedVisible] = useState(false);
    const [numberIncluded, setNumberIncluded] = useState(0);
    const [exclusionError, setExclusionError] = useState(false);
    const [includeDragActive, setIncludeDragActive] = useState(false);
    const [excludeDragActive, setExcludeDragActive] = useState(false);
    const [sidebarOpenWithRankingData, setSidebarOpenWithRankingData] = useState<any>();
    const [endPhase, setEndPhase] = useState<string | null>(null);

    const handleExclude = (e : any) => {
        if (numberIncluded > 1) {
            handleDrop(e, false);
        } else {
            setExclusionError(true);
        }
        setExcludeDragActive(false);
    }
    
    const handleInclude = (e : any) => {
        handleDrop(e, true);
        setIncludeDragActive(false);
    }

    const handleDrop = async (e : any, include : boolean) => {
        const id = e.dataTransfer.getData("id");
        
        if (await changeRankingInclusion(id, include)) {
            setExcludeDragActive(false);
            setIncludeDragActive(false);

            await handleFetchRanking();
        }

        // const newVendorRanking = vendorRanking?.map((item:any) =>{
        //     if (id == item.id) {
        //         item.included = include;
        //     }
        //     return item;
        // })
        // setVendorRanking(newVendorRanking);
    }

    const enterDrop = (e : any, include : boolean) => {
        e.preventDefault();
        if (include) { setIncludeDragActive(true); } else { setExcludeDragActive(true); }
    }
    
    const leaveDrop = (e : any, include : boolean) => {
        e.preventDefault();
        if (include) { setIncludeDragActive(false); } else { setExcludeDragActive(false); }
    }
    
    const dragStart = (e : any, id : string) => {
        setExclusionError(false);
        e.dataTransfer.setData("id", id);
    }

    const toggleExcluded = () => {
        setExcludedVisible(prev => !prev);
        setExclusionError(false);
    }

    const handleFetchRanking = async () => {
        const response = await getSelectionRanking(selectionId);
        if (response.ok) {
            // console.log(response.data.data.results)
            setVendorRanking(response.data.data.results);

            if (response.data.data.results[0].score_presentation) {
                setCurrentAvailableScore("presentation")
            } else if (response.data.data.results[0].score_webcast) {
                setCurrentAvailableScore("webcast")
            } else if (response.data.data.results[0].score_requirements) {
                setCurrentAvailableScore("requirements")
            } else {
                setCurrentAvailableScore("preselection")
            }
        }
    }

    const handleCompareSystems = () => {
        console.log("handleCompareSystems: Not implemented");
    }

    const [ searchParams, setSearchParams ] = useSearchParams();
    useEffect(() => {
        const endPhaseSearchParam = searchParams.get("endPhase");
        setEndPhase(endPhaseSearchParam)
        handleFetchRanking();
    }, [])
    
    // useEffect(() => {
    //     const endPhaseSearchParam = searchParams.get("endPhase");
    //     setEndPhase(endPhaseSearchParam)
    // }, [searchParams])

    useEffect(() => {
        const numberIncludedOrUndefined = vendorRanking?.filter((item:any) => item.included).length;
        if (typeof numberIncludedOrUndefined !== "undefined") {
            setNumberIncluded(numberIncludedOrUndefined);
        }
    }, [vendorRanking])

    return (
    <div className='flex flex-col h-full w-full relative z-[1000]'>
        <div className="absolute top-0 py-4 justify-between z-[1000] w-full flex items-center py-2.5 pr-5 pl-5 text-sm font-medium leading-5 text-gray-900 bg-white border-b border-solid max-md:pr-5 max-md:max-w-full">
            <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
                Ranking
            </h1>

            <div className='absolute right-2 z-[1000] flex space-x-2'>
                <MainButton text='Compare systems' style='light' icon={<div className='text-secondary w-6 h-6'><AnalysisIcon /></div>} />
                <MainButton text={excludedVisible ? "Save short-list" : "Update short-list"} func={() => setExcludedVisible(prev => !prev)} style='light' icon={<div className='text-secondary w-6 h-6'><SettingsFilledIcon /></div>} />
                {
                    endPhase != null ? (
                        <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["FINISH_PHASE"]} reloadTaskTrigger={vendorRanking} />
                    ) : (<></>)
                }
            </div>
        </div>
        {/* <div className="mb-4 col-span-full xl:mb-2">
        </div> */}

        <div className='relative overflow-x-hidden px-4 h-full mt-16'>
            <div className='h-full w-full'>
                {
                    (typeof vendorRanking === "undefined") ? (<LoadingSpinner />) : (
                        <>
                        <div className='relative h-full w-full flex flex-col justify-between'>
                            <div className={`relative overflow-y-auto pt-3  ${excludedVisible ? "h-[60%]" : "mb-16"}`}
                            onDrop={handleInclude} onDragOver={(e) => enterDrop(e, true)} onDragLeave={(e) => leaveDrop(e, true)}>

                                <div className='max-lg:hidden pt-4'>
                                    <TopVendorsPodium vendorData={vendorRanking.filter((item:any) => item.included).slice(0,3)} dragStart={dragStart} draggable={excludedVisible} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
                                </div>
                
                                <div
                                className='space-y-2'>
                                    {
                                        vendorRanking.filter((item:any) => item.included).map((item:any, index:number) => {
                                            if (item.included) {
                                                return (
                                                    <div draggable={excludedVisible} onDragStart={(e) => dragStart(e, item.id)} className={`${(item.rank < 4) ? ("lg:hidden") : ("")}`}>
                                                        <VendorRankingRow vendorRankingData={item} index={index} currentAvailableScore={currentAvailableScore} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                    { exclusionError ? (
                                        <div className='text-red-700 font-semibold w-full text-center'>
                                            There must be at least one system in the current selection.
                                        </div>
                                    ) : (<></>)}

                                        {/* <div className={`flex items-center relative cursor-pointer my-4`}>
                                            <div className='grow my-2 border-t border-gray-300 border-2'>
                                            </div>
                            
                                            <div className='mx-4 flex flex-col items-center text-gray-400'>
                                                +
                                            </div>
                                            
                                            <div className='grow my-2 border-t border-gray-300 border-2'>
                                            </div>
                                        </div> */}
                                </div>
                            </div>

                            { includeDragActive ? (
                                <div onDrop={handleInclude} onDragOver={(e) => enterDrop(e, true)} onDragLeave={(e) => leaveDrop(e, true)} className='absolute top-0 left-0 w-full h-[60%] bg-gray-300 z-[2] opacity-50 border-8 border-dashed rounded-lg'>
                                </div>
                                ) : (<></>)
                            }
                
                            <div onClick={toggleExcluded}
                            className={`absolute flex flex-col w-full bg-gray-50 ${excludedVisible ? "top-[60%] h-[40%]" : "bottom-0"}`}>
                                <div className='flex items-center cursor-pointer h-16'>
                                    <div className='grow my-2 border-t border-gray-400 border-4'>
                                    </div>
                    
                                    <div className='mx-4 flex flex-col items-center text-gray-400'>
                                        {
                                            (excludedVisible) ? ("Hide excluded") : ("See excluded systems")
                                        }
                                        
                                        {/* <ArrowDownIcon iconColor='gray' iconHeight='100px' /> */}
                                    </div>
                                    
                                    <div className='grow my-2 border-t border-gray-400 border-4'>
                                    </div>
                                </div>

                                {
                                    (excludedVisible) ? (
                                        <div onDrop={handleExclude} onDragOver={(e) => enterDrop(e, false)} onDragLeave={(e) => leaveDrop(e, false)}
                                        className='relatvie h-full overflow-y-auto w-full space-y-2 pb-4'>
                                            {   
                                                vendorRanking.filter((item:any) => !item.included).slice(0,30).map((item:any, index:number) => {
                                                    return (
                                                        <div draggable={true} onDragStart={(e) => dragStart(e, item.id)}>
                                                            <VendorRankingRow vendorRankingData={item} index={numberIncluded+index} currentAvailableScore={currentAvailableScore} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    ) : (<></>)
                                }
                            </div>

                            { excludeDragActive ? (
                                <div onDrop={handleExclude} onDragOver={(e) => enterDrop(e, false)} onDragLeave={(e) => leaveDrop(e, false)}
                                className='absolute top-[60%] mt-16 w-full h-[40%] pb-20 bg-gray-300 z-[2] opacity-50 border-8 border-dashed rounded-lg'>
                                </div>
                                ) : (<></>)
                            }
                        
                        </div>
                
                        </>
                    )
                }
            </div>
            
            <SidebarRankingInfo vendorRankingData={sidebarOpenWithRankingData} setSidebarOpenWithRankingData={setSidebarOpenWithRankingData} />
        </div>

        {
            endPhase == "finish" ? (
                <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["FINISH_PHASE"]} />
            ) : (<></>)
        }
        
        {
            (endPhase != null && endPhase != "finish") ? (
                <SpeedDial offsetY={excludedVisible ? undefined : "bottom-16"} speedDials={[
                    {tooltip: "Compare systems", onClick:() => handleCompareSystems(), icon: <div className='text-primary w-6 h-6'><AnalysisIcon /></div>},
                    {tooltip: "Update shortlist", onClick:() => handleCompareSystems(), icon:<div className='text-primary w-6 h-6'><SettingsFilledIcon /></div>},
                ]} defaultDials={true} currentContext={endPhase} selectionId={selectionId} reloadTaskTrigger={vendorRanking} />
            ) : (<></>)
        }
        
        
    </div>
  )
}

export default Index