import React, { useCallback, useEffect, useState } from 'react';
import FormModal from './FormModal';
import LoadingSpinner from './LoadingSpinner';
import { useTranslation } from 'react-i18next'

interface props {
    queryOk : boolean, 
    isOpen : boolean,
    setOpen : React.Dispatch<React.SetStateAction<boolean>>,
    handleNavigate : () => void,
    domainSearchError: boolean,
    domainSearchErrorMessage: string,
}

// Vor Übersetzung jetzt in Reaction Funktion Query-Loading mit Übersetzung
//const loadingStatus = ["Exploring your website", "Collecting information about your company", "Analyzing different information sources", "Extracting the most important information", "Deriving your specific requirements", "Exploring ERP vendors", "Collecting functional catalogs", "Matching your requirements with the functional catalogs", "Finding the most suitable system"];

const QueryLoading = ({queryOk, isOpen, setOpen, handleNavigate, domainSearchError, domainSearchErrorMessage} : props) => {
    const { t } = useTranslation();
    const loadingStatus = [t('query.progress_step1'), t('query.progress_step2'), t('query.progress_step3'), t('query.progress_step4'), t('query.progress_step5'), t('query.progress_step6'), t('query.progress_step7'), t('query.progress_step8'), t('query.progress_step9')];
    
    const [loadingPercent, setLoadingPercent] = useState(0.0);
    const [randomLoadingStatus, setRandomLoadingStatus] = useState(loadingStatus[0]);

    const changeText = useCallback(() => {
        // setLoadingTextIdx(prev => { if (prev <= loadingStatus.length) {return prev+1} else {return prev}});
        const MAX_PERCENT = 95;
        const MAX_STEPSIZE_PERCENT = 5;
        setLoadingPercent(prev => {if (prev < MAX_PERCENT) {return prev + (MAX_STEPSIZE_PERCENT*(MAX_PERCENT-prev)/MAX_PERCENT)} else {return MAX_PERCENT}})
        setRandomLoadingStatus(loadingStatus[Math.floor(Math.random() * loadingStatus.length)]);

    }, []);

    let textChangeInterval : any;
    useEffect(() => {
        textChangeInterval = setInterval(changeText, 1000);
        // return () => clearInterval(textChangeInterval);
    }, [changeText])
    
    useEffect(() => {
        if (queryOk) {
            clearInterval(textChangeInterval);
            setOpen(false);
            handleNavigate();
        }
    }, [queryOk])
    
    useEffect(() => {
        if (domainSearchError) {
            clearInterval(textChangeInterval);
        }
    }, [domainSearchError])
    
    return (
        <div className='z-50'>
            <FormModal setOpen={setOpen} isOpen={isOpen} size="regular">
                <div className='w-full flex flex-col h-[150px] max-sm:w-[250px]'>
                    <div className='flex p-5'>
                        <div className="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
                            {
                                (domainSearchError) ? (
                                    <div className="h-6 bg-red-600 rounded-full dark:bg-red-500" style={{width: `60%`}}></div>
                                ) : (
                                    <div className="h-6 bg-blue-600 rounded-full dark:bg-blue-500" style={{width: `${loadingPercent}%`}}></div>
                                )
                            }
                        </div>
                    </div>
                    {
                        (domainSearchError) ? (
                            <div className='w-full flex h-full flex-1'>
                                <p className='m-auto h-max w-3/4 text-center text-sm md:text-sm md:font-bold text-red-500'>{domainSearchErrorMessage}</p>
                            </div>
                        ) : (
                            <div className='w-full flex h-full flex-1'>
                                <p className='m-auto h-max w-3/4 text-center text-md md:text-2xl md:font-bold text-primary'>{randomLoadingStatus}</p>
                            </div>
                        )
                    }
                    
                </div>
            </FormModal>
        </div>
  )
}

export default QueryLoading;