import React from 'react'
import { CheckmarkIcon } from 'swap-frontend-library';

const ParticipantIcon = ({ name, checked } : { name : string, checked? : boolean }) => {
    const splitName = name.trim().split(" ");
    const firstnameInitial = splitName[0][0];
    const lastnameInitial = splitName[splitName.length -1][0];
    return (
        <div className='rounded-full w-14 h-14 flex items-center justify-center bg-gray-100 p-4 relative'>
            <p className='font-semibold'>{firstnameInitial.toUpperCase()}{lastnameInitial.toUpperCase()}</p>
            {
                checked || typeof checked === "undefined" ? <div className='absolute right-0 bottom-0 rounded-full p-1 h-5 w-5 bg-green-200 text-green-600'><CheckmarkIcon /></div> : <></>
            }
        </div>
    )
}

export default ParticipantIcon