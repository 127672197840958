import React from 'react'

interface props {
    header : String[];
    sticky?: boolean
}

const TableHeader = ({header, sticky} : props) => {
  return (
    <tr className={`${sticky ? "sticky top-0 z-[1] bg-white" : ""}`}>
        {
            header.map((text : any, index : number) => {
                return (
                    <th key={index} scope="col" className="px-6 py-3 truncate">
                        {text}
                    </th>
                );
            })
        }
    </tr>
  )
}

export default TableHeader