import React, { useEffect, useState } from 'react';
import NavPagination from '../../../../../components/NavPagination';
import SoftwareProductTable from './tables/SoftwareProductTable';
import AddSoftwareProductForm from './forms/AddSoftwareProductForm';
import FormModal from '../../../../../components/FormModal';
import { useTranslation } from 'react-i18next';
import UploadSoftwareProductFunctions from './forms/UploadSoftwareProductFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import ExportSoftwareProductFunctions from './forms/ExportSoftwareProductFunctions';
import ArrowDownIcon from '../../../../../assets/img/icons/ArrowDownIcon';
import ArrowRightIcon from '../../../../../assets/img/icons/ArrowRightIcon';

const SoftwareEditor = () => {
  const {t} = useTranslation();
  const [productFormOpen, setProductFormOpen] = useState(false);
  const [openProductId, setOpenProductId] = useState("");
  const [openAdvancedOptions, setOpenAdvancedOptions] = useState(false);

  const userRole = useSelector((state: RootState) => state.user.role);

  const handleOpenProduct = (id : string) => {
    setOpenProductId(id);
    setProductFormOpen(true);
  }

  return (
    <div>
      {(productFormOpen) ? (<FormModal isOpen={productFormOpen} setOpen={setProductFormOpen} size="regular"><AddSoftwareProductForm loadProductId={openProductId} successElement={<p>{t("createdSuccessfully")}</p>} onboarding={false} /></FormModal>) : (<div className="hidden"></div>)}
      <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
            Software
          </h1>
        </div>
        <div>
          <SoftwareProductTable openProduct={handleOpenProduct} />
          {
            (userRole == "platform_manager") ? (
              <>
              <div onClick={() => setOpenAdvancedOptions(prev => !prev)} className="cursor-pointer mt-12 flex items-center group">
                <div className='w-5'>
                  <div className={`flex items-center w-[10px] h-[10px] ${(openAdvancedOptions) ? ("rotate-90") : ("")}`}><ArrowRightIcon iconColor='black'/></div>
                </div>
                <h1 className='text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white group-hover:underline'>
                  Erweiterte Funktionen
                </h1>
              </div>
              {
                (openAdvancedOptions) ? (
                  <>
                    <ExportSoftwareProductFunctions />
                    <UploadSoftwareProductFunctions />
                  </>
                ) : (<></>)
              }
              </>
            ) : (<></>)
          }
      </div>
    </div>
  )
}

export default SoftwareEditor