"use client"
import React, { useState, useEffect } from 'react';
import { socket } from './socket';

const SocketInjector = () => {
    
    useEffect(() => {
        console.log("Socket init")
        if (!socket.connected) {
            socket.connect();
        }
        
        const testConnect = (data : { id : string}) => {
            console.log("Successfully tested connection. Websocket id: " + data.id);
        }

        const onDisconnect = () => {
            socket.disconnect();
            console.log("Disconnected");
        }

        socket.on('test connect', testConnect);
        socket.on('disconnect', onDisconnect);
        
        console.log("Socket connected: ", socket.connected)
        socket.emit('test connect');

        return () => {
            socket.disconnect();
            socket.off('test connect', testConnect);
            socket.off('disconnect', onDisconnect);
        };
    }, []);

    return (<></>)
}

export default SocketInjector