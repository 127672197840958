import React, { useEffect, useState } from 'react';
import { fetchVendorData, addVendorInvite } from '../../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';

const AddVendorInviteForm = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState("")
    const [months, setMonths] = useState(0)
    const [vendorDetailsId, setVendorDetailsId] = useState("")
    const [vendorData, setVendorData] = useState([])
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
    const [newInviteCode, setNewInviteCode] = useState();

    useEffect(() => {
        fetchVendorData().then(data => {
            setVendorData(data.data);
        });
    }, [])

    const handleAddInvite = async () => {
        addVendorInvite(email, months, vendorDetailsId).then(data => {
            if (data) {
                setCreatedSuccessfully(true);
                setNewInviteCode(data.data.inviteCode);
            }
        })
    }
   
    return (
    <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">{t("createVendorInvite")}</h3>
        {(createdSuccessfully) ? (<p>{t("createdSuccessfully")} {t("createdInviteCode", {email: email, newInviteCode: newInviteCode})}</p>) : (
            <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">E-Mail</label>
                    <input placeholder="E-Mail" onChange={(event) => {setEmail(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"  required></input>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">{t("inviteValid")}</label>
                    <input placeholder={`${t("inviteValid")}`} onChange={(event) => {setMonths(parseInt(event.target.value))}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"  required></input>
                </div>
                <div>
                    <label htmlFor="countries" className="block text-sm font-medium text-gray-900 dark:text-white">{t("vendorCompany")}</label>
                    <select onChange={(event) => setVendorDetailsId(event.target.value)}
                    id="countries" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" style={{WebkitAppearance: "none"}}>
                        <option value="" selected disabled hidden>{t("chooseHere")}</option>
                        {
                            vendorData.map((item : any, index : number) => {
                                return (<option key={index} value={item.id}>{item.company}</option>);
                            })
                        }
                    </select>
                </div>
                <div className='w-full'><MainButton text={t("save")} link="" func={handleAddInvite} /></div>
            </div>
        )}
        </div>
  )
}

export default AddVendorInviteForm;