import React, { useEffect, useState } from 'react'
import { getMatchingEngineSettings, setMatchingEngineSettings } from '../../../../../../data/SettingsRequests';

const Index = () => {
    const [dummyMode, setDummyMode] = useState<boolean>(false);

    const handleFetchSettings = async () => {
        const setting = await getMatchingEngineSettings("dummy_mode");
        setDummyMode(setting.setting_value.value);
    }
    useEffect(() => {
        handleFetchSettings();
    }, [])

    const handleToggle = async (e : any) => {
        console.log(e.target.checked);
        await setMatchingEngineSettings("dummy_mode", {value: e.target.checked})
        await handleFetchSettings();
    }
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="text-sm font-medium text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
            <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
                Allgemeine Einstellungen
                <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Passen Sie hier die allgemeinen Einstellungen der Matching Engine an.</p>
           </div>
           <div className="p-6 bg-white border-b border-gray-200 grid grid-cols-2 gap-4">
            { (typeof dummyMode === "undefined") ? (<></>) : (
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" checked={dummyMode} onChange={handleToggle} className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Dummy mode</span>
                </label>
            )}
           </div>
        </div>
    </div>
  )
}

export default Index