import React, { useEffect } from 'react'
import PlaceholderSkeleton from '../../../../../components/PlaceholderSkeleton'
import UnderConstructionPlaceholder from '../../../../../components/UnderConstructionPlaceholder'
import { Outlet, useOutletContext } from 'react-router-dom';
import SpeedDial from '../components/SpeedDial';
import SelectionGuideInjector from '../components/SelectionGuideInjector';
import { socket } from '../../../../../_socket/socket';
import PresentationOverview from './features/presentation-overview/PresentationOverview';

const Index = () => {
  const { selectionId, setSelectedSelectionPhase } = useOutletContext<any>();
  useEffect(() => {setSelectedSelectionPhase("presentations")},[])

  return (
    <div className='h-full'>
      <SelectionGuideInjector selectionId={selectionId} acceptedTaskCodes={["pres-gene_shor"]} />
      {/* <Outlet context={{selectionId, selectionPhase, selectionTaskId, setSelectionTaskId}} /> */}
      <Outlet context={{selectionId}} />
      {/* <div onClick={handleTest}>
        Test socket
      </div> */}
      {/* <SpeedDial speedDials={[]} defaultDials={true} currentContext='presentations' selectionId={selectionId} /> */}
    </div>  
  )
}

export default Index