import React from 'react'
import LoadingSpinner from './LoadingSpinner'

interface props {
    textValue : string | null,
    setTextValue : (text: string) => void | React.Dispatch<React.SetStateAction<string | null>>,
    placeholder? : string,
    label? : string,
    icon? : any
    rows? : number
    onBlur?: (e: any) => void
}

const TextInput = ({ textValue, setTextValue, placeholder, label, icon, rows, onBlur } : props) => {
  // if (textValue == null) return <LoadingSpinner />
  return (
    <div className='w-full'>
        {typeof label !== "undefined" ? (<label className="block text-sm font-medium text-gray-900 dark:text-white">{label}</label>) : (<></>) }
        
        <div className='relative flex text-gray-900 items-center'>
            {typeof icon !== "undefined" ? (<div className='absolute text-gray-300 left-2 w-5 h-5'>{icon}</div>) : (<></>)}
            {
              typeof rows === "undefined" ? (
                <input 
                onChange={(e) => setTextValue(e.target.value)}
                onBlur={(e) => {if (typeof onBlur !== "undefined") onBlur(e)}}
                placeholder={placeholder || "Type here..."} type="text" name="input" value={textValue || ""} 
                className={`${(typeof icon !== "undefined") ? ("pl-9") : ("")} bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                />
                ) : (
                <textarea onChange={(e) => setTextValue(e.target.value)}
                onBlur={(e) => {if (typeof onBlur !== "undefined") onBlur(e)}}
                placeholder={placeholder || "Type here..."} value={textValue || ""} rows={rows}
                className={`${(typeof icon !== "undefined") ? ("pl-9") : ("")} bg-gray-50 text-gray-900 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                />
              )
            }
        </div>
    </div>
  )
}

export default TextInput