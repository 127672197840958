import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../../components/MainButton';
import { createClosedBetaInviteCode } from '../../../../../../data/ClosedBetaRequests';

const AddClosedBetaInviteForm = () => {
    const { t } = useTranslation();

    const [note, setNote] = useState("")
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false);
    const [newInviteCode, setNewInviteCode] = useState();

    const handleAddInvite = async () => {
        console.log(note)
        createClosedBetaInviteCode(note).then(data => {
            if (data) {
                setCreatedSuccessfully(true);
                setNewInviteCode(data.data.inviteCode);
            }
        })
    }
   
    return (
    <div>
        <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Closed-beta {t("inviteCode")}</h3>
        {(createdSuccessfully) ? (<p>{t("createdSuccessfully")} {t("inviteCode")}: {newInviteCode}</p>) : (
            <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">{`${t("note")}`}</label>
                    <input placeholder={`${t("note")}`} onChange={(event) => {setNote(event.target.value)}} type="text" name="input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"></input>
                </div>
                <div className='w-full'><MainButton text={t("save")} link="" func={handleAddInvite} /></div>
            </div>
        )}
        </div>
  )
}

export default AddClosedBetaInviteForm;