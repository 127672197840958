import React, { useEffect, useState } from 'react'
import UploadRequirements from './components/UploadRequirements';
import RequirementsTable from './components/RequirementsTable';
import FormModal from '../../../../../../../components/FormModal';
import RequirementForm from './components/RequirementForm';

const Index = () => {
    const [requirementFormOpen, setRequirementFormOpen] = useState(false);
    const [selectedRequirement, setSelectedRequirement] = useState();

    return (
        <>
            {(requirementFormOpen) ? (<FormModal isOpen={requirementFormOpen} setOpen={setRequirementFormOpen} size="regular"><RequirementForm selectedRequirement={selectedRequirement} /></FormModal>) : (<div className="hidden"></div>)}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <RequirementsTable setRequirementFormOpen={setRequirementFormOpen} setSelectedRequirement={setSelectedRequirement} />
                </div>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
                <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <UploadRequirements />
                </div>
            </div>
        </>
    )
}

export default Index