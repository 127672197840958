import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { getCustomerSearches } from '../../../../../data/CustomerSearchRequests';
import { useNavigate } from 'react-router-dom';
import { LoadingSpinner, MainButton, SearchIcon, SettingsFilledIcon } from 'swap-frontend-library';
import QueryFieldSP from '../../../../../components/QueryFieldSP';

interface props {
    setSelectedSearch: React.Dispatch<React.SetStateAction<string>>;
    setStartSelectionFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchTable = ({setSelectedSearch, setStartSelectionFormOpen} : props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [customerSearches, setCustomerSearches] = useState([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);
    
    const handleFetchCustomerSearches = async () => {
        const data = await getCustomerSearches();
        console.log(data)
        setCustomerSearches(data);
    }

    useEffect(() => {
        handleFetchCustomerSearches();
    }, [])


    const handleOpenCustomerSearch = async (customerSearchCustomerDetailsId : string, selection: any, selectionStarted: boolean) => {
        if (!selectionStarted) {
            console.log(customerSearchCustomerDetailsId)
            setSelectedSearch(customerSearchCustomerDetailsId);
            setStartSelectionFormOpen(true);
        } else {
            navigate(`/user-area/selection-portal/${selection.id}`);
        }
        // navigate(`/search/result?customerSearchId=${id}`);
    }

    return (
    <div className="relative overflow-x-auto mt-5">
        <div className="mb-4 col-span-full xl:mb-2">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl">
              Software-Matchings
          </h1>
        </div>

        <div className='py-4 flex flex-col gap-4'>
            {
                typeof customerSearches === "undefined" ? <LoadingSpinner/> : customerSearches.length == 0 ? (
                    <div className='w-full bg-white border rounded-lg h-96 flex flex-col items-center justify-center'>
                        <div className='w-10 h-10 mb-4 text-gray-400'><SearchIcon /></div>
                        <p className='text-gray-600'>No software matchings conducted yet. Carry out a software matching.</p>
                        <div className='w-1/2 mt-8'><QueryFieldSP /></div>
                    </div>
                ) : (
                    <>
                    {/* `${t("queryUrl")}`, `${t("searchType")}`, `Selection started`, `${t("createdOn")}` */}
                    {
                        customerSearches.map(({id, selection, customerSearch, freeSearch, selectionStarted, createdDate} : any, index : number) => {
                            const createdDateParsed : Date = new Date(Date.parse(createdDate));
                            const createdDateString : string = `${createdDateParsed.getDate()}.${createdDateParsed.getMonth()+1}.${createdDateParsed.getFullYear()}`;
                            return (
                                <div key={index} className='w-full bg-white rounded-lg border p-4 flex items-center justify-between'>
                                    <div className='items-center gap-4'>
                                        <div className='text-sm font-light'>{createdDateString}</div>
                                        <div className='text-2xl font-semibold'>{customerSearch.queryUrl}</div>
                                        {/* (freeSearch == "1") ? ("Free") : ("Paid") */}
                                    </div>

                                    <div className='flex items-center gap-4'>
                                        <div><MainButton text={'Matching results'} icon={<div className='w-6 h-6 text-white'><SearchIcon /></div>} linkNewTab={true} link={`${process.env.REACT_APP_LANDING_PAGE}/search/results?url=${customerSearch.queryUrl}&code=${customerSearch.unlockCode}`} /></div>
                                        {
                                            (selectionStarted == "1") ? (
                                                <></>
                                            ) : (
                                                <div><MainButton text={'Start selection'} icon={<div className='w-6 h-6 text-white'><SettingsFilledIcon /></div>} func={() => {handleOpenCustomerSearch(id, selection, selectionStarted)}} /></div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='border-t pt-8 mt-8 w-full flex flex-col items-center justify-center'>
                        <p className='text-2xl font-semibold mb-4'>Start new matching</p>
                        <div className='w-1/2 mt-2'><QueryFieldSP /></div>
                    </div>
                    </>
                )
            }
        </div>
    </div>
  )
}

export default SearchTable