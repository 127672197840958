import React, { useState } from 'react'

interface props {
    rowItems : any[],
    maxRowWidths? : string[],
    deleteHandler? : () => void;
    additionalStyles?: string
}

const TableRow = ({rowItems, deleteHandler, maxRowWidths, additionalStyles} : props) => {
    const [renderedItems, setRenderedItems] = useState(rowItems);
    const [edit, setEdit] = useState(false);

    const handleEdit = (event : React.MouseEvent<HTMLElement>, restore : boolean) => {
        event.preventDefault();

        if (restore) { setRenderedItems(rowItems);}
        setEdit(prev => !prev);
    }

    const handleChange = (event : React.FormEvent<HTMLInputElement>, index : number) => {
        let tempArray = [...renderedItems];
        tempArray[index] = event.currentTarget.value;
        setRenderedItems([...tempArray]);
    }

    return (
        <tr key={`${rowItems[0]}`} className={`bg-white border-b dhover:bg-gray-50 ${additionalStyles || ""}`}>
            
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 max-w-[500px] truncate whitespace-nowrap">
                {rowItems[1]}
            </th>
            {
                rowItems.slice(2).map((item : any, index : number) => {
                    return (
                        <td key={index} className="px-6 py-4">
                            <div className={`bg-inherit w-fit ${(typeof maxRowWidths !== "undefined" && maxRowWidths[index+2]) ? maxRowWidths[index+2] : "max-w-[200px]"} truncate whitespace-nowrap`}>{item}</div>
                        </td>
                    )
                })    
            }
            {
                (typeof deleteHandler !== "undefined") ? (
                    <td><div className='cursor-pointer text-primary' onClick={(e) => {e.stopPropagation(); deleteHandler();}}>Delete</div></td>
                ) : (<></>)
            }
        </tr>
    )
}

export default TableRow