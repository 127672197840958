export async function getAllClosedBetaInviteCodes() {
    
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/closed-beta/get-all-invite-codes`, options)
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getAllClosedBetaInviteCodes: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`getAllClosedBetaInviteCodes: Error occurred ${error}`);
    }
}

export async function getSPInviteCode(url: string) {
    
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/closed-beta/hash?url=${url}`, options)
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`getSPInviteCode: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`getSPInviteCode: Error occurred ${error}`);
    }
}

export async function createClosedBetaInviteCode(note : string) {
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
            note : note
        }),
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/closed-beta/create-invite-code`, options)
        
        if (res.ok) {
            console.log("Created vendor invite successfully");
            const data = await res.json()
            return data;
        } else {
            console.error(`createClosedBetaInviteCode: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`createClosedBetaInviteCode: Error occurred ${error}`);
    }
}

export async function deleteClosedBetaInviteCode(inviteCode: string) {
    const payload = {
        inviteCode: inviteCode,
    };
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }
    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/closed-beta/delete-invite-code`, options)
        
        if (res.ok) {
            console.log("Deleted vendor invite successfully");
            return true;
        } else {
            console.error(`deleteClosedBetaInviteCode: Bad response ${res.status}`);
            return false;
        }
        
    } catch (error) {
        console.error(`deleteClosedBetaInviteCode: Error occurred ${error}`);
        return false;
    }
}