import React, { useEffect, useState } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

const Index = () => {
  const { selectionId, selectionPhase, setSelectedSelectionPhase, selectionTaskId, setSelectionTaskId } = useOutletContext<any>();
  useEffect(() => {setSelectedSelectionPhase("contracts");}, [])
  
  return (
      <Outlet context={{selectionId, selectionPhase, selectionTaskId, setSelectionTaskId}} />
  )
}

export default Index