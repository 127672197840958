import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getVendorLeads, updateVendorLeads } from '../../../../../data/LeadRequests';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import LeadRow from './LeadRow';

interface props {
    children: JSX.Element,
}

const LeadsTable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [vendorLeads, setVendorLeads] = useState([]);
    const [openLeadsIdxs, setOpenLeadsIdxs] = useState<string[]>([]);

    const cookies = new Cookies();
    
    const handleFetchVendorLeads = async () => { setVendorLeads(await getVendorLeads()); }
    const handleUpdateAndFetchVendorLeads = async () => { await updateVendorLeads(); handleFetchVendorLeads(); }

    console.log(vendorLeads)

    useEffect(() => {
        handleUpdateAndFetchVendorLeads();
    }, [])

    const handleOpenAccordion = (openLeadId : string) => {
        if (openLeadsIdxs.includes(openLeadId)) {
            setOpenLeadsIdxs(openLeadsIdxs.filter(item => item != openLeadId));
        } else {
            setOpenLeadsIdxs(prev => [...prev, openLeadId])
        }
    }

    const handleUnlockLead = (leadId : string) => {
        navigate(`unlock?leadId=${leadId}`);
    }

    if (typeof vendorLeads === "undefined" || vendorLeads === null) {
        return (<LoadingSpinner />)
    }
    return (
    <div className="relative overflow-x-auto mt-5 shadow-md bg-white sm:rounded-lg">
        <Outlet context={[handleFetchVendorLeads]} />
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {t("leads")}
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Sehen Sie an, welche Kunden mit Ihrem System gematcht wurden.</p>
        </div>


        <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700"></div>
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("newLeads")}</p>
        </div>
        <div className='ml-4 mr-4 pb-2'>
            <LeadRow vendorLeads={vendorLeads.filter((lead: any) => !lead.leadPurchased)} handleOpenAccordion={handleOpenAccordion} openLeadsIdxs={openLeadsIdxs} handleUnlockLead={handleUnlockLead} />
        </div>
        
        <div className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700"></div>
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("purchasedLeads")}</p>
        </div>
        <div className='ml-4 mr-4 pb-2'>
            <LeadRow vendorLeads={vendorLeads.filter((lead: any) => lead.leadPurchased)} handleOpenAccordion={handleOpenAccordion} openLeadsIdxs={openLeadsIdxs} handleUnlockLead={handleUnlockLead} />
        </div>
    </div>
  )
}

export default LeadsTable