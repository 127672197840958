import React from 'react'
import ArrowRightIcon from '../../../../../../../assets/img/icons/ArrowRightIcon'

interface props {
    item: any,
    handleSelectRequirementTemplate: (item:any) => void,
    isSelectionRequirement: boolean,
}

const RequirementInputWizardRow = ({item, handleSelectRequirementTemplate, isSelectionRequirement} : props) => {
  return (
    <div 
    className={`p-2 text-gray-900 ${isSelectionRequirement ? "bg-gray-200 border-white" : "hover:text-primary hover:bg-primary2 cursor-pointer"} border-b`} 
    onClick={() => {if (!isSelectionRequirement) handleSelectRequirementTemplate(item)}}>
        <div className='flex justify-between'>
            <div className='flex italic'>
                {item.category[0] ? <><p className='px-2 text-sm truncate max-w-32'>{item.category[0]}</p></> : <></>}
                {item.category[1] ? <><div className='flex items-center'><ArrowRightIcon iconWidth='10' iconColor='currentColor' /></div><p className='px-2 text-sm truncate'>{item.category[1]}</p></> : <></>}
                {item.category[2] ? <><div className='flex items-center'><ArrowRightIcon iconWidth='10' iconColor='currentColor' /></div><p className='px-2 text-sm truncate'>{item.category[2]}</p></> : <></>}
            </div>
            {isSelectionRequirement ? <><p className='px-2 text-sm truncate'>{item.archived ? <p className='text-orange-400'>archived</p> : <p className='text-green-600'>active</p>}</p></> : <></>}
        </div>
        <p className='w-full px-2 text-md truncate'>{item.requirement}</p>
    </div>
  )
}

export default RequirementInputWizardRow