import React, { useEffect, useState } from 'react'
import BigRingChartFilter from '../components/charts/BigRingChartFilter'
import BigRingChartFilterWorking from '../components/charts/BigRingChartFilterWorking'
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { getScenarioStatusStatistics } from '../../../../../../data/SelectionPortalRequests';

interface props {
    selectionId: string
}

const WebcastsDonut = ({selectionId} : props) => {
    const [labels, setLabels] = useState<string[]>();
    const [data, setData] = useState<number[]>();

    // green, dark green, gray, yellow
    const colors = ['rgb(163, 163, 163)', 'rgb(134, 239, 172)', 'rgb(22, 163, 74)', 'rgb(251, 191, 36)'];

    const handleFetchData = async () => {
        // const response = await getScenarioStatusStatistics(selectionId);
        // if (response.ok) {
        //     console.log(Object.keys(response.data.data.scenarioStatusCounts));
        //     setLabels(Object.keys(response.data.data.scenarioStatusCounts));
        //     setData(Object.values(response.data.data.scenarioStatusCounts));
        //     setNumScenarios(response.data.data.numScenarios);
        // }
        console.log("Not implemented")
        setLabels(["Open", "Scheduled", "Completed"]);
        setData([24,0,0]);
    }
    useEffect(() => {handleFetchData()}, [])
    
  return (
    <div className='p-4 h-full bg-white rounded-lg shadow sm:p-6'>
        {
            (typeof labels === "undefined" || typeof data === "undefined") ? (<LoadingSpinner />) : (
                <BigRingChartFilterWorking subTitle={'Scenarios'} title={`${data.reduce((partialSum, a) => partialSum + a, 0)} webcasts planned`} labels={labels} data={data} colors={colors} />
            )
        }
    </div>
  )
}

export default WebcastsDonut