import React, { SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';

interface props {
    topCategories : any[],
    visibleCategoryIndex: number,
    currentSubCategories: any[],
    visibleSubCategoryIndex: number,
    filteredSoftwareFulfillment: any[],
    changedRequirementScores: any[],
    setChangedRequirementScores: React.Dispatch<SetStateAction<any>>,
}

const FulfillmentTable = ({topCategories, visibleCategoryIndex, currentSubCategories, visibleSubCategoryIndex, filteredSoftwareFulfillment, changedRequirementScores, setChangedRequirementScores} : props) => {
    const {t} = useTranslation();

    return (
    <>
        <div className="p-5 text-md font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {topCategories[visibleCategoryIndex]} - {currentSubCategories[visibleSubCategoryIndex]}
        </div>

        <table className="w-full text-sm text-left text-gray-500 table-fixed dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    {/* <th scope="col" className="px-6 py-3 w-[20%] max-sm:hidden">
                        {t("category")}
                    </th> */}
                    <th scope="col" className="px-6 py-3">
                        {t("requirement")}
                    </th>
                    <th scope="col" className="w-[20px]">
                    </th>
                    <th scope="col" className="px-6 py-3 w-[30%]">
                        {t("score")}
                    </th>
                </tr>
            </thead>
            {
                filteredSoftwareFulfillment.map(({
                    fulfillmentRequirement_id,
                    requirement_id, 
                    requirement_category, 
                    requirement_description, 
                    requirement_requirement, 
                    fulfillmentRequirement_score} : any, index : number) => {

                    // let subCategories = JSON.parse(requirement_category).slice(2).toString();
                    // if (subCategories == ",") { subCategories = ""};

                    return (
                        <tbody key={`tbody-${index}`}>
                            <tr key={`tr-${index}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">                                
                                {/* <th scope="row" className="break-words hyphens-auto pl-6 py-4 font-light text-gray-900 dark:text-white max-sm:hidden">
                                {subCategories}
                                </th> */}
                                <th scope="row" className="break-words pl-6 py-4 font-medium text-gray-900 xl:max-w-xl md:max-w-md sm:max-w-xs dark:text-white">
                                    {requirement_requirement}
                                </th>
                                <th scope="row" className="cursor-pointer font-medium text-gray-900 dark:text-white">
                                    <InfoIcon iconColor='gray' />
                                </th>
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {/* <input id="minmax-range" type="range" min="0" max="4" step="1" defaultValue="0" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"></input> */}
                                    {/* fulfillmentRequirement_score */}
                                    <select value={(changedRequirementScores[fulfillmentRequirement_id] == 0) ? (0) : (changedRequirementScores[fulfillmentRequirement_id]) || fulfillmentRequirement_score} onChange={(event) => {setChangedRequirementScores({...changedRequirementScores, [fulfillmentRequirement_id]: Number(event.target.value)})}}
                                    id="company" className={`border ${(fulfillmentRequirement_score == -1 && !Object.keys(changedRequirementScores).includes(fulfillmentRequirement_id)) ? ("bg-red-50 border-red-300") : ("bg-gray-50 border-gray-300")} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`} style={{WebkitAppearance: "none"}}>
                                        <option key={`o0-index`} value={-1} disabled hidden>Choose here</option>
                                        <option key={`o1-index`} value={0}>Not fulfilled</option>
                                        <option key={`o2-index`} value={1}>Feature planned</option>
                                        <option key={`o3-index`} value={2}>Fulfilled with additional module</option>
                                        <option key={`o4-index`} value={3}>Fully fulfilled</option>
                                    </select>
                                </th>
                            </tr>
                        </tbody>
                    )
                })
            }
        </table>
    </>
  )
}

export default FulfillmentTable