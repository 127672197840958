import React, { useEffect, useState } from 'react';
import TableHeader from '../../../../../../components/TableHeader';
import TableRow from '../../../../../../components/TableRow';
import NavPagination from '../../../../../../components/NavPagination';
import { useTranslation } from 'react-i18next';
import SearchBar from '../../../../../../components/SearchBar';
import LoadingSpinner from '../../../../../../components/LoadingSpinner';
import { deleteCustomerAccount, fetchCustomerAccountData } from '../../../../../../data/UserCustomerRequests';
import RefreshIcon from '../../../../../../assets/img/icons/RefreshIcon';
import DeleteModal from '../../../../../../components/DeleteModal';
import InfoIcon from '../../../../../../assets/img/icons/InfoIcon';

const CustomersTable = () => {
    const { t } = useTranslation();
    
    const [customerData, setCustomerData] = useState([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [visibleRows, setVisibleRows] = useState([1,1]);
    const [ loaded, setLoaded ] = useState(false); // temporary -> TODO: Implement fuzzy search

    const handleFetchCustomerData = () => {
        fetchCustomerAccountData().then(data => {setCustomerData(data.data); setFilteredData(data.data); setLoaded(true)})
    }

    useEffect(() => {
        handleFetchCustomerData();
    }, [])

    const [deleteItem, setDeleteItem] = useState<any>(undefined);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteModalValidation, setDeleteModalValidation] = useState("");
    const handleOpenDeleteModal = async (deleteItem: any) => {
        setDeleteItem(deleteItem);
        setDeleteModalOpen(true);
    }

    const handleDeleteVendorAccount = async (id: string) => {
        await deleteCustomerAccount(id);
        handleFetchCustomerData();
        setDeleteModalOpen(false);
        setDeleteItem(undefined);
    }

    return (
    <div className="relative overflow-x-auto mt-5 shadow-md sm:rounded-lg">
        {deleteModalOpen ? <DeleteModal isOpen={deleteModalOpen} setOpen={setDeleteModalOpen} deleteHandler={() => handleDeleteVendorAccount(deleteItem.id)} content={
            <div>
                <p className='mb-3'>Soll der Anbieter {deleteItem.email} wirklich gelöscht werden?</p>
            </div>
        } /> : <></>}
        <div className="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            {t("customerAccounts")}
            <p className="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">{t("totalItems", {length: customerData.length, itemType: `${t("vendor")}`})}</p>
        </div>
        
        <div
            className="p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700"
        >
            <div className="w-full mb-1">
                <div className="items-center justify-between h-[40px] block sm:flex">
                    <div className='sm:w-64 xl:w-96'>
                        <SearchBar translatedPlaceholderItem={`E-Mail`} originalData={customerData} setFilteredData={setFilteredData} filterKey={"email"} />
                    </div>
                    
                    <div className="flex items-center h-full ml-auto space-x-2 sm:space-x-3">
                        <button
                        onClick={() => handleFetchCustomerData()}
                        type="button"
                        data-refresh
                        className="inline-flex items-center justify-center h-full w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                        >
                        <RefreshIcon iconColor='white' />
                        {t("refresh")}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        {(!loaded) ? (<LoadingSpinner />) : (
            <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <TableHeader header={[`${t("companyName")}`, "E-Mail", `${t("firstname")}`, `${t("lastname")}`, `${t("createdOn")}`, ""]} />
                </thead>
                {
                    (filteredData.length == 0) ? (<tbody className='text-center'><tr><td colSpan={5} className='h-16'>No customer accounts available...</td></tr></tbody>) : (
                        filteredData.slice(visibleRows[0]-1,visibleRows[1]).map(({id, customerDetails, email, createdDate} : any, index : number) => {
                            const date : Date = new Date(Date.parse(createdDate));
                            const dateString : string = `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
                            return (
                                <tbody>
                                    <TableRow 
                                        rowItems={[index, customerDetails.company, email, "Lorem Ipsum", "Lorem Ipsum", dateString]} 
                                        deleteHandler={() => handleOpenDeleteModal({id, email})}
                                    />
                                </tbody>
                            )
                        })
                    )
                }
            </table>
            {/* <NavPagination totalItems={1000} visibleRows={[0,10]} setVisibleRows={undefined} handleChange={() => {}} /> */}
            <NavPagination totalItems={filteredData.length} visibleRows={visibleRows} setVisibleRows={setVisibleRows} />
            </>
            )
        }
    </div>
  )
}

export default CustomersTable