import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { TextInput } from 'swap-frontend-library'
import { RootState } from '../../../../../../../../redux/store';
import { updatePresentationAgenda } from '../../../../../../../../data/SelectionPortalRequests';

const SidebarAgenda = ({presentationId, meetingItem, handleGetSelectionPresentation} : {presentationId : string, meetingItem : any, handleGetSelectionPresentation : () => {}}) => {
  const projectRole = useSelector((state: RootState) => state.selection.projectRole);
  const [textValue, setTextValue] = useState<string | null>(meetingItem.agenda);
  const editingLocked = projectRole != "selection_admin";

  const handleSaveAgenda = async () => {
    if (textValue != null) {
      await updatePresentationAgenda(presentationId, textValue);
      handleGetSelectionPresentation();
    }
  }

  return (
    <div className='flex flex-col gap-4 w-96'>
        <textarea 
          disabled={editingLocked} 
          rows={10} 
          onBlur={() => handleSaveAgenda()} className="w-full rounded tracking-tight leading-6 text-gray-900" 
          value={textValue != null ? textValue : ""}
          placeholder="Click here to insert your agenda."
          onChange={(e) => setTextValue(e.target.value)}
        />
    </div>
  )
}

export default SidebarAgenda