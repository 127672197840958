import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './SearchProcessSelection.css';

interface props {
    processStep : number,
    steps: string[],
    style?: string,
    links?: string[]
}

const SearchProcessSection = ({processStep, steps, style, links} : props) => {
    const { t } = useTranslation();

    return (
        <div className={`${style || "max-w-[854px] px-4 lg:px-5 mx-auto mt-10 max-sm:mt-8 timeline"}`}>
            <div className="flex justify-between items-stretch">

                {
                    steps.map((step:string, idx:number) => (
                        <Link to={typeof links !== "undefined" ? links[idx] : "#"} className={`w-full text-center flex flex-col items-center justify-start ${(processStep >= idx) ? "timeline-active" : ""}`} >
                            <h4 className="w-6 h-6 rounded-full border border-secondary4 flex items-center justify-center text-xs font-semibold text-secondary4 bg-white">{idx+1}</h4>
                            <p className="font-medium text-secondary4 mt-2 max-md:text-xs max-w-xmd:text-9">{step}</p>
                        </Link>
                    ))
                }
                
                {/* <a href="#" className={`w-full text-center flex flex-col items-center justify-start ${(processStep >= 4) ? "timeline-active" : ""}`} >
                    <h4 className="w-6 h-6 rounded-full border border-secondary4 flex items-center justify-center text-xs font-semibold text-secondary4 bg-white">4</h4>
                    <p className="font-medium text-secondary4 mt-2  max-md:text-xs max-w-xmd:text-9">{t("guidedSelection")}</p>
                </a> */}
            </div>
        </div>
  )
}

export default SearchProcessSection