import React from 'react'
import { Link } from 'react-router-dom';

interface Props {
    text: string;
    number: number;
    link: string;
    activePage: number;
}

const HorizontalNavigationBalls = ({text, number, link, activePage}: Props) => {

    return (
        <Link to={link}>
            <div className="flex items-center gap-2 p-4 max-sm:px-[8.8px] max-[400px]:pl-0">
                <span className={`w-[30px] h-[30px] rounded-full text-xs font-semibold flex items-center justify-center border ${(number == activePage) ? ("text-white bg-primary border-primary") : ("text-secondary4 bg-white border-secondary4")}`}>
                    {number}
                </span>
                <p className={`w-[90px] md:w-[90%] text-base font-medium max-md:text-sm max-sm:text-xs whitespace-nowrap truncate ${(number == activePage) ? ("text-secondary1") : ("text-secondary4")}`}>
                    {text}
                </p>
            </div>
        </Link>
    )
}

export default HorizontalNavigationBalls