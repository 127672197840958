export async function fetchVendorData() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-vendors`, options);
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`fetchVendorData: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchVendorData: Error occurred ${error}`);
    }
}

export async function fetchVendorAccountData() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-vendor-accounts`, options);
        
        if (res.ok) {
            const data = res.json();
            return data;
        } else {
            console.error(`addVendorAccount: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`addVendorAccount: Error occurred ${error}`);
    }
}

export async function addVendorAccount(email: string, password: string, vendorDetailsId: string) {
    // TODO: Besserer Fehlerabfang bei schlechtem Input
    if (!email || !password || !vendorDetailsId) {console.error(`uploadImageToBackend: Faulty input`); return false;}

    const payload = {
        email: email,
        password: password,
        vendorDetails: vendorDetailsId
    };
    console.log(payload);
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/register-vendor-manager`, options)
        
        if (res.ok) {
            console.log("Created vendor account successfully");
            return true;
        } else {
            console.error(`addVendorAccount: Bad response ${res.status}`);
            return false;
        }

    } catch (error) {
        console.error(`addVendorAccount: Error occurred ${error}`);
        return false;
    }
}

export async function addVendorDetails(company: string, companyUrl: string) {
    if (!company) {return false;}

    const payload = {
        company: company,
        vendorUrl: companyUrl
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/create-vendor-details`, options)
        
        if (res.ok) {
            console.log("Created vendor successfully");
            return true;
        } else {
            console.error(`addVendorDetails: Bad response ${res.status}`);
            return false;
        }

    } catch (error) {
        console.error(`addVendorDetails: Error occurred ${error}`);
        return false;
    }
}

export async function fetchVendorInviteData() {
    
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/get-vendor-invites`, options)
        
        if (res.ok) {
            const data = await res.json();
            return data;
        } else {
            console.error(`fetchVendorInviteData: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchVendorInviteData: Error occurred ${error}`);
    }
}

export async function addVendorInvite (email: string, months: number, vendorDetailsId: string) {
    // TODO: Besserer Fehlerabfang bei schlechtem Input
    if (!email || !months || !vendorDetailsId) {console.error(`addVendorInvite: Faulty input`); return;}
    if (months > 120) {console.error(`addVendorInvite: Faulty input`); return;}

    const payload = {
        email: email,
        months: months,
        vendorDetailsId: vendorDetailsId
    };
    console.log(payload);
    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/create-vendor-invite`, options)
        
        if (res.ok) {
            console.log("Created vendor invite successfully");
            const data = await res.json()
            return data;
        } else {
            console.error(`addVendorInvite: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`addVendorInvite: Error occurred ${error}`);
    }
}

export async function deleteVendorInvite (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/delete-vendor-invite`, options)
        if (res.ok) {
            console.log("deleteVendorInvite: Deleted vendor invite successfully");
        } else {
            console.error(`deleteVendorInvite: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`deleteVendorInvite: Error occurred ${error}`);
    }
}

export async function registerVendor(inputEmail : string, inputPassword : string, inputCompanyName : string, inputInvite : string ) {
    const registrationDetails = {
        email: inputEmail,
        password: inputPassword,
        companyName: inputCompanyName,
        inviteCode: inputInvite
    };

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(registrationDetails),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/auth/register-vendor`, options);
    
        if (res.ok) {
            const data = await res.json();
            return {success: true, status: res.status};
        } else {
            console.error(`registerVendor: Bad response ${res.status}`);
            return {success: false, status: res.status};
        }
    } catch(error) {
        console.error(`registerVendor: Error occurred ${error}`);
        return {success: false, status: undefined};
    }
}

export async function deleteVendorAccount (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/delete-vendor-account`, options)
        if (res.ok) {
            console.log("deleteVendorAccount: Deleted vendor account successfully");
        } else {
            console.error(`deleteVendorAccount: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`deleteVendorAccount: Error occurred ${error}`);
    }
}

export async function deleteVendorDetails (id: string) {
    const payload = {
        id: id,
    };
    
    const options : RequestInit = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND}/api/user/delete-vendor-details`, options)
        if (res.ok) {
            console.log("deleteVendorDetails: Deleted vendor details successfully");
        } else {
            console.error(`deleteVendorDetails: Bad response ${res.status}`);
        }

    } catch (error) {
        console.error(`deleteVendorDetails: Error occurred ${error}`);
    }
}