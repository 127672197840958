import React, { useState } from 'react'
import MainButton from './MainButton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryUrl, queryUrlTestingMode } from '../data/CustomerSearchRequests';
import SearchIcon from '../assets/img/icons/SearchIcon';
import QueryLoading from './QueryLoading';

const QueryFieldSP = () => {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const [queryUrlInput, setQueryUrlInput] = useState("");
    

    const handleQuerySubmit = () => {
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE}/search?url=${queryUrlInput}`;
    }
  return (
    <div className={`p-2 pl-0 border border-secondary6 rounded-lg flex items-center justify-between bg-white max-sm:items-start max-sm:items-start max-sm:p-2`}>
        <input onKeyDown={(event) => {if (event.key == "Enter") {handleQuerySubmit()}}} onChange={(event) => {setQueryUrlInput(event.target.value)}}
        type="search" name="search" id="search" placeholder={`${t('query.pasteCompanyUrl')}`} className="bg-transparent outline-none border-none placeholder:text-secondary4 placeholder:text-sm font-normal px-6 w-4/5 max-sm:h-11 max-sm:w-full max-sm:p-0"></input>
        <MainButton text={`${t('query.matchButton')}`} text_location='left' icon={<SearchIcon iconColor='white' />} link="" func={handleQuerySubmit}/>
    </div>
  )
}

export default QueryFieldSP