import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { registerVendor } from '../../../../../data/UserVendorRequests';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../components/MainButton';
import EyeClosedIcon from '../../../../../assets/img/icons/EyeClosedIcon';
import EyeOpenIcon from '../../../../../assets/img/icons/EyeOpenIcon';
import ArrowRightIcon from '../../../../../assets/img/icons/ArrowRightIcon';

interface props {
    successDestination : string
}

const SignupVendorForm = ({successDestination} : props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [inputInvite, setInputInvite] = useState("");
    const [inputCompanyName, setInputCompanyName] = useState("");
    const [inputEmailError, setInputEmailError] = useState(false);
    const [inputPasswordError, setInputPasswordError] = useState(false);
    const [inputInviteError, setInputInviteError] = useState(false);
    const [invalidInvite, setInvalidInvite] = useState(false);
    const [emailExistsError, setEmailExistsError] = useState(false);
    // const [inputCompanyError, setInputCompanyError] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const submitHandler = async () => {
        setRegistrationError(false);

        let inputError = false;
        if (!inputEmail) {setInputEmailError(true); inputError = true;}
        if (!inputPassword || inputPassword.length < 8) {setInputPasswordError(true); inputError = true;}
        if (!inputInvite) {setInputInviteError(true); inputError = true;}
        if (inputError) {setRegistrationError(true); return;}

        const res = await registerVendor(inputEmail, inputPassword, inputCompanyName, inputInvite);
        // const res = {success: true, status:200}
        
        if (res.success) {
            navigate(successDestination);
        } else {
            if (res.status == 409) {
                setEmailExistsError(true);
            } else if (res.status == 401) {
                setInvalidInvite(true);
                setRegistrationError(true);
            }
        }
    }
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  return (
    <div>
        <form action="" className="flex flex-col gap-6">
                        
        {/* <div className="">
            <input onChange={(event) => {setInputCompanyError(false); setInputCompanyName(event.target.value)}}
            type="text" placeholder="Company name*" className={`w-full text-base outline-none border ${(inputCompanyError) ? ("border-red-500") : ("border-secondary6")} bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
        </div> */}

        <div className="">
            <input onChange={(event) => {setInputEmailError(false); setInputEmail(event.target.value)}}
            type="email" placeholder="E-Mail*" className={`w-full text-base outline-none border ${(inputEmailError) ? ("border-red-500") : ("border-secondary6")} bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
        </div>
        
        <div className="relative flex">
            <input onChange={(event) => {setInputPasswordError(false); setInputPassword(event.target.value)}}
            type={showPassword ? "text" : "password"} placeholder={`${t("password")}*`} className={`w-10/12 text-base outline-none border ${(inputPasswordError) ? ("border-red-500") : ("border-secondary6")} bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
            <button
                type="button"
                className="absolute w-2/12 top-1/2 right-0 transform -translate-y-1/2 text-secondary4"
                onClick={togglePasswordVisibility}
            >
                {showPassword ? (
                    <div className='w-full px-4'><EyeOpenIcon iconColor='currentColor' /></div>
                    ) : (
                    <div className='w-full px-4'><EyeClosedIcon iconColor='currentColor' /></div>
                )}
            </button>
        </div>

        {/* <div className="">
            <input 
            type="number" placeholder="Phone number" className="w-full text-base outline-none border border-secondary6 bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2"></input>
        </div> */}

        <div className="">
            <input onChange={(event) => {setInvalidInvite(false); setInputInviteError(false); setInputInvite(event.target.value)}}
            type="text" placeholder={`${t("inviteCode")}*`} className={`w-full text-base outline-none border ${(inputInviteError || invalidInvite) ? ("border-red-500") : ("border-secondary6")} bg-transparent text-secondary1 rounded-md placeholder:text-sm focus:border-primary focus-within:border px-3 py-2`}></input>
        </div>

        <div className='w-full'>
            <MainButton text={t("signUp")} icon={<ArrowRightIcon />} link="" func={submitHandler} />
        </div>

        {registrationError ? (
            <div className='w-full'>
                <p className='text-xs italic text-rose-500 text-center'>{t("correctFields")}
                {(inputPasswordError) ? (` ${t("passwordCharacters")}`) : ("")}
                </p>
            </div>
        ) : (<p className='hidden'></p>)}
        
        {emailExistsError ? (
            <div className='w-full'>
                <p className='text-xs italic text-rose-500 text-center'>{t("emailExists")}</p>
            </div>
        ) : (<p className='hidden'></p>)}
        
        {invalidInvite ? (
            <div className='w-full'>
                <p className='text-xs italic text-rose-500 text-center'>{t("invalidInvite")}</p>
            </div>
        ) : (<p className='hidden'></p>)}

    </form>
    <p className="text-base text-secondary4 max-w-max mt-5">
        {t("requestInviteCode1")}
        <Link to={`#`} className="text-primary hover:drop-shadow hover:shadow-primary" > {t("requestInviteCode2")}</Link>
        .
    </p>
    <p className="text-base text-secondary4 max-w-max mt-5">
        {t("notAVendor")}
        <Link to={`/signin`} className="text-primary hover:drop-shadow hover:shadow-primary" > {t("clickHere").toLocaleLowerCase()}</Link>
    </p>
    </div>
  )
}

export default SignupVendorForm