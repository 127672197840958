import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

interface props {
    translatedPlaceholderItem: string,
    originalData: any[],
    setFilteredData: React.Dispatch<React.SetStateAction<any[]>>,
    filterKey: string,
}

const SearchBar = ({translatedPlaceholderItem, originalData, setFilteredData, filterKey} : props) => {
    const {t} = useTranslation();
    const [searchText, setSearchText] = useState("");

    const handleInput = (e : any) => {
        setSearchText(e.target.value)
        const tempData = originalData.filter((obj : any) => {
            return obj[filterKey].toLowerCase().includes(e.target.value.toLowerCase())
        });
        setFilteredData([...tempData]);
    }

  return (
    <div className="flex items-center mb-4 sm:mb-0 w-full">
        <form className="sm:pr-3 w-full" action="#" method="GET">
        <label htmlFor="search" className="sr-only">Search</label>
        {/* sm:w-64 xl:w-96 */}
        <div className="relative w-48 w-full">
            <input
            onChange={handleInput}
            value={searchText}
            type="text"
            id="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder={`${t("searchForPlaceholder", {itemType: translatedPlaceholderItem})}`}
            />
        </div>
        </form>
    </div>
  )
}

export default SearchBar