import React from 'react'
import FormModal from './FormModal'
import MainButton from './MainButton';
import { useTranslation } from 'react-i18next';

interface props {
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    deleteHandler: () => void,
    content : JSX.Element;
    disabled?: boolean
}

const DeleteModal = ({isOpen, setOpen, deleteHandler, content, disabled} : props) => {
    const {t} = useTranslation();
  return (
    <FormModal isOpen={true} setOpen={setOpen} size="regular">
        <div>
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">{t("delete")}</h3>
            <div className="max-h-[75vh] overflow-y-auto p-2 space-y-6">
                {content}
            </div>
            <div className='flex justify-center mt-3'>
                <MainButton text={t("delete")} link='' func={deleteHandler} disabled={disabled} />
            </div>
        </div>
    </FormModal>
  )
}

export default DeleteModal