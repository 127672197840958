export async function fetchFactConfig() {
    const options : RequestInit = {
        method: "GET",
        credentials: "include",
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/search/get-fact-config`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            return data.data;
        } else {
            console.error(`fetchFactConfig: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchFactConfig: Error occurred ${error}`);
    }
}

export async function fetchUpdateFactConfig(updatedFacts : any) {
    const payload = {
        updatedFacts,
    };

    console.log("HERE")

    const options : RequestInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(payload),
    }

    try {
        const route = `${process.env.REACT_APP_BACKEND}/api/search/update-fact-config`;
        const res = await fetch(route, options);
    
        if (res.ok) {
            const data = await res.json();
            console.log("HERE2")
            return data.data;
        } else {
            console.error(`fetchFactConfig: Bad response ${res.status}`);
        }
    } catch(error) {
        console.error(`fetchFactConfig: Error occurred ${error}`);
    }
}