import React from 'react'
import { Link } from 'react-router-dom'
import MainButton from '../../../../../../components/MainButton'
import SignupVendorForm from '../../../../../other/auth/sign-up-vendor/features/SignupVendorForm'
import { useTranslation } from 'react-i18next'

const OnboaringCreateAccount = () => {
  const {t} = useTranslation();
  return (
    <div >
      <h1 className="text-3xl font-semibold text-secondary1 mt-2 leading-58 max-xl:text-[44px] max-xl:leading-[48px] max-sm:text-40">{t("createAccountSentence")}</h1>
      <p className="text-secondary2 font-normal my-6 max-xl:max-w-[480px] max-lg:max-w-lg">{t("onboardingCreateAccountDescription")}</p>

      <div className='mx-auto w-full sm:w-4/6'>
        <SignupVendorForm successDestination="/onboarding/create-product" />
      </div>
    </div>
  )
}

export default OnboaringCreateAccount